import * as Default from './Default'
import * as Spf from './Spf'
import * as Soa from './Soa'
import * as Dkim from './Dkim'
import * as Dmarc from './Dmarc'
import * as Mx from './Mx'
import * as Other from './Other'
import * as A from './A'
import * as Txt from './Txt'
import * as Cname from './Cname'
import * as Ns from './Ns'
import * as AAAA from './Aaaa'
import * as Tlsa from './Tlsa'
import * as Srv from './Srv'
import * as Https from './Https'
import * as Bimi from './Bimi'

export function factory(type: string) {
  switch (type) {
    case 'MX':
      return Mx
    case 'SPF':
      return Spf
    case 'DKIM':
      return Dkim
    case 'DMARC':
      return Dmarc
    case 'SOA':
      return Soa
    case 'A':
      return A
    case 'TXT':
      return Txt
    case 'CNAME':
      return Cname
    case 'NS':
      return Ns
    case 'AAAA':
      return AAAA
    case 'TLSA':
      return Tlsa
    case 'SRV':
      return Srv
    case 'HTTPS':
      return Https
    case 'BIMI':
      return Bimi
    default:
      return Other
  }
}
