import React from 'react'
import { Rr, BimiRdata } from '@/api/rrs'
import { HostName } from './Hostname'
import Value from './Value'
import { validateTtl, validateDomainName } from '../validator'
import { parseHostName, convertOldHostName, convertRr } from '../Common'
import { isValidHttpUrl } from '@/components/Rrs/validator'

export function parse(zoneName: string, rr: Rr) {
  if (rr.type !== 'BIMI') {
    throw Error(`Fixme!! this is not BIMI: ${JSON.stringify(rr)}`)
  }

  const fqdn = `${rr.hostName}.${zoneName}.`

  const Val = ({ value }: { value: BimiRdata }) => {
    const { l, a } = value

    return (
      <>
        <p className="truncate">{`l (location): ${l}`}</p>
        {a != null && <p className="truncate">{`a (authority): ${a}`}</p>}
      </>
    )
  }

  const hostName = rr.hostName

  return { fqdn, hostName, rrType: `TXT(BIMI)`, Val }
}

export function create() {
  function isReady(rr: Rr) {
    if (rr.type !== 'BIMI') throw Error('Fixme: this is not BIMI record')

    const res = rr.hostName.split('._bimi')

    if (rr.hostName !== 'default._bimi' && !validateDomainName(rr.hostName))
      return false

    if (res.length < 1 || res[0].length === 0) {
      return false
    }

    if (!validateTtl(rr.ttl)) return false

    if (rr.data.length === 0) return false

    if (rr.data[0].l.length > 0 && !isValidHttpUrl(rr.data[0].l)) {
      return false
    }

    if (
      rr.data[0].a != null &&
      rr.data[0].a.length > 0 &&
      !isValidHttpUrl(rr.data[0].a)
    ) {
      return false
    }

    return true
  }

  const getHostName = (rr: Rr) => {
    if (rr.hostName === '@') {
      return 'default._bimi'
    }

    const res = rr.hostName.split('._bimi')

    return res.length === 1 ? `${rr.hostName}._bimi` : rr.hostName
  }

  return {
    HostName,
    Val: Value,
    isReady,
    convertOldHostName,
    convertRr: (rr: Rr) => {
      rr.hostName = getHostName(rr)
      rr.data = rr.data.map((rdata) => {
        const getA = () => {
          if (rdata.l.length > 0 && (rdata.a == null || rdata.a.length === 0)) {
            return undefined
          }

          if (rdata.l.length === 0 && rdata.a == null) {
            return ''
          }

          return rdata.a
        }

        rdata.a = getA()

        return rdata
      })
      return rr
    },
  }
}

export { parseHostName }
