import React, { useState } from 'react'
import useTranslation from '@/i18n'
import OutsideAlerter from '@/components/OutsideAlerter'
import Info from './Info'
import Require from './Require'
type Props = {
  current: string
  vals: string[]
  select: (index: number) => void
  disabled: boolean
  title?: string
  required?: boolean
  info?: string
}

const Selector = ({
  title,
  current,
  vals,
  select,
  disabled,
  required,
  info,
}: Props) => {
  const { t } = useTranslation()
  const [show, setShow] = useState(false)

  return (
    <OutsideAlerter clicked={() => setShow(false)}>
      {title != null && (
        <div className="flex items-center font-medium text-md">
          <p className="text-gray-900 mr-2">{t(title)}</p>
          <Require require={required} />
          <Info info={info} />
        </div>
      )}
      <div className="relative">
        <button
          disabled={false}
          type="button"
          className="relative w-full bg-white border border-gray-500 rounded-xs pl-2 pr-10 text-left focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 text-md cursor-pointer"
          aria-haspopup="listbox"
          aria-expanded="true"
          aria-labelledby="listbox-label"
          onClick={() => {
            if (!disabled) {
              setShow(!show)
            }
          }}
        >
          <span className="flex items-center h-full">
            <span
              className={`ml-0 block truncate text-md ${
                disabled ? 'text-gray-500' : 'text-gray-800'
              }`}
            >
              {t(current)}
            </span>
          </span>
          <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <svg
              className="h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </button>
        {show && (
          <ul
            className="absolute z-10 mt-0 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-md"
            role="listbox"
            aria-labelledby="listbox-label"
            aria-activedescendant="listbox-option-3"
          >
            {vals.map((type, index) => {
              return (
                <li
                  className="text-gray-900 cursor-default select-none relative py-1 pl-3 pr-9 hover:bg-blue-100"
                  id="listbox-option-0"
                  role="option"
                  onClick={() => {
                    select(index)
                    setShow(false)
                  }}
                  key={index}
                >
                  <span className="flex items-center text-md">{t(type)}</span>
                  {type === current && (
                    <span className="text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4">
                      <svg
                        className="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  )}
                </li>
              )
            })}
          </ul>
        )}
      </div>
    </OutsideAlerter>
  )
}

export default Selector
