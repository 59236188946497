import { Rr } from '@/api/rrs'
import {
  parse as defaultParse,
  convertOldHostName,
  parseHostName,
} from '../Common'
import HostName from './Hostname'
import Value from './Value'
import {
  validateTtl,
  validateHostName,
  validateDomainInValueExceptDot,
} from '../validator'
import deepClone from 'lodash.clonedeep'
import { absoluteDomain2relativeDomain } from '@/utils'

export function parse(zoneName: string, rr: Rr) {
  return defaultParse(zoneName, rr)
}

export function create() {
  function isReady(rr: Rr) {
    if (rr.type !== 'CNAME') throw Error('Fixme: this is not CNAME record')

    if (rr.hostName === '@' || !validateHostName(rr.hostName)) return false

    if (rr.data.length > 0 && rr.data[0].length === 0) return false

    if (!validateTtl(rr.ttl)) return false

    return rr.data.length > 0 && validateDomainInValueExceptDot(rr.data[0])
  }

  return {
    HostName,
    Val: Value,
    isReady,
    convertOldHostName,
    convertRr: (rr: Rr, zoneName: string) => {
      if (rr.type !== 'CNAME') throw Error('FIXME: this is not CNAME')

      const shadow = deepClone(rr)
      shadow.hostName = rr.hostName === '' ? '@' : rr.hostName

      shadow.data = shadow.data.map((value) =>
        absoluteDomain2relativeDomain(value, zoneName)
      )
      return shadow
    },
  }
}

export { parseHostName }
