import { axios, getAuthorization } from '@/api/axios'
import { withRefreshToken } from '@/auth'
import { errConverter } from './error'

export type ZoneTransfer = {
  step: number
  mode: number
}

const getZoneStatus = (zoneId?: number): Promise<ZoneTransfer> =>
  new Promise<ZoneTransfer>((resolve, reject) =>
    axios
      .get<{ message: string }>(`/dev/zone-transfer`, {
        headers: getAuthorization(),
        params: {
          zoneId,
        },
      })
      .then((res) => {
        resolve(JSON.parse(res.data.message))
      })
      .catch((err) => {
        reject(errConverter(err))
      })
  )

const get = withRefreshToken(getZoneStatus)

type Status = {
  zoneId: number
  status: number
}

export const postStatus = (status?: Status): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    axios
      .post<{ message: string }>(
        'dev/zone-transfer',
        {
          zoneId: status!.zoneId,
          status: status!.status,
        },
        {
          headers: getAuthorization(),
        }
      )
      .then((result) => {
        resolve(result.data.message)
      })
      .catch((err) => {
        reject(errConverter(err))
      })
  })

const post = withRefreshToken(postStatus)

export { get, post }
