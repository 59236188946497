import React from 'react'
import { useProgress, useSafeState } from '@/hooks'
import TextareaAutosize from 'react-textarea-autosize'
import { validateEch } from '@/components/Rrs/validator'
import ErrorMsg from '@/components/Rrs/ErrorMsg'

type Props = {
  ech?: string
  change: (ech?: string) => void
}

function Ech(props: Props) {
  const { inProgress } = useProgress()
  const [error, setError] = useSafeState('')

  const onChange = (e) => {
    const value = e.target.value.replace(/\s+/g, '')

    if (!validateEch(value)) {
      setError('ECH must be between 4 to 255 base64 characters')
    } else {
      setError('')
    }

    props.change(value === '' ? undefined : value)
  }

  return (
    <div className="mt-4">
      <p className="text-md font-medium text-gray-900">Ech</p>
      <div className="flex items-center mt-0 w-full sm:w-108">
        <TextareaAutosize
          disabled={inProgress}
          maxLength={253}
          maxRows={5}
          value={props.ech == null ? '' : props.ech}
          onChange={onChange}
          className="text-md text-gray-900 resize-none w-full border-b border-gray-500 outline-none focus:outline-none px-1"
        />
      </div>
      <ErrorMsg error={error} />
    </div>
  )
}

export default Ech
