import React, { useState, useRef } from 'react'
import useTranslation from '@/i18n'
import { post } from '@/api/zone'
import { useErr, useProgress, useRightBar, useTooltip } from '@/hooks'

import ErrorMsg from '@/components/Rrs/ErrorMsg'

type Props = {
  zoneName: string
  nsList: string[]
  loadRrs: () => Promise<void>
}

const domainRegExp = /^[a-zA-Z0-9_]{1,63}$/
const domainPattern = new RegExp(domainRegExp, 'i')

const Subdomain = ({ loadRrs, zoneName, nsList }: Props) => {
  const { inProgress, start, stop } = useProgress()
  const refZone = useRef<HTMLInputElement | null>(null)
  const [value, setValue] = useState('')
  const [disabled, setDisabled] = useState(false)
  const [result, setResult] = useState('')
  const { setTooltip } = useTooltip()
  const { setErr } = useErr()
  const { t } = useTranslation()
  const { closeRightBar } = useRightBar()

  const add = () => {
    if (value.length === 0) {
      setResult(t('Enter a zone name'))
      refZone.current?.focus()
      return
    }

    if (disabled) {
      refZone.current?.focus()
      return
    }

    const subdomain = `${value}.${zoneName}`

    setResult('')
    start(0)

    post(subdomain)
      .then(() => {
        loadRrs().then(() => {
          closeRightBar()
          setTooltip('Completed')
        })
      })
      .catch((err) => {
        if (err.status == null) {
          setErr(err.message)
        } else {
          setResult(`[StatusCode ${err.status}] ${t(err.message)}`)
        }
        stop()
        refZone.current?.focus()
      })
  }

  const validate = (value: string) => {
    if (nsList.find((ns) => ns === value) != null) {
      setResult('The NS record already exists')
      setDisabled(true)
      return false
    }

    if (!domainPattern.test(value)) {
      setResult('Invalid subdomain')
      setDisabled(true)
      return false
    }

    setResult('')
    setDisabled(false)
    return true
  }

  const set = (value: string) => {
    validate(value)
    setValue(value)
  }

  const keyDown = (e) => {
    if (e.keyCode === 13) {
      add()
    }
  }

  return (
    <div className="p-4 w-full">
      <h1 className="text-gray-600 font-bold mb-4">{t('Add a subdomain')}</h1>

      <div className="flex items-center">
        <input
          className="outline-none border-b border-gray-300 rounded-sm w-full px-1 text-sm mb-1"
          type="text"
          disabled={inProgress}
          maxLength={253}
          placeholder={t('sample')}
          value={value}
          onChange={(e) => set(e.target.value)}
          autoFocus={true}
          ref={refZone}
          onKeyDown={keyDown}
        />
        <p className="text-gray-700 text-sm">.{zoneName}</p>
      </div>
      <ErrorMsg error={result} />
      <div className="text-right">
        <button
          disabled={inProgress || disabled}
          className={`${
            inProgress || disabled ? 'bg-gray-400' : 'bg-blue-600'
          } text-white w-full sm:w-32 rounded-sm focus:outline-none`}
          onClick={add}
        >
          {t('Add')}
        </button>
      </div>
    </div>
  )
}

export default Subdomain
