import { HttpsRdata } from '@/api/rrs'

export function service(svc: HttpsRdata) {
  const temp = [`${svc.priority}`, svc.targetName]

  if (svc.svcParams != null) {
    if (svc.svcParams.port != null && svc.svcParams.port !== 443) {
      temp.push(`port=${svc.svcParams.port}`)
    }

    if (svc.svcParams.noDefaultAlpn != null) {
      temp.push(`no-default-alpn`)
    }

    if (svc.svcParams.alpn.filter((d) => d.length > 0).length > 0) {
      temp.push(`alpn=${svc.svcParams.alpn.join(',')}`)
    }

    if (svc.svcParams.ech != null) {
      temp.push(`ech=${svc.svcParams.ech}`)
    }

    if (svc.svcParams.ipv4hints.filter((d) => d.length > 0).length > 0) {
      temp.push(`ipv4hint=${svc.svcParams.ipv4hints.join(',')}`)
    }

    if (svc.svcParams.ipv6hints.filter((d) => d.length > 0).length > 0) {
      temp.push(`ipv6hint=${svc.svcParams.ipv6hints.join(',')}`)
    }

    if (svc.svcParams.mandatories.length > 0) {
      temp.push(`mandatory=${svc.svcParams.mandatories.join(',')}`)
    }
  }

  return temp.join(' ')
}
