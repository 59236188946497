import React, { useEffect } from 'react'
import useTranslation from '@/i18n'
import CurrentAlpn from './CurrentAlpn'
import { useProgress, useSafeState } from '@/hooks'
import { FaPlusCircle } from 'react-icons/fa'
import FlexListFrame from '@/components/Rrs/FlexListFrame'

const _options = [
  'No ALPN',
  'h2',
  'h3',
  'h3-25',
  'h3-26',
  'h3-27',
  'h3-28',
  'h3-29',
  'h3-30',
  'h3-31',
  'h3-32',
  'h3-33',
  'h3-34',
]

type Props = {
  alpns: string[]
  update: (alpns: string[]) => void
}

function Alpn(props: Props) {
  const { t } = useTranslation()
  const { inProgress } = useProgress()
  const [disAdd, setDisAdd] = useSafeState(true)

  useEffect(() => {
    setDisAdd(props.alpns.some((a) => a.length === 0))
  }, [props.alpns])

  const selectableAlpn = () => {
    if (props.alpns.length > 1) {
      return _options.filter((alpn, id) => {
        return id !== 0 && props.alpns.indexOf(alpn) === -1
      })
    }
    return _options.filter((option) => props.alpns.indexOf(option) === -1)
  }

  const currrent = (data: string, total: number) => {
    if (data === '') {
      return total > 1 ? 'Select' : 'No ALPN'
    }

    return data
  }

  const Title = () => (
    <div>
      <p className="text-md font-medium text-gray-900">{t('ALPN')}</p>
    </div>
  )

  const List = (data: string, index: number, total: number) => (
    <CurrentAlpn
      selected={currrent(data, total)}
      options={selectableAlpn()}
      total={total}
      change={(selected) => {
        props.alpns[index] = selected
        setDisAdd(selected === '')
        props.update(props.alpns)
      }}
      key={index}
      del={() => {
        const result = props.alpns.filter((_, id) => id != index)
        props.update([...result])
      }}
    />
  )

  const Input = () => (
    <div className="flex items-center mt-0 w-full text-md">
      <FaPlusCircle
        className={`${
          disAdd || inProgress ? 'text-gray-500' : 'text-green-500'
        } cursor-pointer mr-2`}
        onClick={() => {
          if (!disAdd && !inProgress) {
            props.alpns.push('')
            props.update([...props.alpns])
          }
        }}
      />
      <p
        className={`${
          disAdd || inProgress ? 'text-gray-400' : 'text-gray-800'
        }`}
      >
        {t('Add')}
      </p>
    </div>
  )

  return (
    <div className="mt-4">
      <FlexListFrame
        title={Title}
        listData={props.alpns}
        list={List}
        input={Input}
        maxLine={4}
        error={''}
      />
    </div>
  )
}

export default Alpn
