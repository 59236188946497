import { Rr } from '@/api/rrs'
import {
  parse as defaultParse,
  HostName,
  convertOldHostName,
  // convertRr,
  parseHostName,
} from '../Common'
import Value from './Value'
import { ipV4, validateHostName, validateTtl } from '../validator'

export function parse(zoneName: string, rr: Rr) {
  return defaultParse(zoneName, rr)
}

export function create() {
  function isReady(rr: Rr) {
    if (rr.type !== 'A') throw Error('Fixme: this is not A record')

    const res = rr.data.filter((ip) => {
      return ip.length > 0 && !ipV4(ip)
    })

    if (
      rr.hostName !== '' &&
      rr.hostName !== '@' &&
      !validateHostName(rr.hostName)
    )
      return false

    if (!validateTtl(rr.ttl)) return false

    const ipv4s = rr.data.filter((rdata) => rdata.length > 0)

    return ipv4s.length !== 0 && res.length === 0
  }

  return {
    HostName,
    Val: Value,
    isReady,
    convertOldHostName,
    convertRr: (rr: Rr, zoneName: string) => {
      if (rr.type !== 'A') throw Error('Fixme: this is not A record')
      rr.hostName = rr.hostName === '' ? '@' : rr.hostName
      rr.data = rr.data.filter((rdata) => rdata.length > 0)
      return rr
    },
  }
}

export { parseHostName }
