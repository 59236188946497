import { axios, getAuthorization } from '@/api/axios'
import { withRefreshToken } from '@/auth'
import { errConverter } from './error'

type NsCheckParams = {
  domainName: string
  targetNs?: string
}

export type NsCheckResult = {
  result: boolean
  currentNsList: string[]
  qtDnsNsList: string[]
}

const getNsCheck = (params?: NsCheckParams): Promise<NsCheckResult> =>
  new Promise<NsCheckResult>((resolve, reject) =>
    axios
      .get<{ message: string }>(`/dev/ns-check`, {
        headers: getAuthorization(),
        params,
      })
      .then((res) => {
        resolve(JSON.parse(res.data.message))
      })
      .catch((err) => {
        reject(errConverter(err))
      })
  )

const get = withRefreshToken(getNsCheck)

export { get }
