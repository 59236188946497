import React from 'react'
import { IoMdInformationCircle } from 'react-icons/io'
import ReactTooltip from 'react-tooltip'
import useTranslation from '@/i18n'

type Props = {
  info?: string
}

const Info = ({ info }: Props) => {
  const { t } = useTranslation()
  return (
    <>
      {info && (
        <div data-tip={t(info)}>
          <IoMdInformationCircle className="mt-auto text-sm text-blue-500" />
          <ReactTooltip effect="float" type="info" place="right" />
        </div>
      )}
    </>
  )
}

export default Info
