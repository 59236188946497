import React, { useEffect } from 'react'
import { ValProps } from '@/types'
import { BimiRdata } from '@/api/rrs'
import Input from '@/components/Parts/Input'
import Preview from './Preview'
import { isValidHttpUrl } from '@/components/Rrs/validator'
import deepClone from 'lodash.clonedeep'
import useTranslation from '@/i18n'
import { checkBimiLogo } from '@/api/bimi-logo'
import { useModal, useProgress, useSafeState } from '@/hooks'
import BimiLogoError from './BimiLogoError'
import BimiLogoSuccess from './BimiLogoSuccess'

const _defaultRdata: BimiRdata = {
  l: '',
}

const Value = (props: ValProps) => {
  if (props.rr.type !== 'BIMI') throw Error('Fixme: this is not BIMI record')
  const { t } = useTranslation()
  const { start, stop } = useProgress()
  const [isValidL, setIsValidL] = useSafeState(false)
  const { openModal } = useModal()

  useEffect(() => {
    if (props.rr.data.length === 0) {
      props.rr.data = [deepClone(_defaultRdata)]
      props.change({ ...props.rr })
    }
  }, [props.rr.data])

  const validate = () => {
    if (props.rr.data.length === 0) {
      return false
    }

    if (props.rr.data[0].l.length > 0) {
      return isValidHttpUrl(props.rr.data[0].l)
    }

    return false
  }

  useEffect(() => {
    setIsValidL(validate())
  }, [props.rr])

  const check = async () => {
    start(0)
    checkBimiLogo(props.rr.data[0].l)
      .then(() => {
        stop()
        openModal(<BimiLogoSuccess img={props.rr.data[0].l} />)
      })
      .catch((err) => {
        console.log(err)
        stop()
        openModal(
          <BimiLogoError
            status={err.status}
            subCode={err.subCode}
            message={err.details}
          />
        )
      })
  }

  return (
    <div className="mt-4">
      <Input
        title={'l (URL of a logo)'}
        value={props.rr.data.length === 0 ? '' : props.rr.data[0].l}
        maxLength={2048}
        onChange={(value) => {
          props.rr.data[0].l = value.trim()
          props.change({ ...props.rr })
        }}
        onKeyDown={() => {}}
        validate={isValidHttpUrl}
      />
      <div className="text-right">
        <button
          disabled={!isValidL}
          className={`${
            isValidL ? 'bg-green-500' : 'bg-gray-400'
          } text-white px-4 text-md rounded-sm focus:outline-none`}
          onClick={check}
        >
          {t('Validation BIMI logo')}
        </button>
      </div>
      <Input
        title={'a (URL of Verified Mark Certificate)'}
        value={
          props.rr.data.length === 0
            ? ''
            : props.rr.data[0].a != null
            ? props.rr.data[0].a
            : ''
        }
        maxLength={2048}
        onChange={(value) => {
          props.rr.data[0].a = value.trim()
          props.change({ ...props.rr })
        }}
        onKeyDown={() => {}}
        validate={isValidHttpUrl}
      />
      <Preview rr={props.rr} zoneName={props.zoneName} />
    </div>
  )
}

export default Value
