import React, { useState, ReactNode } from 'react'
import Selector from '../Selector'
import { TlsaRdata } from '@/api/rrs'
import ErrorMsg from '../ErrorMsg'
import { validateCaAssociationData } from '../validator'
import TextareaAutosize from 'react-textarea-autosize'
import { border } from '@/utils'

type Props = {
  children: ReactNode
  inProgress: boolean
  tlsaRdata: TlsaRdata
  blur?: (tlsaRdata: TlsaRdata) => void
  change: (tlsaRdata: TlsaRdata) => void
  keyDown: (tlsaRdata: TlsaRdata) => void
}

export const tlsaCaUsages = [
  'PKIX-TA(0)',
  'PKIX-EE(1)',
  'DANE-TA(2)',
  'DANE-EE(3)',
]

export const tlsaSelector = ['Cert(0)', 'SPKI(1)']

export const tlsaMatchingTypes = ['Full(0)', 'SHA256(1)', 'SHA512(2)']

const Rdata = ({
  inProgress,
  tlsaRdata,
  children,
  blur,
  change,
  keyDown,
}: Props) => {
  const [error, setError] = useState('')

  const changeValue = (value: string) => {
    const cleanedValue = value.replace(/\s+/g, '')
    const { message } = validateCaAssociationData(
      tlsaRdata.matchingType,
      cleanedValue
    )
    setError(message)

    tlsaRdata.certificationAssociationData = cleanedValue
    change({ ...tlsaRdata })
  }

  const changeCaUsage = (id: number) => {
    tlsaRdata.certificationUsage = id
    change({ ...tlsaRdata })
  }

  const changeSelector = (id: number) => {
    tlsaRdata.selector = id
    change({ ...tlsaRdata })
  }

  const changeMatchingType = (id: number) => {
    tlsaRdata.matchingType = id

    const { message } = validateCaAssociationData(
      tlsaRdata.matchingType,
      tlsaRdata.certificationAssociationData
    )
    setError(message)
    change({ ...tlsaRdata })
  }

  const onKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      keyDown(tlsaRdata)
    }
  }

  return (
    <div className="w-full">
      <div className="flex items-center">
        <div className="w-full">
          <div className="w-full grid grid-cols-12 gap-1">
            <div className="col-span-4">
              <Selector
                current={tlsaCaUsages[tlsaRdata.certificationUsage]}
                vals={tlsaCaUsages}
                disabled={inProgress}
                select={changeCaUsage}
              />
            </div>
            <div className="col-span-4">
              <Selector
                current={tlsaSelector[tlsaRdata.selector]}
                vals={tlsaSelector}
                disabled={inProgress}
                select={changeSelector}
              />
            </div>
            <div className="col-span-4">
              <Selector
                current={tlsaMatchingTypes[tlsaRdata.matchingType]}
                vals={tlsaMatchingTypes}
                disabled={inProgress}
                select={changeMatchingType}
              />
            </div>
          </div>
          <div className="flex items-center mt-2 ">
            <TextareaAutosize
              // ref={ref}
              disabled={inProgress}
              maxLength={65000}
              maxRows={5}
              minRows={1}
              value={tlsaRdata.certificationAssociationData}
              onChange={(e) => changeValue(e.target.value)}
              onKeyDown={onKeyDown}
              onBlur={() => {
                if (blur != null) {
                  blur(tlsaRdata)
                }
              }}
              autoFocus={true}
              className={`text-md text-gray-900 resize-none w-full border-b ${border(
                tlsaRdata.certificationAssociationData,
                error
              )} mr-2 outline-none focus:outline-none px-1`}
            />
          </div>
        </div>
        <div className="relative">{children}</div>
      </div>

      <ErrorMsg error={error} />
    </div>
  )
}

export default Rdata
