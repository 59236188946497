import React, { useEffect } from 'react'
import { NsCheckResult } from '@/api/ns-check'
import Step from '../Step'
import { useErr, useModal, useProgress, useSafeState } from '@/hooks'
import useTranslation from '@/i18n'
import DsConfirmDialog from '@/components/Zones/DsConfirmDialog'
import { get } from '@/api/ns-check'
import { post } from '@/api/manual-start-zone'

const _defaultNs = {
  result: true,
  currentNsList: [],
  qtDnsNsList: [],
}

type Props = {
  domainName: string
  zoneId: number
  step: number
  setStep: (step: number) => void
}

const Step3 = ({ step, domainName, zoneId, setStep }: Props) => {
  const [error, setError] = useSafeState<NsCheckResult>({
    ..._defaultNs,
  })
  const { t } = useTranslation()
  const { openModal } = useModal()
  const { start, stop } = useProgress()
  const { setErr } = useErr()

  useEffect(() => {
    if (step === 3) {
      checkNs()
    }
  }, [])

  const checkNs = () => {
    setError({ ..._defaultNs })

    start()
    get({ domainName, targetNs: 'parent' })
      .then((res) => {
        if (res.result) {
          post(zoneId)
            .then((res) => {
              stop()
              setStep(step + 1)
              openModal(<DsConfirmDialog showInfo={true} dsList={res.dsList} />)
            })
            .catch((err) => {
              setErr(err.message, err.status)
              stop()
            })
        } else {
          setError({ ...res })
          stop()
        }
      })
      .catch((err) => {
        setErr(err.message, err.status)
        stop()
      })
  }

  return (
    <>
      <Step
        step={3}
        done={step > 3}
        enable={step === 3}
        title="Set the NS records of the parent DNS server to QUALITIA DNS"
        check={checkNs}
        btnTitle="Check"
      />

      {!error.result && (
        <div className="bg-gray-800 mb-4 p-4 rounded-lg w-full xl:w-1/2">
          <h1 className="text-lg font-medium text-red-500">
            {t(
              'NS records of the parent name servers are not QUALITIA DNS name servers yet'
            )}
          </h1>
          {error.currentNsList.length > 0 && (
            <>
              <p className="text-gray-500 font-medium">
                {t('Current name servers')}
              </p>
              {error.currentNsList.map((currentNs, index) => (
                <p className="text-white" key={index}>
                  {currentNs}
                </p>
              ))}
            </>
          )}
          {error.currentNsList.length === 0 && (
            <>
              <p className="text-white">{t('NotFoundNSInParentNS')}</p>
              <p className="text-white">
                {t('InstructionsOfMovingNSToParent')}
              </p>
            </>
          )}
          <div className="mt-4">
            {error.qtDnsNsList.map((ns, index) => (
              <p className="font-medium ml-2 text-green-400" key={index}>
                {ns}
              </p>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default Step3
