import React, { useEffect } from 'react'
import { Rr, HttpsRdata } from '@/api/rrs'
import { useSafeState } from '@/hooks'
import deepClone from 'lodash.clonedeep'
import Alias from './Alias'
import Svc from './Svc'
import CheckBox from '@/components/CheckBox'
import { defaultSvc } from '@/utils'

type Props = {
  rr: Rr
  zoneName: string
  change: (rr: Rr) => void
  save: () => void
  setDisable: (disabled: boolean) => void
}

const Value = (props: Props) => {
  if (props.rr.type !== 'HTTPS') throw Error('Fixme: this is not HTTPS record')
  const [prev, setPrev] = useSafeState<HttpsRdata[]>([])
  const [isAlias, setIsAlias] = useSafeState(
    props.rr.data.length > 0 ? props.rr.data[0].priority === '0' : false
  )

  useEffect(() => {
    if (props.rr.type !== 'HTTPS')
      throw Error('Fixme: this is not HTTPS record')

    if (isAlias) {
      if (props.rr.data.length === 0) {
        props.rr.data = [
          {
            priority: '0',
            targetName: '.',
          },
        ]
      }
    } else {
      if (props.rr.data.length === 0) {
        props.rr.data = [deepClone(defaultSvc)]
        props.change({ ...props.rr })
      } else {
        setPrev(deepClone(props.rr.data))
      }
    }
  }, [props.rr.data])

  const toggleMode = () => {
    if (props.rr.type !== 'HTTPS')
      throw Error('Fixme: this is not HTTPS record')

    if (isAlias) {
      props.rr.data = prev! // 値を戻す
    } else {
      setPrev(deepClone(props.rr.data)) // 値を退避する
      const targetName =
        props.rr.data.length > 0 ? props.rr.data[0].targetName : ''
      props.rr.data = [{ priority: '0', targetName }]
    }
    props.change({ ...props.rr })
    setIsAlias(!isAlias)
  }

  return (
    <div className="mt-4">
      <CheckBox label="Alias mode" toggle={toggleMode} checked={isAlias} />
      {isAlias && (
        <Alias
          rdata={props.rr.data[0]}
          update={(rdata) => {
            props.rr.data = [rdata]
            props.change({ ...props.rr })
          }}
          save={props.save}
        />
      )}
      {!isAlias && (
        <Svc rr={props.rr} update={(rr) => props.change({ ...rr })} />
      )}
    </div>
  )
}

export default Value
