import React, { useEffect, useState, ReactNode } from 'react'
import { factory } from '../factory'
import { Rr, Rtype } from '@/api/rrs'
import Ttl from './Ttl'
import Save from './Save'
import { post } from '@/api/rrs'
import { useErr, useProgress, useRightBar, useTooltip } from '@/hooks'
import ErrorMsg from '../ErrorMsg'
import useTranslation from '@/i18n'
import { useHeight } from '@/hooks'

type Props = {
  zoneId: number
  zoneName: string
  type: Rtype
  children: ReactNode
  rr: Rr
  setRr: (rr: Rr) => void
  check: (rr: Rr) => boolean
  reload: () => Promise<void>
}

const RecordForType = ({
  zoneName,
  zoneId,
  type,
  children,
  rr,
  setRr,
  check,
  reload,
}: Props) => {
  const { inProgress, start, stop } = useProgress()
  const [disabled, setDisabled] = useState(true)
  const [disabledByVal, setDisabledByVal] = useState(false)
  const { closeRightBar } = useRightBar()
  const [result, setResult] = useState('')
  const { setErr } = useErr()
  const { setTooltip } = useTooltip()
  const { t } = useTranslation()
  const [oldHostName, setOldHostName] = useState<string | undefined>(undefined)
  const { height } = useHeight()

  const handler = factory(type)
  const {
    HostName,
    Val,
    isReady,
    convertOldHostName,
    convertRr,
  } = handler.create()

  useEffect(() => {
    setOldHostName(convertOldHostName(rr))
    setDisabled(!isReady(rr))
  }, [])

  useEffect(() => {
    setDisabled(!isReady(rr))
  }, [type, rr.data])

  useEffect(() => {
    setResult('')
    setDisabledByVal(false)
  }, [type])

  const onchange = (newRr: Rr) => {
    setRr(newRr)
    setDisabled(!isReady(newRr))
  }

  const save = () => {
    if (!isReady(rr)) {
      return
    }

    start(0)
    setResult('')

    const newRr = convertRr(rr, zoneName)

    if (
      (oldHostName == null || oldHostName !== convertOldHostName(rr)) &&
      check(newRr)
    ) {
      setResult('This record already exists')
      stop()
      return
    }

    post({ zoneId, rrs: newRr, oldHostName })
      .then(() => {
        reload()
          .then(() => {
            const newRr: Rr = { hostName: '', ttl: 300, type: 'A', data: [] }
            setRr(newRr)
            setTooltip('Completed')
            closeRightBar()
          })
          .catch((err) => console.log(err))
      })
      .catch((err) => {
        if (err.status == null) {
          setErr(err.message)
        } else {
          setResult(`[StatusCode ${err.status}] ${t(err.message)}`)
        }
        stop()
      })
  }

  return (
    <div
      className="px-2 sm:px-8 pb-4 mt-4"
      // style={{ height: `${height - 120}px` }}
    >
      {children}

      <div className="mt-4">
        <HostName
          rr={rr}
          change={onchange}
          zoneName={zoneName}
          save={save}
          setDisable={setDisabledByVal}
        />
      </div>

      <Ttl
        ttl={rr.ttl}
        change={(ttl) => {
          rr.ttl = ttl
          setRr({ ...rr })
          setDisabled(!isReady(rr))
        }}
        save={save}
      />
      <Val
        rr={rr}
        change={(newRr) => onchange(newRr)}
        save={save}
        zoneName={zoneName}
        setDisable={setDisabledByVal}
      />
      <ErrorMsg error={result} />
      <Save disabled={disabled || inProgress || disabledByVal} save={save} />
    </div>
  )
}

export default RecordForType
