import React from 'react'
import useTranslation from '@/i18n'
import { isNumber } from '../../validator'

type Props = {
  value?: number
  change: (value: string) => void
  keyDown: () => void
}

function Port(props: Props) {
  const { t } = useTranslation()

  const onChange = (e) => {
    e.preventDefault()
    const value = e.target.value.trim()
    if (value === '' || (isNumber(value) && Number(value) <= 65535)) {
      props.change(value)
    }
  }

  const keyDown = (e) => {
    if (e.keyCode === 13) {
      props.keyDown()
    }
  }

  return (
    <div className="mt-4">
      <div className="flex items-center">
        <p className="text-md font-medium text-gray-900 mr-2">
          {t('Port number')}
        </p>
      </div>
      <input
        value={props.value == null ? '' : String(props.value)}
        onChange={onChange}
        className="border-b focus:outline-none border-gray-500 text-md w-full px-2 mr-1"
        placeholder={'443'}
        onKeyDown={(e) => keyDown(e)}
      />
    </div>
  )
}

export default Port
