import React from 'react'
import useTranslation from '@/i18n'
import { useModal } from '@/hooks'
import { NsResult } from '@/api/auto-start-zone'

const Complete = ({ nsTtl }: NsResult) => {
  const { closeModal } = useModal()
  const { t, d } = useTranslation()
  const enablingDnssec = new Date()

  // ゾーンの削除は親のNSのTTLまで待つべき
  enablingDnssec.setSeconds(enablingDnssec.getSeconds() + nsTtl)

  return (
    <>
      <div className="pt-2 px-4 pb-4">
        <h1 className="my-1 text-gray-800 font-medium text-lg">
          {t('Started operation')}
        </h1>
        {nsTtl > 0 && (
          <>
            <p className="text-lg font-medium text-gray-600">
              {t('DeletingNS', { deletingNs: d('ymdhms', enablingDnssec) })}
            </p>
            <p className="text-base text-gray-600">
              {t('EnablingDnssec', {
                enablingDnssec: d('ymdhms', enablingDnssec),
              })}
            </p>
          </>
        )}
      </div>
      <div className="w-full bg-gray-200 text-right mt-2 px-4 py-2">
        <button
          className="bg-blue-500 text-white w-full sm:ml-auto sm:w-32"
          onClick={closeModal}
        >
          {t('Close')}
        </button>
      </div>
    </>
  )
}

export default Complete
