import useTranslation from '@/i18n'
import React from 'react'

interface Props {
  require: boolean | undefined
}

function Require(props: Props) {
  const { t } = useTranslation()
  const { require } = props

  return <>{require && <p className="text-red-500 mr-2">{t('required')}</p>}</>
}

export default Require
