import React from 'react'
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io'

const Open = ({ open, click }: { open: boolean; click: () => void }) => {
  if (open) {
    return (
      <IoIosArrowUp
        className="text-xl text-gray-500 cursor-pointer"
        onClick={click}
      />
    )
  }

  return (
    <IoIosArrowDown
      className="text-xl text-gray-500 cursor-pointer"
      onClick={click}
    />
  )
}

export default Open
