import React, { useEffect, useState } from 'react'
import { DmarcRdata } from '@/api/rrs'
import Policy from './Policy'
import Advanced from './Advanced'
import Preview from './Preview'
import { validateRua, validateRuf } from '../validator'
import { ValProps } from '@/types'
import FlexList from '@/components/Rrs/FlexList'
import deepClone from 'lodash.clonedeep'

const _defaultRdata: DmarcRdata = {
  p: 'none',
  rua: [''],
  ruf: [''],
}

const Value = (props: ValProps) => {
  if (props.rr.type !== 'DMARC') throw Error('Fixme: this is not DMARC')
  const [disables, setDisables] = useState([false, false])

  useEffect(() => {
    if (props.rr.data.length === 0) {
      props.rr.data = [deepClone(_defaultRdata)]
      props.change({ ...props.rr })
    }
  }, [props.rr.data])

  const changeRua = (ruas: string[]) => {
    props.rr.data[0].rua = ruas
    props.change({ ...props.rr })
  }

  const changeRuf = (rufs: string[]) => {
    props.rr.data[0].ruf = rufs
    props.change({ ...props.rr })
  }

  return (
    <div className="mt-4">
      <Policy rr={props.rr} change={props.change} />
      <FlexList
        title={'rua(Aggregate report)'}
        vals={
          props.rr.data.length === 0
            ? ['']
            : props.rr.data[0].rua.length === 0
            ? ['']
            : props.rr.data[0].rua
        }
        validate={validateRua}
        change={changeRua}
        errorMsg={'Invalid Email address'}
        required={false}
      />
      <FlexList
        title={'ruf(Failure report)'}
        vals={
          props.rr.data.length === 0
            ? ['']
            : props.rr.data[0].ruf.length === 0
            ? ['']
            : props.rr.data[0].ruf
        }
        validate={validateRuf}
        change={changeRuf}
        errorMsg={'Invalid Email address'}
        required={false}
      />
      <Advanced rr={props.rr} change={props.change} save={props.save} />
      <Preview rr={props.rr} zoneName={props.zoneName!} />
    </div>
  )
}

export default Value
