import { axios, getAuthorization } from '@/api/axios'
import { withRefreshToken } from '@/auth'
import { errConverter } from './error'

type Ns = {
  zoneId: number
  zoneName: string
}

export type NsResult = {
  nsTtl: number
}

export const postNs = (ns?: Ns): Promise<NsResult> =>
  new Promise<NsResult>((resolve, reject) => {
    axios
      .post<{ message: string }>(
        'dev/auto-start-zone',
        {
          zoneId: ns!.zoneId,
          zoneName: ns!.zoneName,
        },
        {
          headers: getAuthorization(),
        }
      )
      .then((result) => {
        resolve(JSON.parse(result.data.message))
      })
      .catch((err) => {
        reject(errConverter(err))
      })
  })

const post = withRefreshToken(postNs)

export { post }
