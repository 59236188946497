import React from 'react'
import { Rr } from '@/api/rrs'
import Pre from '../Preview'

type Props = {
  zoneName: string
  rr: Rr
}

const Preview = ({ zoneName, rr }: Props) => {
  if (rr.type !== 'SRV') throw Error('FIXME: this is not SRV')

  const hostname =
    rr.hostName === '@' || rr.hostName === ''
      ? zoneName
      : `${rr.hostName}.${zoneName}`

  const records = [hostname, rr.ttl, 'IN SRV']

  const rrs = rr.data.map(
    (srv) =>
      `${srv.priority} ${srv.weight} ${srv.port} ${
        srv.target.indexOf('.') !== -1
          ? `${srv.target}.`
          : `${srv.target}.${zoneName}.`
      }`
  )

  return <Pre hostAndType={records.join(' ')} rdata={rrs} />
}

export default Preview
