import { useProgress } from '@/hooks'
import React from 'react'
import Selector from '../Selector'
import { Rr } from '@/api/rrs'

type Props = {
  rr: Rr
  change: (rr: Rr) => void
}

export const policies = {
  ['none']: 'dmarcPolicyNone',
  ['quarantine']: 'dmarcPolicyQuarantine',
  ['reject']: 'dmarcPolicyReject',
}

const Policy = ({ rr, change }: Props) => {
  if (rr.type !== 'DMARC') throw Error('FIXME: this is not DMARC')
  const { inProgress } = useProgress()

  const select = (index: number) => {
    const { p, ...rest } = rr.data[0]
    rr.data = [{ p: Object.keys(policies)[index], ...rest }]
    change({ ...rr })
  }

  return (
    <div className="mt-4">
      <Selector
        required={false}
        title={'Policy'}
        info={'Policy for recieving emails'}
        disabled={inProgress}
        vals={Object.values(policies)}
        current={policies[rr.data.length === 0 ? 'none' : rr.data[0].p]}
        select={select}
      />
    </div>
  )
}

export default Policy
