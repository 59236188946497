import React, { useEffect } from 'react'
import { TlsaRdata } from '@/api/rrs'
import useTranslation from '@/i18n'
import { useProgress, useSafeState } from '@/hooks'
import ListFrame from '../FlexListFrame'
import { ValProps } from '@/types'
import Rdata from './Rdata'
import { IoTrash } from 'react-icons/io5'
import Preview from './Preview'
import deepClone from 'lodash.clonedeep'
import { validateCaAssociationData } from '../validator'
import BtnAdd from '@/components/Parts/BtnAdd'

const _defaultTlsa: TlsaRdata = {
  certificationUsage: 3,
  selector: 1,
  matchingType: 1,
  certificationAssociationData: '',
}

const Value = (props: ValProps) => {
  if (props.rr.type !== 'TLSA') throw Error('Fixme: this is not TLSA record')
  const [disAdd, setDisAdd] = useSafeState(true)
  const { t } = useTranslation()
  const { inProgress } = useProgress()
  const [error, setError] = useSafeState('')

  useEffect(() => {
    if (props.rr.data.length === 0) {
      props.rr.data = [deepClone(_defaultTlsa)]
      props.change({ ...props.rr })
    } else {
      setDisAdd(isDisAdd())
    }
  }, [props.rr.data])

  const isDisAdd = () => {
    const isOver = props.rr.data.length === 32
    if (isOver) {
      setError('Reached the limit')
    } else {
      setError('')
    }
    return (
      isOver ||
      props.rr.data.some((tlsa) => {
        if (tlsa.certificationAssociationData === '') return true

        const res = validateCaAssociationData(
          tlsa.matchingType,
          tlsa.certificationAssociationData
        )
        return !res.result
      })
    )
  }

  const changeCurrentTlsa = (id: number, tlsa: TlsaRdata) => {
    setDisAdd(isDisAdd())

    props.rr.data[id] = tlsa
    props.change({ ...props.rr })
  }

  const currentKeyDown = (id: number, tlsaRdata: TlsaRdata) => {
    if (tlsaRdata.certificationAssociationData.length > 0) {
      add()
    }
  }

  const add = () => {
    if (!disAdd) {
      props.rr.data.push(deepClone(_defaultTlsa))
      props.change({ ...props.rr })
      setDisAdd(isDisAdd())
    }
  }

  const del = (id: number) => {
    const tlsa = props.rr.data[id]
    const validateRes = validateCaAssociationData(
      tlsa.matchingType,
      tlsa.certificationAssociationData
    )
    const res = props.rr.data.filter((_, index) => index !== id)
    props.rr.data = res
    props.change({ ...props.rr })
  }

  const Title = () => (
    <div className="w-full grid grid-cols-12 gap-1">
      <p className="text-md font-medium text-gray-900 col-span-4">
        {t('Cert usage')}
      </p>
      <p className="text-md font-medium text-gray-900 col-span-4">
        {t('Selector')}
      </p>
      <p className="text-md font-medium text-gray-900 col-span-4">
        {t('Type')}
      </p>
    </div>
  )

  const List = (data: TlsaRdata, index: number) => (
    <div className="flex items-center mt-1" key={index}>
      <Rdata
        inProgress={inProgress}
        tlsaRdata={data}
        change={(tlsa) => changeCurrentTlsa(index, tlsa)}
        blur={(tlsa) => {}}
        keyDown={(tlsa) => currentKeyDown(index, tlsa)}
        key={index}
      >
        {props.rr.data.length === 1 && <></>}
        {props.rr.data.length > 1 && (
          <IoTrash
            className="text-gray-700 cursor-pointer"
            onClick={() => del(index)}
          />
        )}
      </Rdata>
    </div>
  )

  const Input = () => <BtnAdd disAdd={disAdd} add={add} />

  return (
    <>
      <ListFrame
        title={Title}
        listData={props.rr.data}
        list={List}
        input={Input}
        error={error}
      />
      <Preview zoneName={props.zoneName} rr={props.rr} />
    </>
  )
}

export default Value
