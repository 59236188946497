import { useProgress } from '@/hooks'
import React from 'react'

type Props = {
  value: number | string
  error: (info: { message: string; details?: any }) => void
  change: (value: number | string) => void
  min?: number
  max?: number
  required?: boolean
  placeholder?: string
  keyDown?: (e: any) => void
  blur?: () => void
  className?: string
}

const pattern = /^[0-9]{1,}$/

const InputNumber = (props: Props) => {
  const { inProgress } = useProgress()

  const set = (value: string) => {
    if (value === '' || pattern.test(value)) {
      if (value === '') {
        props.change(value)
        return
      }

      if (props.max != null && String(props.max).length >= value.length) {
        props.change(Number(value))
      }
      if (props.max == null) {
        props.change(Number(value))
      }
    }
  }

  const onChange = (value: string) => {
    props.error({ message: '' })

    if (props.min != null && props.max == null) {
      if (value !== '' && pattern.test(value)) {
        if (Number(value) < props.min) {
          props.error({
            message: 'ShouldBeMoreThan',
            details: { min: props.min },
          })
        }
      }
    }

    if (props.min == null && props.max != null) {
      if (value !== '' && pattern.test(value)) {
        if (Number(value) > props.max) {
          props.error({
            message: 'ShouldBeLessThan',
            details: { max: props.max },
          })
        }
      }
    }

    if (props.min != null && props.max != null) {
      if (value !== '' && pattern.test(value)) {
        const val = Number(value)
        if (val > props.max || val < props.min) {
          props.error({
            message: 'ShouldBeBetween',
            details: { min: props.min, max: props.max },
          })
        }
      }
    }

    if (props.required && value === '') {
      props.error({ message: 'Invalid value' })
    }

    set(value)
  }

  return (
    <input
      disabled={inProgress}
      placeholder={props.placeholder}
      maxLength={props.max != null ? String(props.max).length : undefined}
      value={props.value}
      onChange={(e) => onChange(e.target.value.trim())}
      onKeyDown={props.keyDown}
      onBlur={props.blur}
      className={
        props.className != null
          ? props.className
          : 'border-b border-gray-500 px-1 text-md focus:outline-none w-full'
      }
    />
  )
}

export default InputNumber
