import React from 'react'
import { Rr } from '@/api/rrs'
import Open from '../Open'
import Step1 from '../Step1'
import Step2 from '../Step2'
import Step3 from './Step3'
import { useSafeState } from '@/hooks'
import useTranslation from '@/i18n'

type Props = {
  zoneId: number
  zoneName: string
  step: number
  setStep: (v: number) => void
  rrs: Rr[]
}

const ManualTransfer = ({ rrs, zoneId, zoneName, step, setStep }: Props) => {
  const [open, setOpen] = useSafeState<boolean>(true)
  const { t } = useTranslation()

  return (
    <div className="mb-4 px-4">
      <div className="flex items-center w-48">
        <h1 className="text-lg font-medium text-gray-700  mr-2">
          {t('Transfer zone')}
        </h1>
        <Open open={open} click={() => setOpen(!open)} />
      </div>

      <div
        className={`${
          open ? '' : 'hidden'
        } "bg-gray-100 px-4 py-1 w-80 sm:w-full `}
      >
        <Step1 rrs={rrs} zoneId={zoneId} step={step} setStep={setStep} />
        <Step2
          domainName={zoneName}
          zoneId={zoneId}
          step={step}
          setStep={setStep}
          mode={1}
        />
        <Step3
          domainName={zoneName}
          zoneId={zoneId}
          step={step}
          setStep={setStep}
        />
      </div>
    </div>
  )
}

export default ManualTransfer
