import React from 'react'
import { Rr } from '@/api/rrs'
import Pre from '../Preview'

type Props = {
  zoneName: string
  rr: Rr
}

const Preview = ({ rr, zoneName }: Props) => {
  if (rr.type !== 'DMARC') throw Error('FIXME: this is not DMARC')

  const hostname =
    rr.hostName === '@' || rr.hostName === ''
      ? `_dmarc.${zoneName}`
      : `_dmarc.${rr.hostName}.${zoneName}`

  const records = [hostname, rr.ttl, 'IN TXT']

  const temp = ['"v=DMARC1;']
  if (rr.data.length > 0) {
    const rdata = rr.data[0]
    temp.push(`p=${rdata.p};`)

    const rua = rdata.rua.filter((rua) => rua.length > 0)

    if (rua.length > 0) {
      temp.push(`rua=${rua.map((mail) => `mailto:${mail}`).join(',')};`)
    }

    const ruf = rdata.ruf.filter((ruf) => ruf.length > 0)

    if (ruf.length > 0) {
      temp.push(`ruf=${ruf.map((mail) => `mailto:${mail}`).join(',')};`)
    }

    if (rdata.sp != null) {
      temp.push(`sp=${rdata.sp};`)
    }

    if (rdata.adkim != null) {
      temp.push(`adkim=${rdata.adkim};`)
    }

    if (rdata.aspf != null) {
      temp.push(`aspf=${rdata.aspf};`)
    }

    if (rdata.fo != null) {
      temp.push(`fo=${rdata.fo};`)
    }

    if (rdata.ri != null) {
      temp.push(`ri=${rdata.ri};`)
    }

    if (rdata.pct != null) {
      temp.push(`pct=${rdata.pct};`)
    }
  }

  return <Pre hostAndType={records.join(' ')} rdata={[`${temp.join(' ')}"`]} />
}

export default Preview
