import React from 'react'
import ErrorMsg from '@/components/Rrs/ErrorMsg'
import { useProgress, useSafeState } from '@/hooks'
import useTranslation from '@/i18n'

interface Props {
  value: string
  title: string
  maxLength: number
  validate: (value: string) => boolean
  onChange: (value: string) => void
  onKeyDown: () => void
}

function Input({
  value,
  validate,
  onChange,
  onKeyDown,
  title,

  maxLength,
}: Props) {
  const { inProgress } = useProgress()
  const { t } = useTranslation()
  const [error, setError] = useSafeState('')

  return (
    <div className="mt-4">
      <p className="text-md font-medium text-gray-900">{t(title)}</p>
      <div className="flex items-center mt-0 w-full">
        <input
          disabled={inProgress}
          value={value}
          maxLength={maxLength}
          onChange={(e) => {
            const res = e.target.value.trim()
            if (res.length > 0 && !validate(res)) {
              setError('Invalid parameter')
            } else {
              setError('')
            }
            onChange(res)
          }}
          className={
            'border-b focus:outline-none border-gray-500 text-md w-full px-1'
          }
          onKeyDown={onKeyDown}
        />
      </div>
      <ErrorMsg error={error} />
    </div>
  )
}

export default Input
