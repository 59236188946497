import { Rr, HttpsRdata } from '@/api/rrs'
import React, { useRef, useEffect, useCallback } from 'react'
import { FaAngleRight, FaAngleDown } from 'react-icons/fa'
import { useSafeState, useProgress } from '@/hooks'
import Service from './Service'
import deepClone from 'lodash.clonedeep'
import { service } from '../convert'
import { CREATE_NEW_SERVICE, defaultSvc } from '@/utils'
import { IoTrash } from 'react-icons/io5'
import { FaPlusCircle } from 'react-icons/fa'
import { MdError } from 'react-icons/md'
import useTranslation from '@/i18n'
import { validateHttp } from '../../validator'
import ReactTooltip from 'react-tooltip'

interface Props {
  rr: Rr
  update: (rr: Rr) => void
}

function Alert({ svc, msg }: { svc: HttpsRdata; msg: string }) {
  const res = validateHttp(svc)

  if (!res) {
    return (
      <div data-tip={msg} className="mr-1">
        <MdError className="mt-auto text-red-500" />
        <ReactTooltip
          effect="float"
          type="info"
          place="right"
          backgroundColor={'#F05E5C'}
          textColor={'#FFFFFF'}
        />
      </div>
    )
  }

  return <></>
}

function Services(props: Props) {
  const { rr, update } = props
  if (rr.type !== 'HTTPS') throw Error('FIXME: this is not HTTPS')
  const ref = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useSafeState(CREATE_NEW_SERVICE)
  const [width, setWidth] = useSafeState<undefined | number>(undefined)
  const [svcWidth, setSvcWidth] = useSafeState(0)

  const { t } = useTranslation()

  const { start, stop, inProgress } = useProgress()

  const resize = useCallback(() => {
    if (window != null && ref != null && ref.current != null) {
      const { width } = ref.current.getBoundingClientRect()
      setSvcWidth(width - 40)
      setWidth(width)
    }
  }, [rr.data])

  useEffect(() => {
    resize()

    window.addEventListener('resize', resize)

    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  useEffect(() => {
    resize()
  }, [ref])

  useEffect(() => {
    resize()
    if (ref != null && ref.current != null) {
      ref.current.scrollTop = 0
    }
  }, [rr.data, open])

  useEffect(() => {
    const id = rr.data.length === 0 ? 0 : CREATE_NEW_SERVICE
    rr.data.length === 0 && rr.data.push(deepClone(defaultSvc))
    setOpen(id)
  }, [])

  const toggle = (index: number) => {
    if (index === open) {
      setOpen(-1)
    } else {
      setOpen(index)
    }
  }

  const delSvc = (id: number) => {
    rr.data = rr.data.filter((_, index) => id !== index)
    update({ ...rr })
    setOpen(-1)
  }

  const setDotOfEnd = (domain: string) => {
    return domain.endsWith('.') ? domain : `${domain}.`
  }

  const sortRr = () =>
    rr.data.sort((a, b) => (Number(a.priority) > Number(b.priority) ? 1 : -1))

  const add = (rdata: HttpsRdata) => {
    if (rr.data.length < 32) {
      start()
      rdata.targetName = setDotOfEnd(rdata.targetName)
      rr.data.push(rdata)
      if (rdata.priority !== '') {
        sortRr()
      }

      update({ ...rr })
      setOpen(rr.data.length - 1)
      stop()
    }
  }

  return (
    <div className="mb-16 sm:mb-10">
      <div className="relative px-1 overflow-x-hidden" ref={ref}>
        {rr.data.map((svc, index) => {
          if (index !== open) {
            return (
              <div className="flex items-center" key={index}>
                <FaAngleRight
                  onClick={() => toggle(index)}
                  className="mr-2 cursor-pointer"
                />
                <Alert svc={svc} msg={t('Empty or invalid parameters')} />
                <p
                  className="break-all"
                  style={{
                    width: width != null ? `${svcWidth}px` : undefined,
                  }}
                >
                  {service(svc)}
                </p>
                {rr.data.length > 1 && (
                  <div className="ml-auto">
                    <IoTrash
                      className="cursor-pointer"
                      onClick={() => delSvc(index)}
                    />
                  </div>
                )}
              </div>
            )
          }
          return (
            <div className="mb-8" key={index}>
              <div className="flex items-center font-medium">
                <FaAngleDown
                  onClick={() => toggle(index)}
                  className="mr-2 cursor-pointer"
                />
                <Alert svc={svc} msg={t('Empty or invalid parameters')} />
                <p
                  className="break-all"
                  style={{
                    width: width != null ? `${svcWidth}px` : undefined,
                  }}
                >
                  {service(svc)}
                </p>
                {rr.data.length > 1 && (
                  <div className="ml-auto">
                    <IoTrash
                      className="cursor-pointer"
                      onClick={() => delSvc(index)}
                    />
                  </div>
                )}
              </div>

              <Service
                rdata={rr.data[index]}
                setRdata={(rdata) => {
                  rr.data[index] = rdata
                  update({ ...rr })
                }}
                onClick={() => {}}
              />
            </div>
          )
        })}
      </div>
      {rr.data.length < 32 && (
        <div
          className={`${
            inProgress ? 'text-gray-300' : 'text-blue-400'
          } absolute w-full flex items-center justify-center mt-2 border-t border-gray-300  text-xl`}
          style={{
            width: width != null ? `${width}px` : undefined,
          }}
        >
          <FaPlusCircle
            className={`${inProgress ? '' : 'cursor-pointer'} mr-2`}
            onClick={() => {
              !inProgress && add(deepClone(defaultSvc))
            }}
          />
          <p className="font-medium">{t('Add')}</p>
        </div>
      )}
      {rr.data.length >= 32 && (
        <p className="text-gray-500 font-medium">
          {t('32 services in maximum')}
        </p>
      )}
    </div>
  )
}

export default Services
