export const rrTypes = [
  'A',
  'MX',
  'TXT',
  'AAAA',
  'CNAME',
  'NS',
  'TLSA',
  'SRV',
  'HTTPS',
  'SPF',
  'DKIM',
  'DMARC',
  'BIMI',
]
