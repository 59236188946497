import React, { useEffect, useState } from 'react'
import useTranslation from '@/i18n'
import { useProgress, useSafeState } from '@/hooks'
import TextareaAutosize from 'react-textarea-autosize'
import ErrorMsg from '../ErrorMsg'
import { ValProps } from '@/types'
import { validateDomainInValueExceptDot } from '../validator'
import { border } from '@/utils'

const Value = (props: ValProps) => {
  if (props.rr.type !== 'CNAME') throw Error('Fixme: this is not CNAME record')
  const { t } = useTranslation()
  const { inProgress } = useProgress()
  const [error, setError] = useState('')
  const [value, setValue] = useSafeState('')

  useEffect(() => {
    props.rr.data.length > 0 && setValue(props.rr.data[0])
  }, [])

  const keyDownNew = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      if (props.rr.data.length > 0 && props.rr.data[0].length > 0) {
        props.save()
      }
    }
  }

  const onchange = (e) => {
    e.preventDefault()
    const value = e.target.value.trim()

    if (!validateDomainInValueExceptDot(value)) {
      setError('Invalid value')
    } else {
      setError('')
    }

    props.rr.data = [value]
    setValue(value)
    props.change({ ...props.rr })
  }

  return (
    <div className="mt-4">
      <p className={`text-md font-medium text-gray-900`}>{t('Value')}</p>
      <div className="flex items-center mt-0 w-full">
        <TextareaAutosize
          disabled={inProgress}
          maxLength={253}
          maxRows={5}
          value={value}
          onChange={onchange}
          onKeyDown={keyDownNew}
          className={`text-md text-gray-800 resize-none w-full border-b ${border(
            value,
            error
          )} outline-none focus:outline-none px-1`}
        />
      </div>
      <ErrorMsg error={error} />
    </div>
  )
}

export default Value
