import { useProgress } from '@/hooks'
import React from 'react'
import { isNumber } from '../validator'
import { border } from '@/utils'

type Props = {
  preference: number | string
  change: (prefrence: number | string) => void
  keyDown: () => void
  error: string
}

const Preference = ({ preference, change, keyDown, error }: Props) => {
  const onChange = (e) => {
    e.preventDefault()
    const value = e.target.value.trim()
    if (value === '' || isNumber(value)) {
      change(value)
    }
  }

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      keyDown()
    }
  }

  const { inProgress } = useProgress()

  return (
    <input
      autoFocus={true}
      disabled={inProgress}
      value={preference}
      maxLength={10}
      onChange={(e) => onChange(e)}
      className={`border-b focus:outline-none ${border(
        String(preference),
        error
      )} text-md px-1 col-span-2`}
      onKeyDown={onKeyDown}
    />
  )
}

export default Preference
