import { useSafeState } from '@/hooks'
import React from 'react'
import TargetName from '../TargetName'
import CheckBox from '@/components/CheckBox'
import { HttpsRdata } from '@/api/rrs'

type Props = {
  rdata: HttpsRdata
  update: (rdata: HttpsRdata) => void
  save: () => void
}

function Alias({ rdata, update, save }: Props) {
  const [noService, setNoService] = useSafeState(rdata.targetName === '.')
  const [targetName, setTargetName] = useSafeState(
    rdata.targetName.endsWith('.')
      ? rdata.targetName.slice(0, rdata.targetName.length - 1)
      : rdata.targetName
  )

  const toggle = () => {
    if (!noService) {
      rdata.targetName = '.'
    } else {
      rdata.targetName = ''
    }
    update({ ...rdata })
    setNoService(!noService)
  }

  return (
    <div>
      <CheckBox
        label="No service is provided by this domain"
        color="text-blue-600"
        toggle={toggle}
        checked={noService}
      />
      {!noService && (
        <TargetName
          value={targetName}
          info="DescriptionTargetNameAlias"
          change={(value) => {
            rdata.targetName = value
            setTargetName(value)
            update({ ...rdata })
          }}
          keyDown={() => {
            if (rdata.targetName.length > 0) {
              save()
            }
          }}
        />
      )}
    </div>
  )
}

export default Alias
