import React from 'react'
import useTranslation from '@/i18n'

type SaveProps = {
  disabled: boolean
  save: () => void
}

const Save = ({ save, disabled }: SaveProps) => {
  const { t } = useTranslation()
  return (
    <div className="mt-4 text-right">
      <button
        disabled={disabled}
        className={`${
          disabled ? 'bg-gray-500 cursor-default' : 'bg-blue-500'
        } text-white w-full sm:w-32`}
        onClick={save}
      >
        {t('Save')}
      </button>
    </div>
  )
}

export default Save
