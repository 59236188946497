import React from 'react'
import Selector from '@/components/Rrs/Selector'
import { useProgress } from '@/hooks'
import { policies } from '../Policy'
import { Rr } from '@/api/rrs'

type Props = {
  rr: Rr
  change: (rr: Rr) => void
}

const Sp = ({ rr, change }: Props) => {
  if (rr.type !== 'DMARC') throw Error('FIXME: this is not DMARC')
  const { inProgress } = useProgress()

  const sameAsPolicy = 'Same as policy'

  const current =
    rr.data.length === 0 || rr.data[0].sp == null
      ? sameAsPolicy
      : policies[rr.data[0].sp]

  const select = (index: number) => {
    const { sp, ...rest } = rr.data[0]
    rr.data = [
      {
        sp: index === 0 ? undefined : Object.keys(policies)[index - 1],
        ...rest,
      },
    ]
    change({ ...rr })
  }

  return (
    <div className="mt-4">
      <Selector
        disabled={inProgress}
        title={'sp'}
        required={false}
        info={'Policy for subdomains'}
        vals={[sameAsPolicy, ...Object.values(policies)]}
        current={current}
        select={select}
      />
    </div>
  )
}

export default Sp
