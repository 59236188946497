import React, { useEffect } from 'react'
import { SrvRdata } from '@/api/rrs'
import useTranslation from '@/i18n'
import { useProgress, useSafeState } from '@/hooks'
import ListFrame from '../FlexListFrame'
import { ValProps } from '@/types'
import Rdata from './Rdata'
import { IoTrash } from 'react-icons/io5'
import Preview from './Preview'
import deepClone from 'lodash.clonedeep'
import { validateSrv } from '../validator'
import BtnAdd from '@/components/Parts/BtnAdd'

const _defaultSrv: SrvRdata = {
  priority: 0,
  weight: 0,
  port: 1,
  target: '',
}

const Value = (props: ValProps) => {
  if (props.rr.type !== 'SRV') throw Error('Fixme: this is not SRV record')
  const [disAdd, setDisAdd] = useSafeState(true)
  const { t } = useTranslation()
  const { inProgress } = useProgress()
  const [error, setError] = useSafeState('')

  useEffect(() => {
    if (props.rr.data.length === 0) {
      props.rr.data.push(deepClone(_defaultSrv))
      props.change({ ...props.rr })
    } else {
      setDisAdd(isDisAdd())
    }
  }, [props.rr.data])

  const isDisAdd = () => {
    const isOver = props.rr.data.length === 32

    if (isOver) {
      setError('Reached the limit')
      return true
    } else {
      setError('')
    }

    const noEmpty = props.rr.data.filter((svc) => svc.target.length > 0)

    if (noEmpty.length !== props.rr.data.length) {
      return true
    }

    return noEmpty.some((svc) => !validateSrv(svc))
  }

  const changeCurrentSrv = (id: number, srv: SrvRdata) => {
    props.rr.data[id] = srv
    props.change({ ...props.rr })
    setDisAdd(isDisAdd())
  }

  const currentKeyDown = (id: number, srvRdata: SrvRdata) => {
    if (!disAdd) {
      add()
    }
  }

  const add = () => {
    if (!disAdd) {
      props.rr.data.push(deepClone(_defaultSrv))
      props.change({ ...props.rr })
      setDisAdd(isDisAdd())
    }
  }

  const del = (id: number) => {
    const res = props.rr.data.filter((_, index) => index !== id)
    props.rr.data = res
    props.change({ ...props.rr })
    setDisAdd(isDisAdd())
  }

  const Title = () => (
    <div className="w-full grid grid-cols-12 gap-1">
      <p className="text-md font-medium text-gray-900 truncate col-span-2">
        {t('Priority')}
      </p>
      <p className="text-md font-medium text-gray-900 truncate col-span-2">
        {t('Weight')}
      </p>
      <p className="text-md font-medium text-gray-900 truncate col-span-2">
        {t('Port')}
      </p>
      <p className="text-md font-medium text-gray-900 w-full">{t('Target')}</p>
    </div>
  )

  const List = (data: SrvRdata, index: number) => (
    <div className="flex items-center mt-1" key={index}>
      <Rdata
        total={props.rr.data.length}
        inProgress={inProgress}
        srvRdata={data}
        change={(srv) => changeCurrentSrv(index, srv)}
        blur={(srv) => {}}
        keyDown={(srv) => currentKeyDown(index, srv)}
        key={index}
      >
        {props.rr.data.length === 1 && <></>}
        {props.rr.data.length > 1 && (
          <IoTrash
            className="text-gray-700 cursor-pointer"
            onClick={() => del(index)}
          />
        )}
      </Rdata>
    </div>
  )

  const Input = () => <BtnAdd disAdd={disAdd} add={add} />

  return (
    <>
      <ListFrame
        title={Title}
        listData={props.rr.data}
        list={List}
        input={Input}
        error={error}
      />
      <Preview zoneName={props.zoneName} rr={props.rr} />
    </>
  )
}

export default Value
