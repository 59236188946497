import React, { useEffect, useState } from 'react'
import { MxRdata } from '@/api/rrs'
import useTranslation from '@/i18n'
import { useProgress, useSafeState } from '@/hooks'
import { validateDomainInValue, validatePreference } from '../validator'
import CurrentMx from './CurrentMx'
import { ValProps } from '@/types'
import FlexListFrame from '../FlexListFrame'
import { FaPlusCircle } from 'react-icons/fa'
import deepClone from 'lodash.clonedeep'

const _defaultMx: MxRdata = {
  preference: '',
  exchange: '',
}

const Value = (props: ValProps) => {
  if (props.rr.type !== 'MX') throw Error('Fixme: this is not MX record')
  const [disAdd, setDisAdd] = useSafeState(props.rr.data.length === 0)
  const { t } = useTranslation()
  const { inProgress } = useProgress()
  const [error, setError] = useSafeState('')

  useEffect(() => {
    if (props.rr.data.length === 0) {
      props.rr.data = [deepClone(_defaultMx)]
      props.change({ ...props.rr })
    }
  }, [])

  const maxVals = 32

  const add = () => {
    if (props.rr.data.length < maxVals) {
      props.rr.data.push(deepClone(_defaultMx))
      props.change({ ...props.rr })
    }
    props.rr.data.length === maxVals && setError('Reached the limit')
    setDisAdd(true)
  }

  const changeCurrentMx = (id: number, mx: MxRdata) => {
    if (props.rr.data.length < maxVals) {
      setDisAdd(
        !(
          validateDomainInValue(mx.exchange) &&
          validatePreference(mx.preference)
        )
      )
    }

    props.rr.data[id] = mx
    props.change({ ...props.rr })
  }

  const delCurrentMx = (id: number) => {
    props.rr.data = props.rr.data.filter((_, index) => id != index)
    props.change({ ...props.rr })

    if (props.rr.data.length === 1) {
      const mx = props.rr.data[0]
      setDisAdd(
        !(
          validateDomainInValue(mx.exchange) &&
          validatePreference(mx.preference)
        )
      )
    } else {
      setDisAdd(false)
    }
    setError('')
  }

  const Title = () => (
    <div className={`w-full grid grid-cols-12 gap-1`}>
      <p className="text-md font-medium text-gray-700 col-span-2">
        {t('Preference')}
      </p>
      <p
        className={`hidden sm:block text-md font-medium text-gray-700 col-span-10`}
      >
        {t('Hostname of mail server')}
      </p>
      <p className="block sm:hidden text-md font-medium text-gray-700 col-span-10">
        {t('Hostname')}
      </p>
    </div>
  )

  const List = (data: MxRdata, index: number) => (
    <div className="mt-1" key={index}>
      <CurrentMx
        id={index}
        mx={data}
        change={changeCurrentMx}
        del={delCurrentMx}
        key={index}
        total={props.rr.data.length}
        add={add}
      />
    </div>
  )

  const Input = () => (
    <div className="flex items-center mt-0 w-full text-md">
      <FaPlusCircle
        className={`${
          disAdd || inProgress
            ? 'text-gray-400'
            : 'text-green-500 cursor-pointer'
        }  mr-2`}
        onClick={add}
      />
      <p
        className={`${
          disAdd || inProgress ? 'text-gray-400' : 'text-gray-800'
        }`}
      >
        {t('Add')}
      </p>
    </div>
  )

  return (
    <FlexListFrame
      title={Title}
      listData={props.rr.data}
      list={List}
      input={Input}
      error={error}
    />
  )
}

export default Value
