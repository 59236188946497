import React, { useEffect, useState } from 'react'
import useTranslation from '@/i18n'
import { Rr } from '@/api/rrs'
import { IoIosArrowUp, IoIosArrowDown, IoIosSettings } from 'react-icons/io'
import Sp from './Sp'
import Fo from './Fo'
import Adkim from './Adkim'
import Aspf from './Aspf'
import Ri from './Ri'
import Pct from './Pct'

type Props = {
  rr: Rr
  change: (rr: Rr) => void
  save: () => void
}

const Open = ({ open, toggle }: { open: boolean; toggle: () => void }) => {
  if (!open) {
    return (
      <IoIosArrowDown className="cursor-pointer text-lg" onClick={toggle} />
    )
  }

  return <IoIosArrowUp className="cursor-pointer text-lg" onClick={toggle} />
}

const Advanced = ({ rr, change, save }: Props) => {
  if (rr.type !== 'DMARC') throw Error('FIXME: this is not DMARC')

  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (rr.data.length > 0) {
      if (
        rr.data[0].sp != null ||
        rr.data[0].fo != null ||
        rr.data[0].aspf != null ||
        rr.data[0].adkim != null ||
        rr.data[0].pct != null ||
        rr.data[0].ri != null
      ) {
        setOpen(true)
      }
    }
  }, [])

  return (
    <div className="mt-4">
      <div className="flex items-center text-gray-900 text-md">
        <IoIosSettings className="text-lg text-green-500 mr-1" />
        <p className="font-medium mr-2">{t('Advanced settings')}</p>
        <Open open={open} toggle={() => setOpen(!open)} />
      </div>
      {open && (
        <div className="pb-4">
          <div className="sm:flex sm:items-center w-full">
            <div className="mr-2 w-full">
              <Sp rr={rr} change={change} />
            </div>
            <div className="w-full">
              <Fo rr={rr} change={change} />
            </div>
          </div>
          <div className="sm:flex sm:items-center w-full">
            <div className="mr-2 w-full">
              <Adkim rr={rr} change={change} />
            </div>
            <div className="w-full">
              <Aspf rr={rr} change={change} />
            </div>
          </div>
          <div className="sm:flex sm:items-center w-full">
            <div className="w-full mr-2">
              <Ri rr={rr} change={change} save={save} />
            </div>
            <Pct rr={rr} change={change} save={save} />
          </div>
        </div>
      )}
    </div>
  )
}

export default Advanced
