import { Rr } from '@/api/rrs'
import {
  parse as defaultParse,
  HostName,
  convertOldHostName,
  convertRr,
  parseHostName,
} from '../Common'
import Value from './Value'
import { validateTtl, validateHostName } from '../validator'

export function parse(zoneName: string, rr: Rr) {
  return defaultParse(zoneName, rr)
}

export function create() {
  function isReady(rr: Rr) {
    if (rr.type !== 'TXT') throw Error('Fixme: this is not TXT record')

    if (
      rr.hostName !== '' &&
      rr.hostName !== '@' &&
      !validateHostName(rr.hostName)
    )
      return false

    if (!validateTtl(rr.ttl)) return false

    if (rr.data.length === 0) return false

    const validTxts = rr.data.filter((txt) => txt.length !== 0)

    return validTxts.length !== 0
  }

  return {
    HostName,
    Val: Value,
    isReady,
    convertOldHostName,
    convertRr: (rr: Rr, zoneName: string) => {
      if (rr.type !== 'TXT') throw Error('FIXME this is not TXT')
      rr.hostName = rr.hostName === '' ? '@' : rr.hostName
      rr.data = rr.data.filter((txt) => txt.length > 0)
      return rr
    },
  }
}

export { parseHostName }
