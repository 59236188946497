import React, { useState } from 'react'
import { MxRdata } from '@/api/rrs'
import Preference from './Preference'
import Exchange from './Exchange'
import { useProgress } from '@/hooks'
import ErrorMsg from '../ErrorMsg'
import { validateDomainInValue, validatePreference } from '../validator'

type Props = {
  id: number
  mx: MxRdata
  change: (id: number, mx: MxRdata) => void
  del: (id: number) => void
  add: () => void
  total: number
}

const CurrentMx = ({ id, mx, change, del, add, total }: Props) => {
  const { inProgress } = useProgress()
  const [error, setError] = useState('')

  const onKeyDown = () => {
    if (mx.exchange != '' && mx.preference != '') {
      if (
        validateDomainInValue(mx.exchange) &&
        validatePreference(mx.preference)
      ) {
        add()
      }
    }
  }

  const update = () => {
    change(id, mx)
  }

  return (
    <>
      <div className="w-full grid grid-cols-12 gap-1 text-md text-gray-700">
        <Preference
          preference={mx.preference}
          change={(val) => {
            mx.preference = val
            if (!validatePreference(val)) {
              setError('Preference should be between 0 and 65535')
            } else {
              setError('')
            }
            update()
          }}
          keyDown={onKeyDown}
          error={error}
        />
        <Exchange
          exchange={mx.exchange}
          total={total}
          change={(val) => {
            mx.exchange = val
            if (!validateDomainInValue(val)) {
              setError('Invalid hostname')
            } else {
              setError('')
            }
            change(id, mx)
            update()
          }}
          del={() => del(id)}
          keyDown={onKeyDown}
          error={error}
        />
      </div>
      <ErrorMsg error={error} />
    </>
  )
}

export default CurrentMx
