import React, { useEffect } from 'react'
import useTranslation from '@/i18n'
import { isNumber } from '../../validator'
import ErrorMsg from '@/components/Rrs/ErrorMsg'
import { useSafeState } from '@/hooks'
import Require from '@/components/Rrs/Require'
import { border } from '@/utils'

interface Props {
  value: string
  change: (value: string) => void
  keyDown: () => void
}

function Priority(props: Props) {
  const [error, setError] = useSafeState('')
  const { t } = useTranslation()
  const [value, setValue] = useSafeState(props.value)

  const onChange = (e) => {
    e.preventDefault()
    const value = e.target.value.trim()
    if (
      value === '' ||
      (isNumber(value) && Number(value) <= 65535 && Number(value) > 0)
    ) {
      if (value === '') {
        setError('Priority must be between 1 to 65535')
      } else {
        setError('')
      }

      setValue(value)
      props.change(value)
    }
  }

  const keyDown = (e: any) => {
    if (e.keyCode === 13) {
      props.keyDown()
    }
  }

  return (
    <div className="mt-4">
      <div className="flex items-center text-md font-medium">
        <p className="text-gray-900 mr-2">{t('Priority')}</p>
      </div>
      <input
        value={value}
        onChange={onChange}
        className={`border-b focus:outline-none ${border(
          value,
          error
        )} text-md w-full px-2 mr-1`}
        placeholder={''}
        onKeyDown={keyDown}
      />
      <ErrorMsg error={error} />
    </div>
  )
}

export default Priority
