import { axios, getAuthorization } from '@/api/axios'
import { withRefreshToken } from '@/auth'
import { errConverter } from './error'

export type PublishDs = {
  dsList: { ds: string; ttl: string }[]
}

export const manualStartZone = (zoneId?: number): Promise<PublishDs> =>
  new Promise<PublishDs>((resolve, reject) => {
    axios
      .post<{ message: string }>(
        'dev/manual-start-zone',
        {
          zoneId,
        },
        {
          headers: getAuthorization(),
        }
      )
      .then((result) => {
        resolve(JSON.parse(result.data.message))
      })
      .catch((err) => {
        reject(errConverter(err))
      })
  })

const post = withRefreshToken(manualStartZone)

export { post }
