import React, { useEffect } from 'react'
import useTranslation from '@/i18n'
import { useProgress, useSafeState } from '@/hooks'
import TextareaAutosize from 'react-textarea-autosize'
import { IoTrash } from 'react-icons/io5'
import ListFrame from '../FlexListFrame'
import { ValProps } from '@/types'
import { border } from '@/utils'
import BtnAdd from '@/components/Parts/BtnAdd'

const Value = (props: ValProps) => {
  if (props.rr.type !== 'TXT') throw Error('Fixme: this is not TXT record')
  const { t } = useTranslation()
  const { inProgress } = useProgress()
  const [disAdd, setDisAdd] = useSafeState(props.rr.data.length === 0)
  const [error, setError] = useSafeState('')

  useEffect(() => {
    if (props.rr.data.length === 0) {
      props.rr.data = ['']
      props.change({ ...props.rr })
    }
  }, [props.rr.data])

  const isDisAdd = () => {
    const isOver = props.rr.data.length === 32

    if (isOver) {
      setError('Reached the limit')
    } else {
      setError('')
    }

    return isOver || props.rr.data.some((txt) => txt.length === 0)
  }

  const update = (txts: string[]) => {
    props.rr.data = [...txts]
    props.change({ ...props.rr })
  }

  const add = () => {
    if (!disAdd) {
      props.rr.data.push('')
      props.change({ ...props.rr })
      setDisAdd(isDisAdd())
    }
  }

  const del = (id: number) => {
    const res = props.rr.data.filter((_, index) => {
      return index !== id
    })
    update(res)
    setDisAdd(isDisAdd())
  }

  const changeVal = (id: number, txt: string) => {
    props.rr.data[id] = txt
    update(props.rr.data)
    setDisAdd(isDisAdd())
  }

  const keyDownCurrent = (e, index) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      add()
    }
  }

  const Title = () => (
    <p className="text-md font-medium text-gray-900">{t('TXT')}</p>
  )

  const List = (data: string, index: number) => (
    <div className="flex items-center mt-1" key={index}>
      <TextareaAutosize
        disabled={inProgress}
        maxLength={65000}
        maxRows={5}
        value={data}
        onChange={(e) => {
          changeVal(index, e.target.value)
        }}
        onKeyDown={(e) => keyDownCurrent(e, index)}
        onBlur={() => {}}
        autoFocus={true}
        className={`text-md text-gray-800 resize-none w-full border-b ${border(
          data,
          ''
        )} outline-none focus:outline-none px-1`}
      />
      {props.rr.data.length > 1 && (
        <IoTrash
          className={`${
            inProgress ? 'text-gray-400' : 'cursor-pointer'
          } text-gray-700`}
          onClick={() => del(index)}
        />
      )}
    </div>
  )

  const Input = () => <BtnAdd disAdd={disAdd} add={add} />

  return (
    <ListFrame
      title={Title}
      listData={props.rr.data}
      list={List}
      input={Input}
      error={error}
    />
  )
}

export default Value
