import { useProgress } from '@/hooks'
import React, { useEffect, useState } from 'react'
import ErrorMsg from '../ErrorMsg'
import { validateTtl, isNumber } from '../validator'

type Props = {
  ttl: number | string
  change: (ttl: number | string) => void
  save: () => void
}

const Ttl = ({ ttl, change, save }: Props) => {
  const [val, setVal] = useState(ttl)
  const [error, setError] = useState('')
  const { inProgress } = useProgress()

  useEffect(() => {
    setVal(ttl)
  }, [ttl])

  const onchange = (value: string) => {
    if (!validateTtl(value)) {
      setError('TTL must be 300 to 86400')
    } else {
      setError('')
    }

    if (value === '' || isNumber(value)) {
      const res = value === '' ? value : Number(value)
      setVal(res)
      change(res)
    }
  }

  const keyDown = (e) => {
    if (e.keyCode === 13) {
      save()
    }
  }

  return (
    <div className="mt-4">
      <p className="text-md font-medium text-gray-900">TTL</p>
      <input
        value={val}
        disabled={inProgress}
        maxLength={5}
        onChange={(e) => onchange(e.target.value)}
        className={`border-b focus:outline-none ${
          val === '' || error !== '' ? 'border-red-500' : 'border-gray-500'
        } text-md w-full px-2 mr-1`}
        placeholder={'300'}
        onKeyDown={(e) => keyDown(e)}
      />
      <ErrorMsg error={error} />
    </div>
  )
}

export default Ttl
