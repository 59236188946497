import React from 'react'
import { Rr, SrvRdata } from '@/api/rrs'
import { convertOldHostName } from '../Common'
import HostName, { parseHostName } from './Hostname'
import { validateSrvRr } from '../validator'
import Value from './Value'
import deepClone from 'lodash.clonedeep'
import { absoluteDomain2relativeDomain } from '@/utils'

export function parse(zoneName: string, rr: Rr) {
  if (rr.type !== 'SRV') {
    throw Error(`Fixme!! this is not SRV: ${JSON.stringify(rr)}`)
  }

  const fqdn =
    rr.hostName === '' || rr.hostName === '@'
      ? `${zoneName}.`
      : `${rr.hostName}.${zoneName}.`

  const Val = ({ value }: { value: SrvRdata }) => {
    const { priority, weight, port, target } = value

    return (
      <>
        <p className="truncate">{`priority: ${priority}`}</p>
        <p className="truncate">{`weight: ${weight}`}</p>
        <p className="truncate">{`port: ${port}`}</p>
        <p className="truncate">{`target: ${target}`}</p>
      </>
    )
  }

  const hostName = rr.hostName === '' ? '@' : rr.hostName

  return { fqdn, hostName, rrType: rr.type, Val }
}

export function create() {
  return {
    HostName,
    Val: Value,
    isReady: (rr: Rr) => validateSrvRr(rr),
    convertOldHostName,
    convertRr: (rr: Rr, zoneName: string) => {
      if (rr.type !== 'SRV') throw Error('FIXME: this is not SRV')

      const shadow = deepClone(rr)

      shadow.data = shadow.data.map(({ target, ...rest }) => {
        if (target === '.') return { ...rest, target }

        const newTarget = absoluteDomain2relativeDomain(target, zoneName)

        return { ...rest, target: newTarget }
      })
      return shadow
    },
  }
}

export { parseHostName }
