import React from 'react'
import useTranslation from '@/i18n'
import { useProgress } from '@/hooks'

type Props = {
  checked: boolean
  toggle: () => void
  label: string
  color?: string
}

function CheckBox(props: Props) {
  const { t } = useTranslation()
  const { inProgress } = useProgress()

  return (
    <label
      className={`inline-flex items-center ${inProgress && 'text-gray-300'}`}
    >
      <input
        disabled={inProgress}
        type="checkbox"
        className={`form-checkbox ${!inProgress &&
          'text-blue-500 cursor-pointer'}`}
        onChange={props.toggle}
        checked={props.checked}
      />
      <span className="ml-2 text-md mr-2 break-all">{t(props.label)}</span>
    </label>
  )
}

export default CheckBox
