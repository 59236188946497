import { useModal } from '@/hooks'
import useTranslation from '@/i18n'
import React from 'react'
import { FaBug } from 'react-icons/fa'
import { ErrCode } from '@/api/error'

interface Props {
  status: number
  subCode: number
  message: string
}

function BimiLogoError(props: Props) {
  const { t } = useTranslation()
  const { closeModal } = useModal()

  return (
    <div className="sm:w-118 px-4 sm:px-8">
      <h1 className="font-bold text-gray-700">{t('Validation result')}</h1>

      <div className="flex items-center text-xl font-bold text-red-500">
        <FaBug className="mr-2" />
        <p className="">{t('Validation failed')}</p>
      </div>
      <p className="text-gray-500 text-sm font-medium my-2">
        {t('Error details')}
      </p>
      <div className="w-64 sm:w-116 h-32 overflow-x-auto py-2 text-gray-900 rounded-md">
        {props.subCode === ErrCode.FailedToDownloadBimiImage && (
          <p className="">{t('FailedDownloadSvg')}</p>
        )}
        {props.subCode === ErrCode.FailedToValidateBimiLogo && (
          <table cellPadding="8" className="text-sm">
            <tr>
              <th>Line</th>
              <th>Column</th>
              <th>Type</th>
              <th>Error</th>
              <th>Details</th>
            </tr>
            <tbody className="">
              {props.message.split('\n').map((lines) => {
                const res = lines.split(', ')
                return (
                  <tr>
                    <td className="align-top">{res[0]}</td>
                    <td className="align-top">{res[1]}</td>
                    <td className="align-top ">{res[2]}</td>
                    <td className="align-top text-red-500">{res[3]}</td>
                    <td className="align-top">{res.slice(4).join('\n')}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}
      </div>
      <div className="pt-4 py-8 text-right">
        <button
          className={`bg-blue-500 text-white px-8 text-md rounded-sm focus:outline-none`}
          onClick={() => {
            closeModal()
          }}
        >
          {t('Close')}
        </button>
      </div>
    </div>
  )
}

export default BimiLogoError
