import * as Sentry from '@sentry/browser'
import React, { useEffect, useRef } from 'react'
import { withLayout } from '@/layout'
import useTranslation from '@/i18n'
import { get, Rrs, Rr } from '@/api/rrs'
import { IoChevronBackOutline } from 'react-icons/io5'
import {
  useProgress,
  useWaitSignIn,
  useRightBar,
  useErr,
  useModal,
  useSafeState,
} from '@/hooks'
import { PageRendererProps } from 'gatsby'
import Link from '@/components/Link'
import * as queryString from 'query-string'
import { RrsEditor, Delete } from '@/components/Rrs'
import Subdomain from '@/components/Subdomain'
import ZoneTransfer from '@/components/Rrs/ZoneTransfer'
import Header from '@/components/Rrs/Header'
import RrsData from '@/components/Rrs/RrsData'
import Table from '@/components/Table'
import Reload from '@/components/Reload'

const _defaultRr: Rr = {
  hostName: '@',
  ttl: 300,
  type: 'A',
  data: [],
}

const ResourceRecordSet = ({ location }: PageRendererProps) => {
  const { inProgress, start, stop } = useProgress()
  const { isOpenRightBar, openRightBar, closeRightBar } = useRightBar()
  const refIsOpenRightBar = useRef(isOpenRightBar)
  const { setErr } = useErr()
  const waitSignIn = useWaitSignIn()
  const { openModal } = useModal()
  const [rrs, setRrs] = useSafeState<Rrs | null>(null)
  const { t } = useTranslation()

  const { zoneName, zoneId } = queryString.parse(location.search)
  const zonename: string = typeof zoneName === 'string' ? zoneName : ''
  const zoneid: number = typeof zoneId === 'string' ? Number(zoneId) : 0

  const loadRrs = () => {
    start()
    return get(zoneid)
      .then((rrs) => {
        setRrs(rrs)
        stop()
      })
      .catch((err) => {
        setErr(err.message, err.status)
        stop()
      })
  }

  useEffect(() => {
    waitSignIn(loadRrs)

    return () => {
      stop()
    }
  }, [])

  useEffect(() => {
    refIsOpenRightBar.current = isOpenRightBar
  }, [isOpenRightBar])

  const createHostName = (rr: Rr) => {
    if (rr.type === 'DKIM') {
      const dkimHostName =
        rr.hostName === '' || rr.hostName === '@'
          ? `${rr.data[0].selector}._domainkey`
          : `${rr.data[0].selector}._domainkey.${rr.hostName}`
      return dkimHostName
    } else if (rr.type === 'DMARC') {
      const dmarcHostName =
        rr.hostName === '' || rr.hostName === '@'
          ? '_dmarc'
          : `_dmarc.${rr.hostName}`
      return dmarcHostName
    }

    return rr.hostName
  }

  const searchSameHosts = (newRr: Rr, curRrs: Rr[]) => {
    const newHostName = createHostName(newRr)
    return curRrs.some((rr) => {
      if (newRr.type === 'CNAME') {
        return createHostName(rr) === newHostName
      } else {
        if (rr.type === 'CNAME') {
          return rr.hostName === newHostName
        } else if (rr.type === newRr.type) {
          return createHostName(rr) === newHostName
        }
        return false
      }
    })
  }

  const update = (id: number) => {
    closeRightBar()

    const intervalId = setInterval(() => {
      if (!refIsOpenRightBar.current) {
        clearInterval(intervalId)
        const rr = rrs?.rrs[id]!
        openRightBar(
          <RrsEditor
            zoneId={zoneid}
            zoneName={zonename}
            checkExists={(newRr) =>
              searchSameHosts(newRr, rrs == null ? [] : rrs.rrs)
            }
            reload={loadRrs}
            rr={rr}
          />
        )
      }
    }, 50)
  }

  const del = (name: string, type: string) => {
    if (!inProgress) {
      openModal(
        <Delete
          zoneId={zoneid}
          zoneName={zonename}
          hostName={name}
          type={type}
          reload={loadRrs}
        />
      )
    }
  }

  const getNsList = () => {
    if (rrs != null) {
      const nsList = rrs.rrs
        .filter((rr) => {
          if (rr.type.toUpperCase() === 'NS') {
            return rr.hostName
          }
        })
        .map((rr) => rr.hostName)
      return nsList
    }
    return []
  }

  return (
    <>
      <div className="flex items-center mb-4">
        <Link to={'/zones'}>
          <IoChevronBackOutline className="text-3xl text-gray-600 cursor-pointer mr-2" />
        </Link>
        <h1 className="text-3xl text-gray-600 truncate">{zonename}</h1>
      </div>
      <ZoneTransfer
        zoneId={zoneid}
        zoneName={zonename}
        rrs={rrs == null ? [] : rrs.rrs}
      />
      <div className="sm:flex sm:items-center mb-4">
        <h1 className="text-gray-700 text-base md:text-2xl font-bold mr-8 whitespace-no-wrap">
          {t('Record list')}
        </h1>
        <button
          disabled={isOpenRightBar}
          className={`px-1 md:px-4 text-white whitespace-no-wrap text-xs md:text-base rounded-sm focus:outline-none mr-2 sm:ml-auto ${
            isOpenRightBar || inProgress ? 'bg-gray-300' : 'bg-red-600'
          }`}
          onClick={() => {
            openRightBar(
              <RrsEditor
                zoneId={zoneid}
                zoneName={zonename}
                checkExists={(newRr) =>
                  searchSameHosts(newRr, rrs == null ? [] : rrs.rrs)
                }
                reload={loadRrs}
                rr={_defaultRr}
              />
            )
          }}
        >
          {t('Add a new record')}
        </button>
        <button
          disabled={isOpenRightBar}
          className={`px-1 md:px-4 text-white whitespace-no-wrap text-xs md:text-base rounded-sm focus:outline-none ${
            isOpenRightBar || inProgress ? 'bg-gray-300' : 'bg-green-600'
          }`}
          onClick={() => {
            openRightBar(
              <Subdomain
                zoneName={zonename}
                nsList={getNsList()}
                loadRrs={loadRrs}
              />
            )
          }}
        >
          {t('Add a subdomain')}
        </button>
      </div>
      <Reload load={loadRrs} />
      <Table
        header={Header}
        listData={rrs == null ? [] : rrs.rrs}
        list={(rr, index) =>
          RrsData({ index, zoneName: zonename, inProgress, rr, update, del })
        }
      />
    </>
  )
}

const RrsPage = withLayout(ResourceRecordSet)

export default RrsPage
