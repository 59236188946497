import React from 'react'
import { Rr } from '@/api/rrs'
import { useSafeState } from '@/hooks'
import { useProgress } from '@/hooks'
import useTranslation from '@/i18n'
import Open from '../Open'
import Step1 from '../Step1'
import Step2 from '../Step2'
import Step3 from './Step3'

type Props = {
  zoneId: number
  zoneName: string
  step: number
  setStep: (v: number) => void
  rrs: Rr[]
}

const AutoZoneTransfer = ({ zoneId, zoneName, rrs, step, setStep }: Props) => {
  const [open, setOpen] = useSafeState<boolean>(true)
  const { t } = useTranslation()

  return (
    <div className="mb-4 px-4">
      <div className="flex items-center w-48">
        <h1 className="text-lg font-medium text-gray-700  mr-2">
          {t('Transfer zone')}
        </h1>
        <Open open={open} click={() => setOpen(!open)} />
      </div>
      {open && (
        <div className="bg-gray-100 px-4 py-2 w-80 sm:w-full ">
          <Step1 rrs={rrs} zoneId={zoneId} step={step} setStep={setStep} />
          <Step2
            domainName={zoneName}
            zoneId={zoneId}
            step={step}
            mode={0}
            setStep={setStep}
          />
          <Step3
            domainName={zoneName}
            zoneId={zoneId}
            step={step}
            setStep={setStep}
          />
        </div>
      )}
    </div>
  )
}

export default AutoZoneTransfer
