import { useProgress } from '@/hooks'
import React, { ReactNode, useState } from 'react'
import Selector from '../Selector'
import { validateSpf } from '../validator'
import ErrorMsg from '../ErrorMsg'
import { SpfVal } from '@/types'

export function converter(val: string[]): SpfVal {
  const getQualifier = () => {
    if (['+', '-'].includes(val[0])) {
      return val[0]
    }
    return '+'
  }

  const qualifier = getQualifier()

  return {
    qualifier,
    mechanism: val[1],
    value: val[2],
  }
}

export function parser(spfVal: SpfVal): string[] {
  return [spfVal.qualifier, spfVal.mechanism, spfVal.value]
}

type Props = {
  spfVal: SpfVal
  change: (spfVal: SpfVal) => void
  keyDown: (spfVal: SpfVal) => void
  onBlur?: (spfVal: SpfVal) => void
  total: number
  children: ReactNode
}

const _qualifier = ['+', '-']
const _mechanism = ['ip4', 'ip6', 'include']

const Input = ({ spfVal, change, keyDown, onBlur, total, children }: Props) => {
  const { inProgress } = useProgress()
  const [error, setError] = useState('')

  const selectQualifier = (id: number) => {
    spfVal.qualifier = _qualifier[id]
    change({ ...spfVal })
  }

  const validate = () => {
    if (spfVal.value != '' && !validateSpf(spfVal)) {
      if (['ip4', 'ip6'].includes(spfVal.mechanism)) {
        setError('Invalid IP address')
      } else if (spfVal.mechanism === 'include') {
        setError('Invalid value')
      }
    } else {
      setError('')
    }
  }

  const selectMechanism = (id: number) => {
    spfVal.mechanism = _mechanism[id]
    validate()
    change({ ...spfVal })
  }

  const changeValue = (value: string) => {
    spfVal.value = value
    validate()
    change({ ...spfVal })
  }

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      keyDown(spfVal)
    }
  }

  return (
    <>
      <div className="grid grid-cols-12 gap-1">
        <div className="col-span-2">
          <Selector
            current={spfVal.qualifier}
            vals={_qualifier}
            disabled={inProgress}
            select={selectQualifier}
          />
        </div>
        <div className="col-span-2">
          <Selector
            current={spfVal.mechanism}
            vals={_mechanism}
            disabled={inProgress}
            select={selectMechanism}
          />
        </div>
        <div className={`col-span-7`}>
          <input
            autoFocus={true}
            disabled={inProgress}
            maxLength={254}
            className="focus:outline-none border-b px-2 text-md py-0 border-gray-500 w-full"
            value={spfVal.value}
            onChange={(e) => changeValue(e.target.value)}
            onKeyDown={onKeyDown}
            onBlur={() => {}}
          />
        </div>
        {<div className="col-span-1">{children}</div>}
      </div>
      <ErrorMsg error={error} />
    </>
  )
}

export default Input
