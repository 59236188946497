import React from 'react'
import { Rr, MxRdata } from '@/api/rrs'
import { HostName, convertOldHostName, parseHostName } from '../Common'
import Value from './Value'
import {
  validateTtl,
  validateDomainInValue,
  validatePreference,
  validateHostName,
} from '../validator'
import { absoluteDomain2relativeDomain } from '@/utils'
import deepClone from 'lodash.clonedeep'
import Preference from './Preference'

export function parse(zoneName: string, rr: Rr) {
  if (rr.type !== 'MX') {
    throw Error(`Fixme!! this is not MX: ${JSON.stringify(rr)}`)
  }

  const fqdn =
    rr.hostName === '' || rr.hostName === '@'
      ? `${zoneName}.`
      : `${rr.hostName}.${zoneName}.`

  const Val = ({ value }: { value: MxRdata }) => {
    const { preference, exchange } = value

    return <p className="truncate">{`${preference} ${exchange}`}</p>
  }

  const hostName = rr.hostName === '' ? '@' : rr.hostName

  return { fqdn, hostName, rrType: rr.type, Val }
}

export function create() {
  function isReady(rr: Rr) {
    if (rr.type !== 'MX') throw Error('Fixme: this is not MX record')

    if (
      rr.hostName !== '' &&
      rr.hostName !== '@' &&
      !validateHostName(rr.hostName)
    )
      return false

    if (!validateTtl(rr.ttl)) return false

    if (rr.data.length === 0) return false

    const noEmpty = rr.data.filter(
      (mx) => mx.exchange.length > 0 && String(mx.preference).length > 0
    )

    if (noEmpty.length === 0) {
      return false
    }

    const res = noEmpty.filter(
      (mx) =>
        validateDomainInValue(mx.exchange) && validatePreference(mx.preference)
    )

    return res.length > 0 && noEmpty.length === res.length
  }

  return {
    HostName,
    Val: Value,
    isReady,
    convertOldHostName,
    convertRr: (rr: Rr, zoneName: string) => {
      if (rr.type !== 'MX') throw Error('FIXME: this is not MX')
      const shadow = deepClone(rr)
      shadow.hostName = rr.hostName === '' ? '@' : rr.hostName

      shadow.data = shadow.data
        .filter(
          ({ preference, exchange }) =>
            String(preference).length > 0 && exchange.length > 0
        )
        .map(({ preference, exchange }) => {
          if (exchange === '.') return { preference, exchange }

          const newExchange = absoluteDomain2relativeDomain(exchange, zoneName)

          return { preference, exchange: newExchange }
        })
      return shadow
    },
  }
}

export { parseHostName }
