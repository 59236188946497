import React, { useEffect, useRef, useCallback } from 'react'
import { useModal, useSafeState } from '@/hooks'
import useTranslation from '@/i18n'

import { FaCheck } from 'react-icons/fa'

type Props = {
  img: string
}

function BimiLogoSuccess({ img }: Props) {
  const { t } = useTranslation()
  const { closeModal } = useModal()
  const refImg = useRef<HTMLImageElement | null>(null)
  const [w, setW] = useSafeState(0)

  const resize = useCallback(() => {
    if (window != null && refImg != null && refImg.current != null) {
      const { width } = refImg.current.getBoundingClientRect()
      setW(width)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', resize)

    if (refImg.current != null) {
      const { width } = refImg.current.getBoundingClientRect()
      setW(width)
    }

    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  return (
    <div className="px-1 sm:w-96 sm:px-4 pt-2 pb-8">
      <h1 className="text-lg font-bold text-gray-800 border-b border-gray-400">
        {t('Validation result')}
      </h1>
      <div className="mt-2 flex items-center text-xl font-bold text-green-500">
        <FaCheck className="mr-2" />
        <p className="">{t('Did not detect a problem')}</p>
      </div>
      <div className="text-purple-800 mt-2 font-medium inline-block">
        {t('BIMI SVG Image')}
      </div>
      <div
        className="w-full bg-gray-400 mt-1 rounded-md"
        ref={refImg}
        style={{
          height: `${w}px`,
        }}
      >
        <img src={img} className="rounded-full " />
      </div>

      <div className="pt-8 text-right">
        <button
          className={`bg-blue-500 text-white px-8 text-md rounded-sm focus:outline-none`}
          onClick={() => {
            closeModal()
          }}
        >
          {t('Close')}
        </button>
      </div>
    </div>
  )
}

export default BimiLogoSuccess
