import React from 'react'
import Selector from '@/components/Rrs/Selector'
import { useProgress } from '@/hooks'
import { Rr, HValue } from '@/api/rrs'

type Props = {
  rr: Rr
  change: (rr: Rr) => void
}

const _h = ['sha256', 'sha1']

const Hash = ({ rr, change }: Props) => {
  if (rr.type !== 'DKIM') throw Error('FIXME: this is not DKIM')
  const { inProgress } = useProgress()

  const current =
    rr.data.length === 0 || rr.data[0].h == null ? 'rsa' : rr.data[0].h

  const select = (index: number) => {
    const { h, ...rest } = rr.data[0]
    rr.data = [
      {
        h: index === 0 ? undefined : (_h[index] as HValue),
        ...rest,
      },
    ]
    change({ ...rr })
  }

  return (
    <div className="mt-4">
      <Selector
        disabled={inProgress}
        title={'Hash'}
        vals={_h}
        current={current}
        select={select}
      />
    </div>
  )
}

export default Hash
