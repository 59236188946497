import { Rr } from '@/api/rrs'
import {
  parse as defaultParse,
  convertOldHostName,
  parseHostName,
} from '../Common'
import Value from './Value'
import HostName from './Hostname'
import { absoluteDomain2relativeDomain } from '@/utils'
import {
  validateTtl,
  validateDomainName,
  validateDomainInValueExceptDot,
} from '../validator'
import deepClone from 'lodash.clonedeep'

export function parse(zoneName: string, rr: Rr) {
  return defaultParse(zoneName, rr)
}

export function create() {
  function isReady(rr: Rr) {
    if (rr.type !== 'NS') throw Error('Fixme: this is not NS record')

    if (['@', ''].includes(rr.hostName) || !validateDomainName(rr.hostName))
      return false

    if (!validateTtl(rr.ttl)) return false

    if (rr.data.length === 0) return false

    const nonEmpty = rr.data.filter((ns) => ns.length > 0)

    if (nonEmpty.length === 0) {
      return false
    }

    const nss = new Set(nonEmpty)

    if (nss.size !== nonEmpty.length) {
      return false
    }

    // const nss = rr.data.filter(ns => ns.length > 0)

    const res = nonEmpty.filter((email) =>
      validateDomainInValueExceptDot(email)
    )

    return nonEmpty.length === res.length
  }

  return {
    HostName,
    Val: Value,
    isReady,
    convertOldHostName,
    convertRr: (rr: Rr, zoneName: string) => {
      if (rr.type !== 'NS') throw Error('FIXME: this is not NS')

      const shadow = deepClone(rr)
      shadow.hostName = rr.hostName === '' ? '@' : rr.hostName

      shadow.data = shadow.data
        .filter((ns) => ns.length > 0)
        .map((value) => absoluteDomain2relativeDomain(value, zoneName))
      return shadow
    },
  }
}

export { parseHostName }
