import React from 'react'
import Selector from '@/components/Rrs/Selector'
import { useProgress } from '@/hooks'
import { Rr } from '@/api/rrs'

type Props = {
  rr: Rr
  change: (rr: Rr) => void
}

const Adkim = ({ rr, change }: Props) => {
  if (rr.type !== 'DMARC') throw Error('FIXME: this is not DMARC')
  const { inProgress } = useProgress()

  const _adkim = {
    ['r']: 'Relaxed',
    ['s']: 'Strict',
  }

  const current =
    rr.data.length === 0 || rr.data[0].adkim == null ? 'r' : rr.data[0].adkim

  const select = (index: number) => {
    const { adkim, ...rest } = rr.data[0]
    rr.data = [
      {
        adkim: index === 0 ? undefined : Object.keys(_adkim)[index],
        ...rest,
      },
    ]
    change({ ...rr })
  }

  return (
    <div className="mt-4">
      <Selector
        disabled={inProgress}
        title={'adkim'}
        info={'DKIM alignment mode'}
        vals={Object.values(_adkim)}
        current={_adkim[current]}
        select={select}
      />
    </div>
  )
}

export default Adkim
