import React from 'react'
import useTranslation from '@/i18n'
import Require from '@/components/Rrs/Require'
import { validateTargetName } from '../validator'
import ErrorMsg from '../ErrorMsg'
import { useProgress, useSafeState } from '@/hooks'
import { border } from '@/utils'

type Props = {
  value: string
  info: string
  change: (value: string) => void
  keyDown: () => void
}

function TargetName(props: Props) {
  const [error, setError] = useSafeState('')
  const { t } = useTranslation()
  const { inProgress } = useProgress()

  const change = (e: any) => {
    let value = e.target.value.trim()
    if (!validateTargetName(value)) {
      setError('Invalid target name')
    } else {
      setError('')
    }
    props.change(value)
  }

  const keyDown = (e: any) => {
    if (e.keyCode === 13) {
      props.keyDown()
    }
  }

  return (
    <div className="mt-2">
      <div className="flex items-center text-md font-medium">
        <p className="text-gray-900 mr-2">{t('Target name')}</p>
      </div>
      <input
        disabled={inProgress}
        value={props.value}
        onChange={change}
        className={`border-b focus:outline-none ${border(
          props.value,
          error
        )} text-md w-full px-2 mr-1`}
        placeholder={''}
        onKeyDown={(e) => keyDown(e)}
      />
      <ErrorMsg error={error} />
    </div>
  )
}

export default TargetName
