import React from 'react'
import { Rr } from '@/api/rrs'
import Pre from '../Preview'

type Props = {
  zoneName: string
  rr: Rr
}

const Preview = ({ zoneName, rr }: Props) => {
  if (rr.type !== 'TLSA') throw Error('FIXME: this is not TLSA')

  const hostname =
    rr.hostName === '@' || rr.hostName === ''
      ? zoneName
      : `${rr.hostName}.${zoneName}`

  const records = [hostname, rr.ttl, 'IN TLSA']

  const rrs = rr.data.map(
    (tlsa) =>
      `${tlsa.certificationUsage} ${tlsa.selector} ${tlsa.matchingType} ${tlsa.certificationAssociationData}`
  )

  return <Pre hostAndType={records.join(' ')} rdata={rrs} />
}

export default Preview
