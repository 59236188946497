import React, { useEffect } from 'react'
import useTranslation from '@/i18n'
import { useProgress, useSafeState } from '@/hooks'
import TextareaAutosize from 'react-textarea-autosize'
import Preview from './Preview'
import { ValProps } from '@/types'
import { DkimData } from '@/api/rrs'
import K from './K'
import Hash from './Hash'
import ErrorMsg from '../ErrorMsg'
import deepClone from 'lodash.clonedeep'
import { border } from '@/utils'

const _defaultRdata: DkimData = {
  selector: '',
  h: 'sha256',
  k: '',
  p: '',
}

const Value = (props: ValProps) => {
  if (props.rr.type !== 'DKIM') throw Error('Fixme: this is not DKIM record')
  const { t } = useTranslation()
  const { inProgress } = useProgress()
  const [key, setKey] = useSafeState('')

  useEffect(() => {
    if (props.rr.data.length > 0 && props.rr.data[0].p != null) {
      setKey(props.rr.data[0].p)
    }
  }, [])

  useEffect(() => {
    if (props.rr.data.length === 0) {
      props.rr.data = [deepClone(_defaultRdata)]
      props.change({ ...props.rr })
      setKey(
        props.rr.data.length > 0 && props.rr.data[0].p != null
          ? props.rr.data[0].p
          : ''
      )
    }
  }, [props.rr.data])

  const keyDownNew = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      props.save()
    }
  }

  const onchange = (e) => {
    e.preventDefault()
    const value = e.target.value
      .replace(/-----BEGIN PUBLIC KEY-----/g, '')
      .replace(/-----END PUBLIC KEY-----/g, '')
      .replace(/\r?\n/g, '')
      .trim()

    if (props.rr.data.length > 0) {
      props.rr.data[0].p = value
      props.change({ ...props.rr })
      setKey(value)
    }
  }

  return (
    <div className="mt-4 ">
      <div className="flex items-center">
        <div className="mr-2">
          <K rr={props.rr} change={props.change} />
        </div>
        <Hash rr={props.rr} change={props.change} />
      </div>
      {props.rr.data.length > 0 && props.rr.data[0].h === 'sha1' && (
        <ErrorMsg error={'SHA256 is recommended'} />
      )}
      <div className="mt-4">
        <p className="text-md font-medium text-gray-900 mr-2">
          {t('Public key')}
        </p>
        <TextareaAutosize
          disabled={inProgress}
          maxLength={65000}
          maxRows={10}
          minRows={3}
          value={key}
          onChange={onchange}
          onKeyDown={keyDownNew}
          className={`text-md text-gray-900 resize-none w-full border ${border(
            key,
            ''
          )} mr-2 outline-none focus:outline-none px-1`}
        />
      </div>
      <div>
        <label className="inline-flex items-center">
          <input
            type="checkbox"
            className="form-checkbox text-pink-600 cursor-pointer"
            onChange={() => {
              if (props.rr.data.length > 0) {
                props.rr.data[0].testMode =
                  props.rr.data[0].testMode != null ? undefined : true
                props.change({ ...props.rr })
              }
            }}
            checked={
              props.rr.data.length > 0 && props.rr.data[0].testMode
                ? true
                : false
            }
          />
          <span className="ml-2 text-md">
            {t('Use this domain to test DKIM')}
          </span>
        </label>
      </div>
      <Preview rr={props.rr} zoneName={props.zoneName} />
    </div>
  )
}

export default Value
