import { axios, getAuthorization } from '@/api/axios'
import { withRefreshToken } from '@/auth'
import { errConverter } from './error'

const validateBimiLogo = (url?: string): Promise<void> =>
  new Promise<void>((resolve, reject) =>
    axios
      .get<{ message: string }>(`/dev/bimi-logo`, {
        headers: getAuthorization(),
        params: { url },
      })
      .then(() => {
        resolve()
      })
      .catch((err) => {
        reject(errConverter(err))
      })
  )

const checkBimiLogo = withRefreshToken(validateBimiLogo)

export { checkBimiLogo }
