import React from 'react'
import { Rr } from '@/api/rrs'
import Info from '../../Info'

const pattern = /^[0-9]{1,}$/

type Props = {
  rr: Rr
  change: (rr: Rr) => void
  save: () => void
}

const Ri = ({ rr, change, save }: Props) => {
  if (rr.type !== 'DMARC') throw Error('FIXME: this is not DMARC')

  const value =
    rr.data.length === 0 || rr.data[0].ri == null ? '' : rr.data[0].ri

  const set = (res: number | undefined) => {
    const { ri, ...rest } = rr.data[0]
    rr.data = [
      {
        ri: res,
        ...rest,
      },
    ]
    change({ ...rr })
  }

  const onchange = (value: string) => {
    if (pattern.test(value)) {
      const res = Number(value)
      if (res >= 0 && res <= 4294967295) {
        set(res)
      }
    } else if (value === '') {
      set(undefined)
    }
  }

  const keyDown = (e) => {
    if (e.keyCode === 13) {
      save()
    }
  }

  return (
    <div className="mt-4">
      <div className="flex items-center">
        <p className="text-md font-medium text-gray-900 mr-2">ri(seconds)</p>
        <Info info={'The interval for report'} />
      </div>

      <input
        value={value}
        onChange={(e) => onchange(e.target.value)}
        className="border-b focus:outline-none border-gray-500 text-md w-full px-2 mr-1"
        placeholder={'300'}
        onKeyDown={(e) => keyDown(e)}
      />
    </div>
  )
}

export default Ri
