import React from 'react'
import useTranslation from '@/i18n'
import { remove } from '@/api/rrs'
import { useErr, useModal, useProgress } from '@/hooks'

type Props = {
  zoneId: number
  zoneName: string
  hostName: string
  type: string
  reload: () => Promise<void>
}

function _getName(type: string, hostName: string, zoneName: string) {
  const domain = hostName === '@' ? zoneName : `${hostName}.${zoneName}`

  return { host: hostName, domain }
}

export const Delete = ({ zoneId, zoneName, hostName, type, reload }: Props) => {
  const { start, stop, inProgress } = useProgress()
  const { closeModal } = useModal()
  const { setErr } = useErr()
  const { t } = useTranslation()

  const { host, domain } = _getName(type, hostName, zoneName)

  const del = () => {
    start(0)
    closeModal()
    remove({ zoneId, hostName: host, type })
      .then(() => {
        reload()
      })
      .catch((err) => {
        setErr(err.message, err.status)
        stop()
      })
  }

  return (
    <div>
      <div className="px-4">
        <h1 className="text-xl text-red-600 font-bold mb-2 max-w-3xl break-words">
          {t('Deleting {{name}}', { name: domain })}
        </h1>

        <p className="text-gray-800 mb-4">{t('ConfirmDeleting')}</p>
      </div>
      <div className="bg-gray-100 px-2 py-2 text-right">
        <button
          className={`${
            inProgress ? 'bg-red-300' : 'bg-red-600'
          } text-white px-8 rounded-sm focus:outline-none`}
          onClick={del}
          autoFocus={true}
        >
          {t('Yes')}
        </button>
      </div>
    </div>
  )
}
