import React from 'react'
import { Rr } from '@/api/rrs'
import { factory } from '@/components/Rrs'
import { IoTrash } from 'react-icons/io5'

type Props = {
  index: number
  zoneName: string
  rr: Rr
  inProgress: boolean
  update: (id: number) => void
  del: (name: string, type: string) => void
}

const RrsData = ({ index, zoneName, inProgress, rr, update, del }: Props) => {
  const handler = factory(rr.type)
  const { fqdn, hostName, rrType, Val } = handler.parse(zoneName, rr)

  return (
    <tr key={index}>
      <td className="px-6 py-4 ">
        <p
          className={`text-sm text-gray-900  font-medium w-82 break-words ${
            rr.mutable ? 'hover:text-blue-500 cursor-pointer' : ''
          }`}
          onClick={() => {
            rr.mutable && update(index)
          }}
        >
          {fqdn}
        </p>
      </td>
      <td className="px-6 py-4 break-words text-sm text-gray-900 whitespace-nowrap">
        {rrType}
      </td>
      <td
        className="px-6 py-4 break-words text-sm text-gray-900 whitespace-nowrap truncate"
        style={{ width: '520px' }}
      >
        {rr.data.map((value, index) => (
          <Val value={value} key={index} />
        ))}
      </td>
      <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
        {rr.ttl}
      </td>
      <td className="py-4 whitespace-nowrap">
        {rr.mutable && (
          <IoTrash
            className={`text-xl ${
              inProgress ? 'text-gray-300' : 'text-gray-600 cursor-pointer '
            } mx-auto`}
            onClick={() => {
              del(hostName, rr.type)
            }}
          />
        )}
      </td>
    </tr>
  )
}

export default RrsData
