import React from 'react'
import { Rr } from '@/api/rrs'
import Pre from '../Preview'

type Props = {
  zoneName: string
  rr: Rr
}

const Preview = ({ zoneName, rr }: Props) => {
  if (rr.type !== 'SPF') throw Error('FIXME: this is not SPF')

  const hostname =
    rr.hostName === '@' || rr.hostName === ''
      ? zoneName
      : `${rr.hostName}.${zoneName}`

  const records = [hostname, rr.ttl, 'IN TXT']

  const rrs = rr.data.map((spf) => {
    if (spf.length === 2) {
      if (spf[0] === 'redirect') {
        return spf.join('=')
      } else if (['-', '+'].includes(spf[0])) {
        return spf.join('')
      } else {
        return spf.join(':')
      }
    } else {
      if (spf[2] === '') return ''
      return `${spf[0]}${spf[1]}:${spf[2]}`
    }
  })

  const rdata = ['"v=spf', ...rrs]

  return <Pre hostAndType={records.join(' ')} rdata={[`${rdata.join(' ')}"`]} />
}

export default Preview
