import React, { useEffect, useState } from 'react'
import { Rr, Rtype } from '@/api/rrs'
import Selector from '../Selector'
import RecordForType from './RecordForType'
import deepClone from 'lodash.clonedeep'
import { rrTypes } from '../rrTyps'
import { factory } from '../factory'

type Props = {
  zoneName: string
  zoneId: number
  rr: Rr
  reload: () => Promise<void>
  checkExists: (rr: Rr) => boolean
}

const RrsEditor = ({ zoneName, zoneId, rr, checkExists, reload }: Props) => {
  const [newRr, setNewRr] = useState<Rr>(deepClone(rr))
  const disabled = rr.id != null
  const typesWithOther = [...rrTypes, 'OTHER']

  useEffect(() => {
    const cloned: Rr = deepClone(rr)
    if (!rrTypes.includes(cloned.type)) {
      cloned.type = 'OTHER'
    }
    setNewRr(cloned)
  }, [])

  return (
    <RecordForType
      type={newRr.type}
      zoneName={zoneName}
      zoneId={zoneId}
      rr={newRr}
      setRr={setNewRr}
      check={checkExists}
      reload={reload}
    >
      <div className="mt-1">
        <Selector
          title={'Record Type'}
          current={newRr.type}
          vals={typesWithOther}
          disabled={disabled}
          select={(index) => {
            if (!disabled) {
              if (typesWithOther[index] !== newRr.type) {
                const handler = factory(newRr.type)
                const { hostName } = handler.parseHostName(newRr.hostName)
                newRr.type = typesWithOther[index] as Rtype
                newRr.ttl = 300
                newRr.data = []
                newRr.hostName = hostName === '' ? '@' : hostName
                setNewRr({ ...newRr })
              }
            }
          }}
        />
      </div>
    </RecordForType>
  )
}

export { RrsEditor }
