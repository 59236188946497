import React from 'react'
import { Rr } from '@/api/rrs'
import Pre from '../Preview'

interface Props {
  zoneName: string
  rr: Rr
}

function Preview({ rr, zoneName }: Props) {
  if (rr.type !== 'BIMI') throw Error('FIXME: this is not BIMI')

  const hostname =
    rr.hostName === '@'
      ? `default._bimi.${zoneName}`
      : `${rr.hostName}.${zoneName}`

  const records = [hostname, rr.ttl, 'IN TXT']

  const temp = ['"v=BIMI1;']

  if (rr.data.length > 0) {
    const rdata = rr.data[0]
    temp.push(`l=${rdata.l}`)
    if (rdata.l.length === 0 || (rdata.a != null && rdata.a.length > 0)) {
      temp.push(`; a=${rdata.a == null ? '' : rdata.a}`)
    }
  }

  return <Pre hostAndType={records.join(' ')} rdata={[`${temp.join(' ')}"`]} />
}

export default Preview
