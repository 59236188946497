import React from 'react'
import useTranslation from '@/i18n'

const Header = () => {
  const { t } = useTranslation()
  return (
    <tr>
      <th
        scope="col"
        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
        style={{ width: '300px' }}
      >
        {t('Domain')}
      </th>
      <th
        scope="col"
        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
        style={{ width: '128px' }}
      >
        {t('Type')}
      </th>
      <th
        scope="col"
        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
        style={{ width: '520px' }}
      >
        {t('Value')}
      </th>
      <th
        scope="col"
        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider "
        style={{ width: '120px' }}
      >
        {t('TTL')}
      </th>
      <th scope="col" className="relative px-6 py-3 w-20 md:w-10">
        <span className="sr-only">Delete</span>
      </th>
    </tr>
  )
}

export default Header
