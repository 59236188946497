import React from 'react'
import Selector from '@/components/Rrs/Selector'
import { useProgress } from '@/hooks'
import { Rr } from '@/api/rrs'

type Props = {
  rr: Rr
  change: (rr: Rr) => void
}

const Aspf = ({ rr, change }: Props) => {
  if (rr.type !== 'DMARC') throw Error('FIXME: this is not DMARC')
  const { inProgress } = useProgress()

  const _aspf = {
    ['r']: 'Relaxed',
    ['s']: 'Strict',
  }

  const current =
    rr.data.length === 0 || rr.data[0].aspf == null ? 'r' : rr.data[0].aspf

  const select = (index: number) => {
    const { aspf, ...rest } = rr.data[0]
    rr.data = [
      {
        aspf: index === 0 ? undefined : Object.keys(_aspf)[index],
        ...rest,
      },
    ]
    change({ ...rr })
  }

  return (
    <div className="mt-4">
      <Selector
        disabled={inProgress}
        title={'aspf'}
        info={'SPF alignment mode'}
        vals={Object.values(_aspf)}
        current={_aspf[current]}
        select={select}
      />
    </div>
  )
}

export default Aspf
