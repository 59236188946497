import React from 'react'
import { OtherRdata } from '@/api/rrs'
import useTranslation from '@/i18n'
import { useProgress } from '@/hooks'
import TextareaAutosize from 'react-textarea-autosize'
import { rrTypes } from '../rrTyps'
import { ValProps } from '@/types'

const Value = (props: ValProps) => {
  if (rrTypes.includes(props.rr.type))
    throw Error('Fixme: this is not OTHER record')
  const { t } = useTranslation()
  const { inProgress } = useProgress()

  const getOther = () => {
    if (props.rr.data.length > 0) {
      return props.rr.data[0] as OtherRdata
    } else {
      return { type: '', value: '' } as OtherRdata
    }
  }

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      if (props.rr.data.length > 0) {
        const data = props.rr.data[0] as OtherRdata
        if (data.type.length > 0 && data.value.length > 0) {
          props.save()
        }
      }
    }
  }

  const onTypeChange = (e) => {
    e.preventDefault()
    const value = e.target.value.trim()
    if (props.rr.data.length > 0) {
      const data = props.rr.data[0] as OtherRdata
      data.type = value
    } else {
      props.rr.data = [{ type: value, value: '' }]
    }
    props.change({ ...props.rr })
  }

  const onValueChange = (e) => {
    e.preventDefault()
    const value = e.target.value

    if (props.rr.data.length > 0) {
      const data = props.rr.data[0] as OtherRdata
      data.value = value
    } else {
      props.rr.data = [{ type: value, value: '' }]
    }
    props.change({ ...props.rr })
  }

  return (
    <div className="mt-4">
      <p className="text-md font-medium text-gray-900">{t('Type')}</p>
      <input
        disabled={inProgress || props.rr.id != null}
        value={getOther().type}
        maxLength={200}
        onChange={(e) => onTypeChange(e)}
        className="border-b focus:outline-none border-gray-500 text-md w-full px-1"
        onKeyDown={onKeyDown}
      />
      <p className="text-md font-medium text-gray-900 mt-4">{t('Value')}</p>

      <TextareaAutosize
        disabled={inProgress}
        maxLength={200}
        maxRows={5}
        value={getOther().value}
        onChange={onValueChange}
        onKeyDown={onKeyDown}
        className="text-md text-gray-900 resize-none w-full border-b border-gray-500 outline-none focus:outline-none px-1"
      />
    </div>
  )
}

export default Value
