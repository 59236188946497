import { useProgress } from '@/hooks'
import React from 'react'
import { IoTrash } from 'react-icons/io5'
import { border } from '@/utils'

type Props = {
  exchange: string
  total: number
  error: string
  change: (exchange: string) => void
  keyDown: () => void
  del: () => void
}

const Exchange = ({ exchange, change, keyDown, total, del, error }: Props) => {
  const onChange = (e) => {
    e.preventDefault()
    const value = e.target.value.trim()
    change(value)
  }

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      keyDown()
    }
  }

  const { inProgress } = useProgress()

  return (
    <div className="col-span-10">
      <div className="flex items-center">
        <input
          disabled={inProgress}
          value={exchange}
          maxLength={253}
          onChange={(e) => onChange(e)}
          className={`w-full border-b focus:outline-none ${border(
            exchange,
            error
          )} text-md  px-1 `}
          onKeyDown={onKeyDown}
        />
        {total > 1 && (
          <IoTrash
            className="text-gray-700 cursor-pointer ml-1"
            onClick={() => del()}
          />
        )}
      </div>
    </div>
  )
}

export default Exchange
