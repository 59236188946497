import React from 'react'
import useTranslation from '@/i18n'
import { IoIosCheckmarkCircle } from 'react-icons/io'
import { useProgress } from '@/hooks'

type Props = {
  step: number
  done: boolean
  enable: boolean
  title: string
  explain?: string
  check: () => void
  btnTitle: string
}

const Step = ({
  step,
  done,
  enable,
  title,
  check,
  btnTitle,
  explain,
}: Props) => {
  const { t } = useTranslation()
  const { inProgress } = useProgress()

  return (
    <div className="mb-4">
      <div className="flex items-center">
        {done && (
          <IoIosCheckmarkCircle className="text-green-500 mr-2 text-lg" />
        )}

        <h1
          className={`${
            enable
              ? 'text-gray-900 font-medium'
              : 'text-gray-500 cursor-default'
          } text-white text-xs sm:text-base mb-1`}
        >
          <span className="mr-1">Step{step}</span>
          {t(title)}
        </h1>
      </div>
      {enable && explain != null && (
        <p className="mt-1 mb-4 text-xs text-gray-800">{t(explain)}</p>
      )}
      {enable && inProgress && (
        <p className="text-gray-500 text-sm font-medium mb-4">
          {t('NowChecking')}
        </p>
      )}
      {enable && (
        <button
          disabled={!enable}
          className={`${
            enable && !inProgress ? 'bg-blue-500' : 'bg-gray-600 cursor-default'
          } text-white rounded-sm px-4 focus:outline-none`}
          onClick={check}
        >
          {t(btnTitle)}
        </button>
      )}
    </div>
  )
}

export default Step
