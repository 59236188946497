import React, { useEffect, useState } from 'react'
import { Rr } from '@/api/rrs'
import ErrorMsg from '../ErrorMsg'
import { useProgress, useSafeState } from '@/hooks'
import { validateHostName, validateDomainNameExpAster } from '../validator'
import useTranslation from '@/i18n'
import { border } from '@/utils'

export type HostNameProps = {
  zoneName: string
  rr: Rr
  change: (rr: Rr) => void
  save: () => void
}

export const HostName = ({ rr, change, zoneName, save }: HostNameProps) => {
  if (rr.type !== 'DKIM') throw new Error('FIXME: this is not DKIM')
  const [hostName, setHostName] = useSafeState(
    rr.hostName === '@' ? '' : rr.hostName
  )
  const [selector, setSelector] = useSafeState('')
  const [err, setErr] = useSafeState('')
  const [selectorErr, setSelectorErr] = useSafeState('')
  const { inProgress } = useProgress()
  const { t } = useTranslation()

  useEffect(() => {
    if (rr.data.length > 0 && rr.data[0].selector != null) {
      setSelector(rr.data[0].selector)
    }
  }, [])

  const onChangeHostName = (value: string) => {
    const res = value.trim()
    if (!validateDomainNameExpAster(res)) {
      setErr('Invalid hostname')
    } else {
      setErr(selectorErr)
    }

    rr.hostName = res
    setHostName(res)
    change({ ...rr })
  }

  const onChangeSelector = (value: string) => {
    const res = value.trim()

    if (res == '' || !validateHostName(res)) {
      setErr('Invalid selector')
      setSelectorErr('Invalid selector')
    } else {
      setErr('')
      setSelectorErr('')
    }

    if (rr.data.length > 0) {
      rr.data[0].selector = res
      setSelector(res)
      change({ ...rr })
    }
  }

  const keyDown = (e) => {
    if (e.keyCode === 13) {
      save()
    }
  }

  return (
    <>
      <p className="text-md font-medium text-gray-900">{t('Host name')}</p>

      <div className="sm:flex sm:items-center">
        <input
          disabled={inProgress}
          placeholder="selector"
          maxLength={179}
          value={selector}
          onChange={(e) => onChangeSelector(e.target.value)}
          onKeyDown={keyDown}
          className={`border-b ${border(
            selector,
            selectorErr
          )} px-1 text-md focus:outline-none w-48 text-right`}
          autoFocus={true}
        />

        <p className="text-md">._domainkey.</p>
        <input
          disabled={inProgress}
          placeholder="host name"
          maxLength={179}
          value={hostName}
          onChange={(e) => onChangeHostName(e.target.value)}
          onKeyDown={keyDown}
          className="border-b border-gray-500 px-1 text-md focus:outline-none w-48 text-right"
          autoFocus={false}
        />

        <p className="text-md">{`.${zoneName}`}</p>
      </div>
      <ErrorMsg error={err} />
    </>
  )
}
