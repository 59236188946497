import React, { useState } from 'react'
import { Rr } from '@/api/rrs'
import ErrorMsg from './ErrorMsg'
import { useProgress } from '@/hooks'
import { validateHostName } from './validator'
import useTranslation from '@/i18n'

export function parse(zoneName: string, rr: Rr) {
  const fqdn =
    rr.hostName === '' || rr.hostName === '@'
      ? `${zoneName}.`
      : `${rr.hostName}.${zoneName}.`

  const Val = ({ value }) => <p className="truncate">{value}</p>

  const hostName = rr.hostName === '' ? '@' : rr.hostName

  return { fqdn, hostName, rrType: rr.type, Val }
}

export type HostNameProps = {
  zoneName: string
  rr: Rr
  change: (rr: Rr) => void
  save: () => void
}

export const HostName = ({ rr, change, zoneName, save }: HostNameProps) => {
  const [err, setErr] = useState('')
  const { inProgress } = useProgress()
  const { t } = useTranslation()

  const onchange = (value: string) => {
    const res = value.trim()
    if (res.length > 0 && !validateHostName(res)) {
      setErr('Invalid hostname')
    } else {
      setErr('')
    }

    rr.hostName = res
    change({ ...rr })
  }

  const keyDown = (e) => {
    if (e.keyCode === 13) {
      save()
    }
  }

  return (
    <>
      <p className="text-md font-medium text-gray-900">{t('Host name')}</p>
      <div className="flex items-center">
        <input
          disabled={inProgress}
          placeholder="sample"
          maxLength={200}
          value={rr.hostName === '@' ? '' : rr.hostName}
          onChange={(e) => onchange(e.target.value)}
          onKeyDown={keyDown}
          className="border-b border-gray-500 px-1 text-md focus:outline-none w-48 text-right"
          autoFocus={true}
        />
        <p className="text-md">{`.${zoneName}`}</p>
      </div>
      <ErrorMsg error={err} />
    </>
  )
}

export const convertOldHostName = (rr: Rr) => {
  if (rr.id == null || rr.hostName == null) return undefined
  return rr.hostName
}

export const convertRr = (rr: Rr, zoneName: string) => {
  rr.hostName = rr.hostName === '' ? '@' : rr.hostName
  return rr
}

export function parseHostName(hostName: string) {
  return { hostName }
}
