import React from 'react'
import { ipV6 } from '../validator'
import { ValProps } from '@/types'
import FlexList from '@/components/Rrs/FlexList'

const Value = (props: ValProps) => {
  if (props.rr.type !== 'AAAA') throw Error('Fixme: this is not AAAA record')

  const changeIpList = (ipList: string[]) => {
    props.rr.data = ipList
    props.change({ ...props.rr })
  }

  return (
    <div className="mt-4">
      <div className="w-full">
        <FlexList
          title={'IP address(v6)'}
          vals={props.rr.data.length === 0 ? [''] : props.rr.data}
          validate={ipV6}
          change={changeIpList}
          errorMsg={'Invalid IP address'}
        />
      </div>
    </div>
  )
}

export default Value
