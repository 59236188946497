import React from 'react'
import Selector from '@/components/Rrs/Selector'
import { useProgress } from '@/hooks'
import { Rr, KValue } from '@/api/rrs'

type Props = {
  rr: Rr
  change: (rr: Rr) => void
}

const _k = ['rsa', 'ed25519']

const K = ({ rr, change }: Props) => {
  if (rr.type !== 'DKIM') throw Error('FIXME: this is not DKIM')
  const { inProgress } = useProgress()

  const current =
    rr.data.length === 0 || rr.data[0].k == null || rr.data[0].k === ''
      ? 'rsa'
      : rr.data[0].k

  const select = (index: number) => {
    const { k, ...rest } = rr.data[0]
    rr.data = [
      {
        k: index === 0 ? '' : (_k[index] as KValue),
        ...rest,
      },
    ]
    change({ ...rr })
  }

  return (
    <div className="mt-4">
      <Selector
        disabled={inProgress}
        title={'Key type'}
        vals={_k}
        current={current}
        select={select}
      />
    </div>
  )
}

export default K
