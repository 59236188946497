import React from 'react'
import CheckBox from '@/components/CheckBox'
import useTranslation from '@/i18n'

type Props = {
  mandatories: string[]
  update: (mandatories: string[]) => void
}

function Mandatories(props: Props) {
  const { t } = useTranslation()

  const toggle = (key: string) => {
    const index = props.mandatories.findIndex((m) => m === key)
    if (index < 0) {
      props.mandatories.push(key)
      props.update([...props.mandatories])
    } else {
      props.update(props.mandatories.filter((m) => m !== key))
    }
  }

  return (
    <>
      <p className="text-md font-medium text-gray-900 mt-4">{t('Mandatory')}</p>
      <div className="grid grid-cols-1 sm:grid-cols-4 gap-1">
        <CheckBox
          label="ALPN"
          color="text-blue-600"
          toggle={() => toggle('alpn')}
          checked={props.mandatories.includes('alpn')}
        />
        <CheckBox
          label="Ech"
          color="text-blue-600"
          toggle={() => toggle('ech')}
          checked={props.mandatories.includes('ech')}
        />
        <CheckBox
          label="IPv4 hint"
          color="text-blue-600"
          toggle={() => toggle('ipv4hint')}
          checked={props.mandatories.includes('ipv4hint')}
        />
        <CheckBox
          label="IPv6 hint"
          color="text-blue-600"
          toggle={() => toggle('ipv6hint')}
          checked={props.mandatories.includes('ipv6hint')}
        />
      </div>
    </>
  )
}

export default Mandatories
