import React, { useEffect } from 'react'
import { get } from '@/api/zoneTransfer'
import { Rr } from '@/api/rrs'
import { useProgress } from '@/hooks'
import { useSafeState } from '@/hooks'
import AutoTransfer from './AutoTranser'
import ManualTransfer from './ManualTransfer'

type Props = {
  zoneId: number
  zoneName: string
  rrs: Rr[]
}

const ZoneTransfer = ({ zoneId, zoneName, rrs }: Props) => {
  const [step, setStep] = useSafeState<number>(4)
  const [mode, setMode] = useSafeState<number | undefined>(undefined)
  const { start, stop } = useProgress()

  useEffect(() => {
    start()
    get(zoneId).then(({ step, mode }) => {
      setStep(step + 1)
      setMode(mode)
      stop()
    })

    return () => {
      stop()
    }
  }, [])

  if (step !== 4) {
    if (mode === 0) {
      return (
        <AutoTransfer
          rrs={rrs}
          zoneId={zoneId}
          zoneName={zoneName}
          step={step}
          setStep={setStep}
        />
      )
    }

    if (mode === 1) {
      return (
        <ManualTransfer
          rrs={rrs}
          zoneId={zoneId}
          zoneName={zoneName}
          step={step}
          setStep={setStep}
        />
      )
    }
  }

  return <></>
}

export default ZoneTransfer
