import React from 'react'
import { Rr, DkimData } from '@/api/rrs'
import { HostName } from './Hostname'
import Value from './Value'
import { validateTtl, validateDomainName } from '../validator'
import { parseHostName } from '../Common'

export function parse(zoneName: string, rr: Rr) {
  if (rr.type !== 'DKIM') {
    throw Error(`Fixme!! this is not DKIM: ${JSON.stringify(rr)}`)
  }

  const domain =
    rr.hostName === '' || rr.hostName === '@'
      ? `${zoneName}.`
      : `${rr.hostName}.${zoneName}.`

  const selector =
    rr.data == null || rr.data.length === 0 ? '' : rr.data[0].selector

  const fqdn = `${selector}._domainkey.${domain}`

  const Val = ({ value }: { value: DkimData }) => {
    const { h, k, p } = value

    return (
      <>
        <p className="truncate">{`h(hash): ${h}`}</p>
        <p className="truncate">{`k(algorithm): ${k === '' ? 'rsa' : k}`}</p>
        <p className="truncate">{`p(public key): ${p}`}</p>
      </>
    )
  }

  const hostName =
    rr.hostName === '' || rr.hostName === '@'
      ? `${selector}._domainkey`
      : `${selector}._domainkey.${rr.hostName}`

  return { fqdn, hostName, rrType: `TXT(${rr.type})`, Val }
}

export function create() {
  function isReady(rr: Rr) {
    if (rr.type !== 'DKIM') throw Error('Fixme: this is not DKIM record')

    if (rr.hostName !== '@' && !validateDomainName(rr.hostName)) return false

    if (!validateTtl(rr.ttl)) return false

    if (rr.data.length === 0) return false

    if (
      rr.data[0].selector == null ||
      rr.data[0].selector === '' ||
      !validateDomainName(rr.data[0].selector)
    )
      return false

    if (rr.data[0].p == null || rr.data[0].p.length === 0) return false

    return true
  }

  return {
    HostName,
    Val: Value,
    isReady,
    convertOldHostName: (rr: Rr) => {
      if (rr.type !== 'DKIM') throw new Error('FIXME: this is not DKIM')

      if (rr.id == null || rr.hostName == null || rr.data.length === 0)
        return undefined

      const selector = rr.data[0].selector

      return rr.hostName === '@' || rr.hostName === ''
        ? `${selector}._domainkey`
        : `${selector}._domainkey.${rr.hostName}`
    },

    convertRr: (rr: Rr, zoneName: string) => {
      if (rr.type !== 'DKIM') throw new Error('FIXME: this is not DKIM')

      rr.hostName = rr.hostName === '@' ? '' : rr.hostName

      return rr
    },
  }
}

export { parseHostName }
