import { useProgress } from '@/hooks'
import React, { useEffect, useState } from 'react'
import Selector from '../Selector'
import ErrorMsg from '../ErrorMsg'
import { validateFqdn } from '../validator'
import useTranslation from '@/i18n'

type Props = {
  vals: string[]
  change: (vals: string[]) => void
  save: () => void
}

const _terminators = ['-all', 'redirect']

const Terminator = ({ vals, change, save }: Props) => {
  const [redirect, setRedirect] = useState(false)
  const { inProgress } = useProgress()
  const [error, setError] = useState('')
  const { t } = useTranslation()

  useEffect(() => {
    if (vals.length > 0 && vals[0] === 'redirect') {
      setRedirect(true)
    }
  }, [])

  const onSelect = (id: number) => {
    if (id === 0) {
      vals = ['-', 'all']
      setRedirect(false)
    } else {
      vals = ['redirect', '']
      setRedirect(true)
    }
    change([...vals])
  }

  const changeValue = (value: string) => {
    if (!validateFqdn(value)) {
      setError('Invalid value')
    } else {
      setError('')
    }

    vals[1] = value
    change([...vals])
  }

  const getCurrent = () => {
    if (vals.length === 0) {
      return '-all'
    }

    if (vals[0] === '-') {
      return '-all'
    }

    return 'redirect'
  }

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      if (vals[0] === 'redirect' && vals[1] !== '') {
        save()
      }
    }
  }

  return (
    <>
      <div className="flex items-center">
        <Selector
          current={getCurrent()}
          vals={_terminators}
          disabled={inProgress}
          select={onSelect}
        />
        {redirect && (
          <div className="relative">
            <input
              disabled={inProgress}
              maxLength={256}
              placeholder={t('Redirect to')}
              className="focus:outline-none border-b px-2 text-sm py-0 border-gray-500 w-full sm:w-80 ml-2"
              value={vals.length === 2 ? vals[1] : vals[2]}
              onChange={(e) => changeValue(e.target.value)}
              onKeyDown={onKeyDown}
            />
          </div>
        )}
      </div>
      <ErrorMsg error={error} />
    </>
  )
}

export default Terminator
