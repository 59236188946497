import React from 'react'
import { get } from '@/api/ns-check'
import Step from '../Step'
import { useErr, useProgress, useModal } from '@/hooks'
import { post } from '@/api/auto-start-zone'
import { post as postZoneTransfer } from '@/api/zoneTransfer'
import Complete from './Complete'

type Props = {
  domainName: string
  zoneId: number
  step: number
  setStep: (step: number) => void
}

const Step3 = ({ domainName, zoneId, step, setStep }: Props) => {
  const { inProgress, start, stop } = useProgress()
  const { setErr } = useErr()
  const { openModal } = useModal()

  const click = () => {
    start()
    get({ domainName })
      .then(() => {
        post({ zoneId, zoneName: domainName })
          .then(({ nsTtl }) => {
            postZoneTransfer({ zoneId, status: step })
              .then(() => {
                setStep(step + 1)
                stop()
                openModal(<Complete nsTtl={nsTtl} />)
              })
              .catch((err) => {
                setErr(err.message, err.status)
                stop()
              })
          })
          .catch((err) => {
            setErr(err.message, err.status)
            stop()
          })
      })
      .catch((err) => {
        setErr(err.message, err.status)
        stop()
      })
  }

  return (
    <>
      <Step
        step={3}
        done={step > 3}
        enable={step === 3}
        title="Set the NS records of the parent DNS server to QUALITIA DNS"
        explain="This action will change NS records and add DS record in the name servers of the parent"
        check={click}
        btnTitle="Start"
      />
    </>
  )
}

export default Step3
