import React from 'react'
import { Rr } from '@/api/rrs'
import ErrorMsg from '../ErrorMsg'
import { useProgress, useSafeState } from '@/hooks'
import { validateHostName, validateDomainNameExpAster } from '../validator'
import useTranslation from '@/i18n'
import { border } from '@/utils'

export type HostNameProps = {
  zoneName: string
  rr: Rr
  change: (rr: Rr) => void
  save: () => void
}

const _getHostName = (rr: Rr) => {
  if (rr.hostName === '@') return ''

  const res = rr.hostName.split('._bimi')[1]
  return res == null ? '' : res.slice(1)
}

const _getSelector = (rr: Rr) => {
  if (rr.hostName === '@') return 'default'

  return rr.hostName.split('._bimi')[0]
}

export const HostName = ({ rr, change, zoneName, save }: HostNameProps) => {
  if (rr.type !== 'BIMI') throw new Error('FIXME: this is not BIMI')

  const [hostName, setHostName] = useSafeState(_getHostName(rr))
  const [selector, setSelector] = useSafeState(_getSelector(rr))
  const [selectorErr, setSelectorErr] = useSafeState('')
  const [error, setError] = useSafeState('')
  const { inProgress } = useProgress()

  const { t } = useTranslation()

  const onChangeHostName = (value: string) => {
    const res = value.trim()

    if (!validateDomainNameExpAster(res)) {
      setError('Invalid hostname')
    } else {
      setError(selectorErr)
    }

    setHostName(res)

    rr.hostName =
      res.length === 0 ? `${selector}._bimi` : `${selector}._bimi.${res}`

    change({ ...rr })
  }

  const onChangeSelector = (value: string) => {
    const res = value.trim()
    if (res == '' || !validateHostName(res)) {
      setError('Invalid selector')
      setSelectorErr('Invalid selector')
    } else {
      setError('')
      setSelectorErr('')
    }

    setSelector(res)

    rr.hostName =
      hostName.length === 0 ? `${res}._bimi` : `${res}._bimi.${hostName}`

    change({ ...rr })
  }

  const keyDown = (e) => {
    if (e.keyCode === 13) {
      save()
    }
  }

  return (
    <>
      <p className="text-md font-medium text-gray-900">{t('Host name')}</p>

      <div className="sm:flex sm:items-center">
        <input
          disabled={inProgress}
          placeholder="default"
          maxLength={179}
          value={selector}
          onChange={(e) => onChangeSelector(e.target.value)}
          onKeyDown={keyDown}
          className={`border-b ${border(
            selector,
            selectorErr
          )} px-1 text-md focus:outline-none w-48 text-right`}
          autoFocus={true}
        />

        <p className="text-md">._bimi.</p>
        <input
          disabled={inProgress}
          placeholder="host name"
          maxLength={179}
          value={hostName}
          onChange={(e) => onChangeHostName(e.target.value)}
          onKeyDown={keyDown}
          className="border-b border-gray-500 px-1 text-md focus:outline-none w-48 text-right"
          autoFocus={false}
        />

        <p className="text-md">{`.${zoneName}`}</p>
      </div>
      <ErrorMsg error={error} />
    </>
  )
}
