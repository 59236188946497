import React, { useEffect, useState } from 'react'
import { Rr } from '@/api/rrs'
import ErrorMsg from '../ErrorMsg'
import { useProgress } from '@/hooks'
import useTranslation from '@/i18n'
import { validateDomainNameExpAster } from '../validator'
import { border } from '@/utils'

export type HostNameProps = {
  zoneName: string
  rr: Rr
  change: (rr: Rr) => void
  save: () => void
  setDisable: (disabled: boolean) => void
}

const HostName = ({
  rr,
  change,
  zoneName,
  save,
  setDisable,
}: HostNameProps) => {
  const [hostName, setHostName] = useState(
    rr.hostName === '@' ? '' : rr.hostName
  )
  const [err, setErr] = useState('')
  const { inProgress } = useProgress()
  const { t } = useTranslation()

  useEffect(() => {
    if (rr.hostName === '@' || rr.hostName === '') {
      setDisable(true)
    } else {
      setDisable(false)
    }
  }, [])

  const onchange = (value: string) => {
    const res = value.trim()

    if (value === '') {
      setErr('Enter a hostname')
      setDisable(true)
    } else if (!validateDomainNameExpAster(res)) {
      setErr('Invalid hostname')
      setDisable(true)
    } else {
      setErr('')
      setDisable(false)
    }

    rr.hostName = res
    setHostName(res)
    change({ ...rr })
  }

  const keyDown = (e) => {
    if (e.keyCode === 13) {
      if (rr.hostName !== '@' && rr.hostName !== '') {
        save()
      }
    }
  }

  return (
    <>
      <p className={`text-md font-medium  text-gray-900`}>{t('Host name')}</p>
      <div className="flex items-center">
        <input
          disabled={inProgress}
          placeholder="sample"
          maxLength={200}
          value={hostName}
          onChange={(e) => onchange(e.target.value)}
          onKeyDown={keyDown}
          className={`border-b ${border(
            hostName,
            err
          )} px-1 text-md focus:outline-none w-48 text-right`}
          autoFocus={true}
        />
        <p className="text-md mr-2">{`.${zoneName}`}</p>
      </div>
      <ErrorMsg error={err} />
    </>
  )
}

export default HostName
