import React, { useEffect, useState } from 'react'
import useTranslation from '@/i18n'
import { useProgress, useSafeState } from '@/hooks'
import InputSpf, { converter } from './Input'
import { IoTrash } from 'react-icons/io5'
import Terminator from './Terminator'
import ListFrame from '../FlexListFrame'
import { ValProps, SpfVal } from '@/types'
import { validateSpf } from '../validator'
import Preview from './Preview'
import deepClone from 'lodash.clonedeep'
import BtnAdd from '@/components/Parts/BtnAdd'

const _defaultSPF = ['+', 'ip4', '']

const Value = (props: ValProps) => {
  if (props.rr.type !== 'SPF') throw Error('Fixme: this is not SPF record')
  const [disAdd, setDisAdd] = useSafeState(true)
  const { t } = useTranslation()
  const { inProgress } = useProgress()
  const [error, setError] = useSafeState('')

  useEffect(() => {
    if (props.rr.data.length === 0) {
      props.rr.data.push([..._defaultSPF])
      props.rr.data.push(['-', 'all'])
      props.change({ ...props.rr })
    } else {
      setDisAdd(isDisAdd())
    }
  }, [props.rr.data])

  const isDisAdd = () => {
    const isOver = props.rr.data.length === 32

    if (isOver) {
      setError('Reached the limit')
      return true
    } else {
      setError('')
    }

    if (props.rr.data.length === 1) {
      return false
    }
    const noEmpty = props.rr.data
      .slice(0, -1)
      .filter((spf) => spf.slice(-1).length > 0)

    if (noEmpty.length === 0) {
      return true
    }

    return noEmpty.some((spf) => !validateSpf(converter(spf)))
  }

  const changeSpf = (id: number, spf: SpfVal) => {
    props.rr.data[id] = [spf.qualifier, spf.mechanism, spf.value]
    props.change({ ...props.rr })
    setDisAdd(isDisAdd())
  }

  const delSpf = (id: number) => {
    const res = props.rr.data.filter((_, index) => id != index)
    props.rr.data = res
    props.change({ ...props.rr })
    setDisAdd(isDisAdd())
  }

  const add = () => {
    if (!disAdd) {
      const last = props.rr.data.pop()
      props.rr.data.push(deepClone(_defaultSPF))
      props.rr.data.push(last)
      props.change({ ...props.rr })
      setDisAdd(true)
    }
  }

  const getLast = () => {
    if (props.rr.data.length === 0) {
      return ['-', 'all']
    }

    return props.rr.data.slice(-1)[0]
  }

  const changeLast = (vals: string[]) => {
    props.rr.data[props.rr.data.length - 1] = vals
    props.change({ ...props.rr })
  }

  const Title = () => (
    <div className="w-full grid grid-cols-12 gap-1">
      <p className="text-md font-medium text-gray-900 truncate col-span-2">
        {t('Qualifier')}
      </p>
      <p className="text-md font-medium text-gray-900 truncate col-span-2">
        {t('Mechanism')}
      </p>
      <p className="text-md font-medium text-gray-900">{t('Value')}</p>
    </div>
  )

  const List = (data: string[], index: number) => (
    <InputSpf
      spfVal={converter(data)}
      change={(data) => changeSpf(index, data)}
      keyDown={add}
      key={index}
      total={props.rr.data.slice(0, -1).length}
      onBlur={(data) => {}}
    >
      {
        <IoTrash
          className={`${
            inProgress ? 'text-gray-400' : 'cursor-pointer'
          } text-gray-600 w-8`}
          onClick={() => delSpf(index)}
        />
      }
    </InputSpf>
  )

  const Input = () => <BtnAdd disAdd={disAdd} add={add} />

  return (
    <>
      <ListFrame
        title={Title}
        listData={props.rr.data.slice(0, -1)}
        list={List}
        input={Input}
        error={error}
      />

      <div className="mt-4">
        <Terminator vals={getLast()} change={changeLast} save={() => {}} />
      </div>
      <Preview zoneName={props.zoneName} rr={props.rr} />
    </>
  )
}

export default Value
