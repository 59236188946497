import React, { useEffect, useState } from 'react'
import { Rr } from '@/api/rrs'
import ErrorMsg from '../ErrorMsg'
import { useProgress, useSafeState } from '@/hooks'
import Selector from '../Selector'
import useTranslation from '@/i18n'
import { validateSrvRr, validateSrvHostName } from '../validator'
import { border } from '@/utils'

export type HostNameProps = {
  zoneName: string
  rr: Rr
  change: (rr: Rr) => void
  save: () => void
}

export function parseHostName(name: string) {
  const splitted = name.split('.')

  if (splitted.length < 2) {
    return { service: '', protocol: 0, hostName: name }
  }

  const protocol = _protocols.findIndex(
    (protocol) => `_${protocol}` === splitted[1]
  )

  if (protocol < 0) {
    return { service: '', protocol: 0, hostName: name }
  }

  const service = splitted[0] === '*' ? '*' : splitted[0].slice(1)
  const hostName = splitted.length === 2 ? '' : splitted.slice(2).join('.')

  return { service, protocol, hostName }
}

const _protocols = ['tcp', 'udp', 'sctp']

const HostName = ({ rr, change, zoneName, save }: HostNameProps) => {
  if (rr.type !== 'SRV') throw new Error('FIXME: this is not SRV')
  const [service, setService] = useSafeState('')
  const [serviceErr, setServiceErr] = useSafeState('')
  const [protocols, setProtocols] = useSafeState(0)
  const [hostName, setHostName] = useSafeState('')
  const [err, setErr] = useSafeState('')
  const { inProgress } = useProgress()
  const { t } = useTranslation()

  useEffect(() => {
    if (rr.id != null) {
      const { service, protocol, hostName } = parseHostName(rr.hostName)

      setService(service)
      setProtocols(protocol)
      setHostName(hostName)
    } else {
      setHostName(rr.hostName === '@' ? '' : rr.hostName)

      rr.hostName =
        rr.hostName === '' || rr.hostName === '@'
          ? `_${service}._${_protocols[protocols]}`
          : `_${service}._${_protocols[protocols]}.${rr.hostName}`

      change({ ...rr })
    }
  }, [])

  const changeService = (value: string) => {
    const serviceName = value === '*' ? '*' : `_${value.replace(/^_/, '')}`

    const res =
      hostName === ''
        ? `${serviceName}._${_protocols[protocols]}`
        : `${serviceName}._${_protocols[protocols]}.${hostName}`

    const { message } = validateSrvHostName(res)

    setErr(message)
    setServiceErr(message)
    setService(value)

    rr.hostName = res

    change({ ...rr })
  }

  const changeProtocols = (index: number) => {
    const serviceName = service === '*' ? '*' : `_${service.replace(/^_/, '')}`

    setProtocols(index)
    rr.hostName =
      hostName === ''
        ? `${serviceName}._${_protocols[index]}`
        : `${serviceName}._${_protocols[index]}.${hostName}`

    change({ ...rr })
  }

  const onChangeHostName = (value: string) => {
    const res = value.trim()
    const serviceName = service === '*' ? '*' : `_${service.replace(/^_/, '')}`

    rr.hostName =
      res === '' || res === '@'
        ? `${serviceName}._${_protocols[protocols]}`
        : `${serviceName}._${_protocols[protocols]}.${res}`

    setHostName(res)
    change({ ...rr })

    const { message } = validateSrvHostName(rr.hostName)
    setErr(message)
  }

  const keyDown = (e) => {
    if (e.keyCode === 13) {
      if (validateSrvRr(rr)) {
        save()
      }
    }
  }

  return (
    <>
      <div className="sm:flex sm:items-center">
        <div className="relative mr-1">
          <div className="flex items-center font-medium text-md">
            <p className="text-gray-900 mr-2">{t('Service')}</p>
          </div>
          <input
            disabled={inProgress}
            placeholder=""
            maxLength={179}
            value={service}
            onChange={(e) => changeService(e.target.value.trim())}
            onKeyDown={keyDown}
            className={`border-b ${border(
              service,
              serviceErr
            )} px-1 text-md focus:outline-none w-48 text-right`}
            autoFocus={false}
          />
        </div>
        <Selector
          current={_protocols[protocols]}
          title="Protocol"
          vals={_protocols}
          select={changeProtocols}
          disabled={inProgress}
        />
      </div>
      <div className="flex items-center mt-4">
        <input
          disabled={inProgress}
          placeholder="host name"
          maxLength={179}
          value={hostName}
          onChange={(e) => onChangeHostName(e.target.value)}
          onKeyDown={keyDown}
          className="border-b border-gray-500 px-1 text-md focus:outline-none w-48 text-right"
          autoFocus={false}
        />

        <p className="text-md">{`.${zoneName}`}</p>
      </div>
      <ErrorMsg error={err} />
    </>
  )
}

export default HostName
