import React from 'react'
import CheckBox from '@/components/CheckBox'
import TargetName from '../TargetName'
import { useSafeState } from '@/hooks'
import Port from './Port'
import Alpn from './Alpn'
import Mandatories from './Mandatories'
import Ech from './Ech'
import Priority from './Priority'
import { HttpsRdata } from '@/api/rrs'
import ErrorMsg from '@/components/Rrs/ErrorMsg'
import { ipV4, ipV6, validateAlpn, validateMandatory } from '../../validator'
import FlexList from '@/components/Rrs/FlexList'

type Props = {
  rdata: HttpsRdata
  onClick: (rdata: HttpsRdata) => void
  setRdata: (rdata: HttpsRdata) => void
}

function Service({ rdata, onClick, setRdata }: Props) {
  const [useHostName, setUseHostName] = useSafeState(rdata.targetName !== '.')
  const [alpnErr, setAlpnErr] = useSafeState('')
  const [mandatoryErr, setMandatoryErr] = useSafeState('')
  const [prev, setPrev] = useSafeState(rdata.targetName)

  const update = () => {}

  return (
    <div>
      <Priority
        value={String(rdata.priority)}
        change={(priority) => {
          rdata.priority = priority
          setRdata({ ...rdata })
        }}
        keyDown={update}
      />
      <div className="mt-4">
        <CheckBox
          label="Use hostname for target name"
          color="text-blue-600"
          toggle={() => {
            if (useHostName) {
              rdata.targetName = '.'
            } else {
              rdata.targetName = prev === '.' ? '' : prev
              setPrev(rdata.targetName)
            }

            setRdata({ ...rdata })
            setUseHostName(!useHostName)
          }}
          checked={!useHostName}
        />
      </div>
      {useHostName && (
        <TargetName
          value={rdata.targetName === '.' ? '' : rdata.targetName}
          info="DescriptionTargetNameSvc"
          change={(value) => {
            rdata.targetName = value
            setRdata({ ...rdata })
          }}
          keyDown={update}
        />
      )}
      <Port
        value={rdata.svcParams != null ? rdata.svcParams.port : undefined}
        change={(value) => {
          rdata.svcParams!.port = value === '' ? undefined : Number(value)
          setRdata({ ...rdata })
        }}
        keyDown={update}
      />
      <Alpn
        alpns={
          rdata.svcParams == null || rdata.svcParams.alpn.length === 0
            ? ['']
            : rdata.svcParams.alpn
        }
        update={(alpns) => {
          rdata.svcParams!.alpn = alpns
          setAlpnErr(validateAlpn(rdata))
          setMandatoryErr(validateMandatory(rdata))
          setRdata({ ...rdata })
        }}
      />
      <div className="mt-4">
        <CheckBox
          label="NOT_USE_HTTP_1_1"
          color="text-blue-600"
          toggle={() => {
            rdata.svcParams!.noDefaultAlpn =
              rdata.svcParams!.noDefaultAlpn == null ? true : undefined
            setAlpnErr(validateAlpn(rdata))
            setMandatoryErr(validateMandatory(rdata))
            setRdata({ ...rdata })
          }}
          checked={rdata.svcParams!.noDefaultAlpn != null}
        />
        <ErrorMsg error={alpnErr} />
      </div>
      <Ech
        ech={rdata.svcParams!.ech}
        change={(ech) => {
          rdata.svcParams!.ech = ech
          setRdata({ ...rdata })
        }}
      />
      <div className="mt-4">
        <FlexList
          title={'IPv4 hint'}
          vals={
            rdata.svcParams == null || rdata.svcParams.ipv4hints.length === 0
              ? ['']
              : rdata.svcParams.ipv4hints
          }
          validate={ipV4}
          change={(ipv4) => {
            rdata.svcParams!.ipv4hints = ipv4
            setMandatoryErr(validateMandatory(rdata))
            setRdata({ ...rdata })
          }}
          errorMsg={'Invalid IP address'}
          required={false}
        />
      </div>
      <div className="mt-4">
        <FlexList
          title={'IPv6 hint'}
          vals={
            rdata.svcParams == null || rdata.svcParams.ipv6hints.length === 0
              ? ['']
              : rdata.svcParams.ipv6hints
          }
          validate={ipV6}
          change={(ipv6) => {
            rdata.svcParams!.ipv6hints = ipv6
            setMandatoryErr(validateMandatory(rdata))
            setRdata({ ...rdata })
          }}
          errorMsg={'Invalid IP address'}
          required={false}
        />
      </div>
      <Mandatories
        mandatories={rdata.svcParams != null ? rdata.svcParams.mandatories : []}
        update={(m) => {
          rdata.svcParams!.mandatories = m
          setMandatoryErr(validateMandatory(rdata))
          setRdata({ ...rdata })
        }}
      />
      <ErrorMsg error={mandatoryErr} />
    </div>
  )
}

export default Service
