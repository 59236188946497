import { v4, v6 } from 'is-ip'
import { MxRdata, SrvRdata, Rr, HttpsRdata } from '@/api/rrs'
import { SpfVal } from '@/types'

const isCidr = require('is-cidr')

export function ipV4(ip: string) {
  return v4(ip)
}

export function ipV6(ip: string) {
  return v6(ip)
}

const fqdnRegExp = /^([a-zA-Z0-9_]|[a-zA-Z0-9_][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9_])(?:\.([a-zA-Z0-9_]|[a-zA-Z0-9_][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9_]))+$/
const fqdnPattern = new RegExp(fqdnRegExp, 'i')

export function validateFqdn(exchange: string) {
  if (exchange.length > 200) {
    return false
  }

  if (exchange === '.') return true

  return fqdnPattern.test(exchange)
}

const targetRegExp = /^([a-zA-Z0-9_]|[a-zA-Z0-9_][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9_])(?:\.([a-zA-Z0-9_]|[a-zA-Z0-9_][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9_]))+[.]{0,1}$/
const targetPattern = new RegExp(targetRegExp, 'i')

export function validateTargetName(value: string) {
  if (value.length > 253) return false
  return targetPattern.test(value)
}

const domainRegExp = /^([a-zA-Z0-9_@\*]|[a-zA-Z0-9_][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9_])(?:\.([a-zA-Z0-9_]|[a-zA-Z0-9_][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9_]))+$/
const domainPattern = new RegExp(domainRegExp, 'i')

export function validateDomainName(domain: string) {
  if (domain === '') return true

  if (domain.length > 200) return false

  const _domain = domain.length <= 63 ? `${domain}.com` : domain

  return domainPattern.test(_domain)
}

const hostNameRegExp = /^([a-zA-Z0-9_@\*]|[a-zA-Z0-9_][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9_])$/
const hostNamePattern = new RegExp(hostNameRegExp, 'i')

export function validateHostName(hostName: string) {
  if (hostName.length > 200) {
    return false
  }

  // TODO: There must be better way of regex
  return hostNamePattern.test(hostName) || domainPattern.test(hostName)
}

const pattern = /^[0-9]{1,}$/

export function isNumber(value: string) {
  return pattern.test(value)
}

export function validateTtl(ttl: number | string) {
  if (typeof ttl === 'string' && !pattern.test(ttl)) {
    return false
  }

  const ttlNum = Number(ttl)
  return ttlNum >= 300 && ttlNum <= 86400
}

const abstractDomainMxRegExp = /^([a-zA-Z0-9_]|[a-zA-Z0-9_][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9_])(?:\.([a-zA-Z0-9_]|[a-zA-Z0-9_][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9_]))+[.]{0,1}$/
const abstractDomainMxPattern = new RegExp(abstractDomainMxRegExp, 'i')

const hostNameMxRegExp = /^([a-zA-Z0-9_.]|[a-zA-Z0-9_][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9_])$/
const hostNameMxPattern = new RegExp(hostNameMxRegExp, 'i')

export function validateDomainInValue(value: string) {
  if (value.length > 200) {
    return false
  }

  // TODO: There must be better way of regex
  return hostNameMxPattern.test(value) || abstractDomainMxPattern.test(value)
}

export const validateEmail = (value: string): boolean => {
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    value
  )
}

export function validateRua(rua: string) {
  return validateEmail(rua)
}

export function validateRuf(ruf: string) {
  return validateEmail(ruf)
}

const preferencePattern = /^[0-9]{1,}$/

export function validatePreference(preference: number | string) {
  if (typeof preference === 'string') {
    if (!preferencePattern.test(preference)) {
      return false
    }

    const num = Number(preference)

    return num >= 0 && num <= 65535
  }
  return preference >= 0 && preference <= 65535
}

export function validateMx(mx: MxRdata) {
  return new Promise<void>((resolve, reject) => {
    if (!validatePreference(mx.preference)) {
      reject('Preference should be between 0 and 65535')
      return
    }

    if (!validateDomainInValue(mx.exchange)) {
      reject('Invalid hostname')
      return
    }
    resolve()
  })
}

export const validateDomainInValueExceptDot = (value: string) => {
  if (value === '.') return false

  return value.length > 0 && validateDomainInValue(value)
}

export function valiateIp4(value: string) {
  return v4(value) || isCidr.v4(value)
}

export function valiateIp6(value: string) {
  return v6(value) || isCidr.v6(value)
}

const macro_literal = '[\x21-\x24\x26-\x7e]' // visible characters except "%"
const macro_letter = '[slodiphcrtv]'
const transformers = '(?:d*r?)'
const delimiter = '[-.+,/_=]'
const macro_expand = `(?:(?:%{{${macro_letter}${transformers}${delimiter}*}})|%%|%_|%-)`
const macro_string = `(?:${macro_expand}|${macro_literal})*`
const ALPHA = '[a-zA-Z]'
const alphanum = '[a-zA-Z0-9]'
const toplabel = `(?:(?:${alphanum}*${ALPHA}${alphanum}*)|(?:${alphanum}-(?:${alphanum}|-)*${alphanum}))`
const domain_end = `(?:(?:\.${toplabel}\.?)|${macro_expand})`
const domain_spec = `^(?:${macro_string}${domain_end})$`

const macroPattern = new RegExp(domain_spec, 'i')

export function validateInclude(value: string) {
  return macroPattern.test(value)
}

const _validators = {
  ['ip4']: valiateIp4,
  ['ip6']: valiateIp6,
  ['include']: validateInclude,
}

export function validateSpf(spf: SpfVal) {
  return _validators[spf.mechanism](spf.value)
}

const labelRegExp = /^[a-zA-Z0-9_]{2,62}$/
const labelPattern = new RegExp(labelRegExp, 'i')

export const validateSrvHostName = (hostName: string) => {
  const splitted = hostName.split('.')

  if (splitted[0] !== '*' && !labelPattern.test(splitted[0])) {
    return { result: false, message: 'Invalid service' }
  }

  if (splitted.length > 2 && !validateDomainNameExpAster(splitted[2])) {
    return { result: false, message: 'Invalid hostname' }
  }

  return { result: true, message: '' }
}

export const validateSrv = (srv: SrvRdata) => {
  if (srv.target === '' || !validateDomainInValue(srv.target)) {
    return false
  }
  return srv.port <= 65535 && srv.priority <= 65535 && srv.weight <= 65535
}

export const validateSrvRr = (rr: Rr) => {
  if (rr.type !== 'SRV') throw Error('FIXME: this is not SRV')
  const { result } = validateSrvHostName(rr.hostName)

  if (!result) return false
  if (!validateTtl(rr.ttl)) return false

  if (rr.data.length === 0) return false

  const res = rr.data.filter((rdata) => !validateSrv(rdata))

  return res.length === 0
}

export const validateTlsaHostName = (hostName: string) => {
  const splitted = hostName.split('.')

  const port = splitted[0].slice(1)
  if (port.length === 0 || !pattern.test(port) || Number(port) > 65535) {
    return { result: false, message: 'Port number must be between 1 and 65535' }
  }

  const value = splitted.slice(2).join('.')

  if (value !== '' && !validateDomainNameExpAster(value)) {
    return { result: false, message: 'Invalid hostname' }
  }

  return { result: true, message: '' }
}

const domainExpAsterRegExp = /^([a-zA-Z0-9_]|[a-zA-Z0-9_][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9_])(?:\.([a-zA-Z0-9_]|[a-zA-Z0-9_][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9_]))+$/
const domainExpAsterPattern = new RegExp(domainExpAsterRegExp, 'i')

export const validateDomainNameExpAster = (domain: string) => {
  if (domain === '') return true

  if (domain.length > 200) return false

  const _domain = domain.length <= 63 ? `${domain}.com` : domain

  return domainExpAsterPattern.test(_domain)
}

const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/

export function validateEch(value: string) {
  if (value.length > 0 && value.length <= 255) {
    if (value.length < 4 || !base64regex.test(value)) {
      return false
    }
    return btoa(atob(value)) === value
  }

  return value.length === 0
}

export const validateAlpn = (rdata: HttpsRdata) => {
  // if (rdata.svcParams!.noDefaultAlpn && rdata.svcParams!.alpn.length === 0) {
  //   return 'You need to set ALPN (Protocol)'
  // } else {
  //   return ''
  // }

  return ''
}

export const validateMandatory = (rdata: HttpsRdata) => {
  const mandatories = rdata.svcParams!.mandatories

  if (
    mandatories.includes('alpn') &&
    rdata.svcParams!.alpn.filter((a) => a.length > 0).length === 0
  ) {
    return 'You need to set ALPN'
  } else if (
    mandatories.includes('ipv4hint') &&
    rdata.svcParams!.ipv4hints.filter((a) => a.length > 0).length === 0
  ) {
    return 'You need to IPv4 hint'
  } else if (
    mandatories.includes('ipv6hint') &&
    rdata.svcParams!.ipv6hints.filter((a) => a.length > 0).length === 0
  ) {
    return 'You need to IPv6 hint'
  } else if (mandatories.includes('ech') && rdata.svcParams!.ech == null) {
    return 'You need to Ech'
  }

  return ''
}

export function validateHttp(rdata: HttpsRdata) {
  if (rdata.priority === '') {
    return false
  }

  if (rdata.svcParams != null) {
    const ech = rdata.svcParams!.ech == null ? '' : rdata.svcParams!.ech
    if (ech.length > 0 && !validateEch(ech)) {
      return false
    }

    if (validateAlpn(rdata).length > 0) {
      return false
    }

    if (validateMandatory(rdata).length > 0) {
      return false
    }

    if (
      rdata.svcParams.ipv4hints.some((ipv4) => ipv4.length > 0 && !ipV4(ipv4))
    ) {
      return false
    }

    if (
      rdata.svcParams.ipv6hints.some((ipv6) => ipv6.length > 0 && !ipV6(ipv6))
    ) {
      return false
    }
  }

  if (
    rdata.targetName !== '.' &&
    (rdata.targetName === '' || !validateTargetName(rdata.targetName))
  ) {
    return false
  }

  return true
}

export function isValidHttpUrl(string) {
  let url

  try {
    url = new URL(string)
  } catch (_) {
    return false
  }

  return url.protocol === 'https:'
}

export const hexPattern = /^[0-9a-fA-F]{1,1024}$/

export function validateCaAssociationData(matchingType: number, value: string) {
  if (!hexPattern.test(value) || value.length % 2 !== 0)
    return { result: false, message: 'Invalid value' }

  if (matchingType === 0 && value.length < 10) {
    return {
      result: false,
      message: 'Must be equal or more than 10 charactors',
    }
  }

  if (matchingType === 1 && value.length !== 64) {
    return { result: false, message: 'Must be 64 charactors' }
  }

  if (matchingType === 2 && value.length !== 128) {
    return { result: false, message: 'Must be 128 charactors' }
  }

  return { result: true, message: '' }
}
