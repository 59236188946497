import React from 'react'
import { Rr } from '@/api/rrs'
import {
  HostName,
  convertOldHostName,
  convertRr,
  parseHostName,
} from '../Common'
import Value from './Value'
import {
  validateTtl,
  validateFqdn,
  validateHostName,
  validateSpf,
} from '../validator'
import { converter } from './Input'

export function parse(zoneName: string, rr: Rr) {
  if (rr.type !== 'SPF') {
    throw Error(`Fixme!! this is not SPF: ${JSON.stringify(rr)}`)
  }

  const fqdn =
    rr.hostName === '' || rr.hostName === '@'
      ? `${zoneName}.`
      : `${rr.hostName}.${zoneName}.`

  const Val = ({ value }) => {
    if (value.length === 0) return <></>
    if (value.length === 1) return <p className="truncate">{value[0]}</p>
    if (value[0] === '+') {
      return <p className="truncate">{value.slice(1).join(': ')}</p>
    } else if (value[0] === '-') {
      return <p className="truncate">{value.join('')}</p>
    }
    return <p className="truncate">{value.join(': ')}</p>
  }

  const hostName = rr.hostName === '' ? '@' : rr.hostName
  return { fqdn, hostName, rrType: `TXT(${rr.type})`, Val }
}

export function create() {
  function isReady(rr: Rr) {
    if (rr.type !== 'SPF') throw Error('Fixme: this is not SPF record')

    if (
      rr.hostName !== '' &&
      rr.hostName !== '@' &&
      !validateHostName(rr.hostName)
    )
      return false

    if (!validateTtl(rr.ttl)) return false

    if (rr.data.length === 0) return false

    const last = rr.data[rr.data.length - 1]
    if (last[0] === 'redirect' && (last[1] === '' || !validateFqdn(last[1]))) {
      return false
    }

    if (rr.data.length > 0) {
      const res = rr.data.slice(0, -1).filter((rawData) => {
        const spf = converter(rawData)
        return spf.value === '' || validateSpf(spf)
      })

      return res.length === rr.data.length - 1
    }
    return true
  }

  return {
    HostName,
    Val: Value,
    isReady,
    convertOldHostName,
    convertRr: (rr: Rr) => {
      if (rr.type !== 'SPF') throw Error('Fixme: this is not SPF record')
      rr.hostName = rr.hostName === '' ? '@' : rr.hostName

      const res = rr.data.slice(0, -1).filter((rawData) => {
        const spf = converter(rawData)
        return spf.value !== ''
      })

      res.push(rr.data[rr.data.length - 1])

      rr.data = res

      return rr
    },
  }
}

export { parseHostName }
