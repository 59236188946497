import React from 'react'
import { Rr, HttpsRdata } from '@/api/rrs'
import {
  parse as defaultParse,
  HostName,
  convertOldHostName,
  parseHostName,
} from '../Common'
import Value from './Value'
import { validateHttp, validateHostName, validateTtl } from '../validator'
import { service } from './convert'

export function parse(zoneName: string, rr: Rr) {
  const { fqdn, hostName, rrType } = defaultParse(zoneName, rr)

  const Val = ({ value }: { value: HttpsRdata }) => {
    const txt = service(value)
    return <p className="truncate">{txt}</p>
  }

  return { fqdn, hostName, rrType, Val }
}

const convertRr = (rr: Rr, zoneName: string) => {
  if (rr.type !== 'HTTPS') throw Error('FIXME: this is not HTTPS record')
  //TODO: 直接いじるのではなくて、コピーをいじること。
  rr.hostName = rr.hostName === '' ? '@' : rr.hostName
  rr.data = rr.data.map((svc) => {
    svc.targetName = svc.targetName.endsWith('.')
      ? svc.targetName
      : `${svc.targetName}.`

    if (svc.svcParams != null) {
      if (svc.svcParams.ech === '') {
        svc.svcParams.ech = undefined
      }
      svc.svcParams.ipv4hints = svc.svcParams.ipv4hints.filter(
        (ipv4) => ipv4.length > 0
      )
      svc.svcParams.ipv6hints = svc.svcParams.ipv6hints.filter(
        (ipv6) => ipv6.length > 0
      )
      svc.svcParams.alpn = svc.svcParams.alpn.filter((a) => a.length > 0)
    }

    return svc
  })

  return rr
}
//
export function create() {
  function isReady(rr: Rr) {
    if (rr.type !== 'HTTPS') throw Error('Fixme: this is not HTTPS record')

    if (
      rr.hostName !== '' &&
      rr.hostName !== '@' &&
      !validateHostName(rr.hostName)
    )
      return false

    if (!validateTtl(rr.ttl)) return false

    if (rr.data.length === 0) return false

    if (rr.data[0].priority === '0') {
      if (rr.data[0].targetName.length === 0) {
        return false
      }
    }

    return !rr.data.some((rdata) => !validateHttp(rdata))
  }

  return { HostName, Val: Value, isReady, convertOldHostName, convertRr }
}

export { parseHostName }
