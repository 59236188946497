import React from 'react'
import { Rr } from '@/api/rrs'
import {
  HostName,
  convertOldHostName,
  convertRr,
  parseHostName,
} from './Common'
import { ValProps } from '@/types'

export function parse(zoneName: string, rr: Rr) {
  if (rr.type !== 'SOA') {
    throw Error(`Fixme!! this is not SOA: ${JSON.stringify(rr)}`)
  }

  const fqdn =
    rr.hostName === '' || rr.hostName === '@'
      ? `${zoneName}.`
      : `${rr.hostName}.${zoneName}.`

  const Val = ({ value }) => {
    const { primaryNs, email, serial, refresh, retry, expire, minTtl } = value

    return (
      <p className="truncate">
        {`${primaryNs} ${email} ${serial} ${refresh} ${retry} ${expire} ${minTtl}`}
      </p>
    )
  }
  const hostName = rr.hostName === '' ? '@' : rr.hostName

  return { fqdn, hostName, rrType: rr.type, Val }
}

export function create() {
  function isReady(rr: Rr) {
    return true
  }
  const Val = (props: ValProps) => {
    return <></>
  }
  return { HostName, Val, isReady, convertOldHostName, convertRr }
}

export { parseHostName }
