import React from 'react'
import { useErr, useModal, useProgress } from '@/hooks'
import useTranslation from '@/i18n'
import { Rr } from '@/api/rrs'
import { post } from '@/api/zoneTransfer'
import Step from './Step'
import { IoMdWarning } from 'react-icons/io'

type ConfirmStep1Props = {
  next: () => void
}

const ConfirmStep1 = ({ next }: ConfirmStep1Props) => {
  const { inProgress } = useProgress()
  const { t } = useTranslation()

  return (
    <div className="sm:w-auto">
      <div className="flex items-center my-1 text-2xl px-2">
        <IoMdWarning className="text-yellow-500" />
        <p className="text-red-500 font-medium">{t('WARNING')}</p>
      </div>
      <p className="text-gray-700 px-4 font-medium py-1 mb-2 break-words">
        {t('TransferZoneStep1Confirm')}
      </p>
      <div className="bg-gray-100 px-2 py-2 text-right">
        <button
          disabled={inProgress}
          className={`${
            inProgress ? 'bg-gray-400' : 'bg-blue-500'
          } text-white px-8`}
          onClick={next}
        >
          {t('Yes')}
        </button>
      </div>
    </div>
  )
}

type Props = {
  step: number
  zoneId: number
  setStep: (step: number) => void
  rrs: Rr[]
}

const Step1 = ({ rrs, zoneId, step, setStep }: Props) => {
  const { inProgress, start, stop } = useProgress()
  const { openModal, closeModal } = useModal()
  const { setErr } = useErr()

  const click = () => {
    const res = rrs.filter((rr) => rr.type !== 'SOA' && rr.type !== 'NS')

    const next = () => {
      start()
      return post({ zoneId, status: step })
        .then(() => {
          stop()
          closeModal()
          setStep(step + 1)
        })
        .catch((err) => {
          setErr(err.message, err.status)
          closeModal()
          stop()
        })
    }

    if (res.length === 0) {
      openModal(<ConfirmStep1 next={next} />)
      return
    }

    next()
  }

  return (
    <Step
      step={1}
      done={step > 1}
      enable={step === 1}
      title="Transfer records except NS record"
      check={click}
      btnTitle="Next"
    />
  )
}

export default Step1
