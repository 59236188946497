import React from 'react'
import { validateDomainInValueExceptDot } from '../validator'
import { ValProps } from '@/types'
import FlexList from '@/components/Rrs/FlexList'

const Value = (props: ValProps) => {
  if (props.rr.type !== 'NS') throw Error('Fixme: this is not NS record')

  const changeNsList = (nslist: string[]) => {
    props.rr.data = nslist
    props.change({ ...props.rr })
  }

  return (
    <div className="mt-4">
      <FlexList
        title={'Name servers'}
        vals={props.rr.data.length === 0 ? [''] : props.rr.data}
        validate={validateDomainInValueExceptDot}
        change={changeNsList}
        errorMsg={'Invalid or found the same NS'}
      />
    </div>
  )
}

export default Value
