import React from 'react'
import { DmarcRdata, Rr } from '@/api/rrs'
import useTranslation from '@/i18n'
import { HostName } from './Hostname'
import { validateDomainName, validateRua, validateRuf } from '../validator'
import Value from './Value'
import { parseHostName } from '../Common'

function _convMode(flag: string) {
  return flag === 's' ? 'strict' : 'relaxed'
}

function _convFo(fo: string) {
  switch (fo) {
    case '0':
      return 'fo0'
    case '1':
      return 'fo1'
    case 'd':
      return 'fod'
    case 's':
      return 'fos'
    default:
      return 'unkown'
  }
}

export function parse(zoneName: string, rr: Rr) {
  if (rr.type !== 'DMARC') {
    throw Error(`Fixme!! this is not DMARC: ${JSON.stringify(rr)}`)
  }

  const domain =
    rr.hostName === '' || rr.hostName === '@'
      ? `${zoneName}.`
      : `${rr.hostName}.${zoneName}.`

  const fqdn = `_dmarc.${domain}`

  const Val = ({ value }: { value: DmarcRdata }) => {
    const { t } = useTranslation()
    const { p, fo, rua, ruf, ri, adkim, aspf, pct, sp } = value

    return (
      <>
        <p className="truncate">{`p(Policy): ${p}`}</p>
        {fo && (
          <p className="truncate">{`fo(Failure reporting options): ${t(
            _convFo(fo)
          )}`}</p>
        )}
        {rua.length > 0 && (
          <p className="truncate">{`rua(Aggregate data report): ${rua.join(
            ', '
          )}`}</p>
        )}
        {ruf.length > 0 && (
          <p className="truncate">{`ruf(Failure report): ${ruf.join(', ')}`}</p>
        )}
        {ri && (
          <p className="truncate">{`ri(Interval of report): ${ri} seconds`}</p>
        )}
        {adkim && (
          <p className="truncate">{`adkim(DKIM identifier alignment mode): ${_convMode(
            adkim
          )}`}</p>
        )}
        {aspf && (
          <p className="truncate">{`spf(SPF identifier alignment mode): ${_convMode(
            aspf
          )}`}</p>
        )}
        {pct && <p className="truncate">{`pct(Percentage): ${pct}%`}</p>}
        {sp && (
          <p className="truncate">{`sp(Requested Mail Receiver policy for all subdomains): ${sp}`}</p>
        )}
      </>
    )
  }

  const hostName =
    rr.hostName === '' || rr.hostName === '@'
      ? `_dmarc`
      : `_dmarc.${rr.hostName}`

  return { fqdn, hostName, rrType: `TXT(${rr.type})`, Val }
}

export function create() {
  function isReady(rr: Rr) {
    if (rr.type !== 'DMARC') throw Error('FIXME: this is not DMARC')

    if (rr.hostName !== '@' && !validateDomainName(rr.hostName)) return false

    if (rr.data.length === 0) {
      return false
    }

    const ruaList = rr.data[0].rua.filter((email) => email.length > 0)

    if (
      ruaList.filter((email) => validateRua(email)).length !== ruaList.length
    ) {
      return false
    }

    const rufList = rr.data[0].ruf.filter((email) => email.length > 0)

    if (
      rufList.filter((email) => validateRuf(email)).length !== rufList.length
    ) {
      return false
    }

    return true
  }

  return {
    HostName,
    Val: Value,
    isReady,
    convertOldHostName: (rr: Rr) => {
      if (rr.id == null || rr.hostName == null) return undefined
      return rr.hostName === '@' || rr.hostName === ''
        ? '_dmarc'
        : `_dmarc.${rr.hostName}`
    },
    convertRr: (rr: Rr) => {
      if (rr.type !== 'DMARC') throw Error('FIXME: this is not DMARC')

      rr.hostName = rr.hostName === '@' ? '' : rr.hostName
      rr.data[0].rua = rr.data[0].rua.filter((rua) => rua.length > 0)
      rr.data[0].ruf = rr.data[0].ruf.filter((ruf) => ruf.length > 0)
      return rr
    },
  }
}

export { parseHostName }
