import React from 'react'
import { Rr } from '@/api/rrs'
import useTranslation from '@/i18n'
import Services from './Services'

type Props = {
  rr: Rr
  update: (rr: Rr) => void
}

function Svc({ rr, update }: Props) {
  if (rr.type !== 'HTTPS') throw Error('FIXME: this is not HTTPS')
  const { t } = useTranslation()

  return (
    <div className="mt-4">
      <p className="text-md font-medium text-gray-900">
        {t('Service configuration')}
      </p>

      <Services rr={rr} update={update} />
    </div>
  )
}

export default Svc
