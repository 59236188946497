import React, { useEffect, useState } from 'react'
import { Rr } from '@/api/rrs'
import ErrorMsg from '../ErrorMsg'
import { useProgress } from '@/hooks'
import Selector from '../Selector'
import useTranslation from '@/i18n'
import { validateTlsaHostName } from '../validator'
import { border } from '@/utils'

export type HostNameProps = {
  zoneName: string
  rr: Rr
  change: (rr: Rr) => void
  save: () => void
}

export function parseHostName(name: string) {
  const splitted = name.split('.')

  if (splitted.length < 2) {
    return { port: '25', protocol: 0, hostName: name }
  }

  const protocol = _protocols.findIndex(
    (protocol) => `_${protocol}` === splitted[1]
  )

  if (protocol < 0) {
    return { port: '25', protocol: 0, hostName: name }
  }

  const port = splitted[0].slice(1)
  const hostName = splitted.length === 2 ? '' : splitted.slice(2).join('.')

  return { port, protocol, hostName }
}

const _protocols = ['tcp', 'udp', 'sctp']
const pattern = /^[1-9]{1}[0-9]{0,}$/

const HostName = ({ rr, change, zoneName, save }: HostNameProps) => {
  if (rr.type !== 'TLSA') throw new Error('FIXME: this is not TLSA')
  const [port, setPort] = useState('25')
  const [protocols, setProtocols] = useState(0)
  const [hostName, setHostName] = useState('')
  const [err, setErr] = useState('')
  const [portErr, setPortErr] = useState('')
  const { inProgress } = useProgress()
  const { t } = useTranslation()

  useEffect(() => {
    if (rr.id != null) {
      const { port, protocol, hostName } = parseHostName(rr.hostName)
      setPort(port)
      setProtocols(protocol)
      setHostName(hostName)
    } else {
      setHostName(rr.hostName === '@' ? '' : rr.hostName)

      rr.hostName =
        rr.hostName === '' || rr.hostName == '@'
          ? `_${port}._${_protocols[protocols]}`
          : `_${port}._${_protocols[protocols]}.${rr.hostName}`

      change({ ...rr })
    }
  }, [])

  const changePort = (port: string) => {
    const res =
      hostName === ''
        ? `_${port}._${_protocols[protocols]}`
        : `_${port}._${_protocols[protocols]}.${hostName}`

    const { message } = validateTlsaHostName(res)
    setErr(message)
    setPortErr(message)

    if (port === '' || pattern.test(port)) {
      setPort(port)
      rr.hostName = res

      change({ ...rr })
    }
  }

  const changeProtocols = (index: number) => {
    setProtocols(index)
    rr.hostName =
      hostName === ''
        ? `_${port}._${_protocols[index]}`
        : `_${port}._${_protocols[index]}.${hostName}`

    change({ ...rr })
  }

  const onChangeHostName = (value: string) => {
    const res = value.trim()

    rr.hostName =
      res === '' || res === '@'
        ? `_${port}._${_protocols[protocols]}`
        : `_${port}._${_protocols[protocols]}.${res}`

    setHostName(res)
    change({ ...rr })

    const { message } = validateTlsaHostName(rr.hostName)

    setErr(message)
  }

  const keyDown = (e) => {
    if (e.keyCode === 13) {
      save()
    }
  }

  return (
    <>
      <div className="sm:flex sm:items-center">
        <div className="relative mr-1">
          <div className="flex items-center font-medium text-md">
            <p className="text-gray-900 mr-2">{t('Port')}</p>
          </div>
          <input
            disabled={inProgress}
            placeholder="port"
            maxLength={5}
            value={port}
            onChange={(e) => changePort(e.target.value.trim())}
            onKeyDown={keyDown}
            className={`border-b ${border(
              port,
              portErr
            )} px-1 text-md focus:outline-none w-24 text-right`}
            autoFocus={true}
          />
        </div>
        <Selector
          current={_protocols[protocols]}
          title="Protocol"
          vals={_protocols}
          select={changeProtocols}
          disabled={inProgress}
        />
      </div>
      <div className="flex items-center mt-4">
        <input
          disabled={inProgress}
          placeholder="host name"
          maxLength={179}
          value={hostName}
          onChange={(e) => onChangeHostName(e.target.value)}
          onKeyDown={keyDown}
          className="border-b border-gray-500 px-1 text-md focus:outline-none w-48 text-right"
          autoFocus={false}
        />

        <p className="text-md">{`.${zoneName}`}</p>
      </div>
      <ErrorMsg error={err} />
    </>
  )
}

export default HostName
