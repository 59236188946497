import React, { useState, ReactNode } from 'react'
import { SrvRdata } from '@/api/rrs'
import ErrorMsg from '../ErrorMsg'
import InputNumber from '@/components/InputNumber'
import { validateDomainInValue } from '../validator'
import { border } from '@/utils'

type Props = {
  children: ReactNode
  inProgress: boolean
  srvRdata: SrvRdata
  blur?: (srvRdata: SrvRdata) => void
  change: (srvRdata: SrvRdata) => void
  keyDown: (srvRdata: SrvRdata) => void
  total: number
}

const Rdata = ({
  inProgress,
  srvRdata,
  children,
  change,
  keyDown,
  total,
}: Props) => {
  const [error, setError] = useState<{ message: string; details?: any }>({
    message: '',
  })

  const changePriority = (value: string | number) => {
    srvRdata.priority = value
    change({ ...srvRdata })
  }

  const changeWeight = (value: string | number) => {
    srvRdata.weight = value
    change({ ...srvRdata })
  }

  const changePort = (value: string | number) => {
    srvRdata.port = value
    change({ ...srvRdata })
  }

  const changeTarget = (value: string) => {
    if (!validateDomainInValue(value)) {
      setError({ message: 'Invalid target' })
    } else {
      if (
        srvRdata.port < 65535 &&
        srvRdata.priority < 65535 &&
        srvRdata.weight < 65535
      ) {
        setError({ message: '' })
      } else {
        setError({ message: 'InvalidNumber0to65535' })
      }
    }

    srvRdata.target = value
    change({ ...srvRdata })
  }

  const onError = (info: { message: string; details?: any }) => {
    setError(info)
  }

  const onKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      keyDown(srvRdata)
    }
  }

  return (
    <div className="">
      <div className="grid grid-cols-12 gap-1">
        <div className="col-span-2">
          <InputNumber
            value={srvRdata.priority}
            change={changePriority}
            error={onError}
            min={0}
            max={65535}
            required={true}
            keyDown={onKeyDown}
          />
        </div>
        <div className="col-span-2">
          <InputNumber
            value={srvRdata.weight}
            change={changeWeight}
            error={onError}
            min={0}
            max={65535}
            required={true}
            keyDown={onKeyDown}
          />
        </div>
        <div className="col-span-2">
          <InputNumber
            value={srvRdata.port}
            change={changePort}
            error={onError}
            min={0}
            max={65535}
            required={true}
            keyDown={onKeyDown}
          />
        </div>
        <div className={`${total > 1 ? 'col-span-5' : 'col-span-6'}`}>
          <input
            disabled={inProgress}
            maxLength={179}
            value={srvRdata.target}
            onChange={(e) => changeTarget(e.target.value.trim())}
            onKeyDown={onKeyDown}
            className={`border-b ${border(
              srvRdata.target,
              error.message
            )} px-1 text-md focus:outline-none w-full`}
            autoFocus={true}
          />
        </div>
        {total > 1 && <div className={'col-span-1'}>{children}</div>}
      </div>
      <ErrorMsg error={error.message} params={error.details} />
    </div>
  )
}

export default Rdata
