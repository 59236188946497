import React, { useEffect, useState, useRef } from 'react'
import { get } from '@/api/ns-check'
import Step from './Step'
import { useErr, useProgress, useSafeState } from '@/hooks'
import { post } from '@/api/zoneTransfer'
import { NsResult } from '@/api/error'
import useTranslation from '@/i18n'

type Props = {
  domainName: string
  zoneId: number
  step: number
  mode: number
  setStep: (step: number) => void
}

const Step2 = ({ domainName, zoneId, step, mode, setStep }: Props) => {
  const { start, stop } = useProgress()
  const [error, setError] = useSafeState<NsResult | null>(null)
  const { setErr } = useErr()
  const { t } = useTranslation()

  useEffect(() => {
    if (step === 2) {
      click()
    }
  }, [step])

  const click = () => {
    start()
    setError(null)
    get({ domainName, targetNs: mode === 1 ? 'child' : undefined })
      .then(() => {
        post({ zoneId, status: step })
          .then(() => {
            setStep(step + 1)
            stop()
          })
          .catch((err) => {
            setErr(err.message, err.status)
            stop()
          })
      })
      .catch((err) => {
        setError(err.params)
        stop()
      })
    // For testing
    // const params = {
    //   nsResult: [
    //     {
    //       nsServer: 'ns1.dnsauth.cdev.jp',
    //       ns: [
    //         'ns1.dnsauth.cdev.jp.ns1.dnsauth.cdev.jp.ns1.dnsauth.cdev.jp.ns1.dnsauth.cdev.jp.ns1.dnsauth.cdev.jp.ns1.dnsauth.cdev.jp.ns1.dnsauth.cdev.jp.ns1.dnsauth.cdev.jp.ns1.dnsauth.cdev.jp',
    //         'test.com',
    //         'homo.test.com',
    //       ],
    //     },
    //     {
    //       nsServer: 'ns2.dnsauth.cdev.jp',
    //       ns: [
    //         'ns1.dnsauth.cdev.jp',
    //         'ns1.dnsauth.cdev.jp.ns1.dnsauth.cdev.jp.ns1.dnsauth.cdev.jp.ns1.dnsauth.cdev.jp.ns1.dnsauth.cdev.jp.ns1.dnsauth.cdev.jp.ns1.dnsauth.cdev.jp.ns1.dnsauth.cdev.jp.ns1.dnsauth.cdev.jp',
    //       ],
    //     },
    //   ],
    //   qtDnsNs: [
    //     'test.cdev.jp',
    //     'test2.cdev.jp',
    //     'ns1.dnsauth.cdev.jp.ns1.dnsauth.cdev.jp.ns1.dnsauth.cdev.jp.ns1.dnsauth.cdev.jp.ns1.dnsauth.cdev.jp.ns1.dnsauth.cdev.jp.ns1.dnsauth.cdev.jp.ns1.dnsauth.cdev.jp.ns1.dnsauth.cdev.jp',
    //   ],
    // }
    // setError(params)
    // stop()
  }

  return (
    <>
      <Step
        step={2}
        done={step > 2}
        enable={step === 2}
        title="Set the NS records of your current DNS server to QUALITIA DNS"
        check={click}
        btnTitle="Check"
      />

      {error != null && (
        <div className="mb-4 ">
          <div className="sm:flex items-start">
            <div className="">
              <h1 className="text-base text-gray-500 font-medium w-64">
                {t('Current name servers')}
              </h1>
              <ul className="w-64">
                {error.nsResult.map((ns, index) => {
                  return (
                    <li className="mb-2" key={index}>
                      <h1 className="text-red-500 font-medium">
                        {t(ns.nsServer)}
                      </h1>
                      <ul>
                        {ns.ns.map((server, index) => (
                          <li className="text-sm truncate" key={index}>
                            {server}
                          </li>
                        ))}
                      </ul>
                    </li>
                  )
                })}
              </ul>
            </div>

            <div className="sm:ml-24">
              <h1 className="text-base text-gray-500 font-medium">
                {t('QUALITIA DNS name servers')}
              </h1>
              <ul className="w-64">
                {error.qtDnsNsList.map((server, index) => (
                  <li className="text-sm truncate" key={index}>
                    {server}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Step2
