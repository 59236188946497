import React from 'react'
import { Rr, OtherRdata } from '@/api/rrs'
import { convertOldHostName, convertRr, parseHostName } from '../Common'
import { HostName } from '../Common'
import Value from './Value'
import { validateTtl, validateHostName } from '../validator'
import { rrTypes } from '../rrTyps'

export function parse(zoneName: string, rr: Rr) {
  const fqdn =
    rr.hostName === '' || rr.hostName === '@'
      ? `${zoneName}.`
      : `${rr.hostName}.${zoneName}.`

  const Val = ({ value }: { value: OtherRdata }) => {
    return <p className="truncate">{value.value}</p>
  }
  const hostName = rr.hostName === '' ? '@' : rr.hostName

  return { fqdn, hostName, rrType: rr.type, Val }
}

export function create() {
  function isReady(rr: Rr) {
    if (rrTypes.includes(rr.type))
      throw Error('Fixme: this is not OTHER record')

    if (
      rr.hostName !== '' &&
      rr.hostName !== '@' &&
      !validateHostName(rr.hostName)
    )
      return false

    if (rr.data.length === 0) return false

    if (!validateTtl(rr.ttl)) return false

    const getOther = () => {
      if (rr.data.length > 0) {
        return rr.data[0] as OtherRdata
      } else {
        return { type: '', value: '' } as OtherRdata
      }
    }

    return (
      rr.data.length > 0 && getOther().type !== '' && getOther().value !== ''
    )
  }

  return { HostName, Val: Value, isReady, convertOldHostName, convertRr }
}

export { parseHostName }
