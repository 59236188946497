import React, { useEffect, useRef, useState } from 'react'
import useTranslation from '@/i18n'

type Props = {
  hostAndType: string
  rdata: string[]
  className?: string
}

const Preview = ({ hostAndType, rdata, className }: Props) => {
  const { t } = useTranslation()

  return (
    <div className="w-full">
      <p className="mt-4 text-md font-medium text-gray-900">{t('Preview')}</p>
      <div className="bg-gray-200 px-4 py-1 w-full ">
        <p className="w-full text-md break-all pr-2 ">{hostAndType}</p>
        {rdata.map((rr, index) => (
          <p
            className={
              className == null ? 'w-full text-md px-2  break-all' : className
            }
            key={index}
          >
            {rr}
          </p>
        ))}
      </div>
    </div>
  )
}

export default Preview
