import React from 'react'
import { Rr } from '@/api/rrs'
import Pre from '../Preview'

type Props = {
  zoneName: string
  rr: Rr
}

const Preview = ({ rr, zoneName }: Props) => {
  if (rr.type !== 'DKIM') throw Error('FIXME: this is not DKIM')

  const res =
    rr.hostName === '@' || rr.hostName === ''
      ? `_domainkey.${zoneName}`
      : `_domainkey.${rr.hostName}.${zoneName}`

  const hostname =
    rr.data.length > 0 &&
    rr.data[0].selector != null &&
    rr.data[0].selector !== ''
      ? `${rr.data[0].selector}.${res}`
      : res

  const records = [hostname, rr.ttl, 'IN TXT']

  const temp: string[] = ['"v=DKIM;']
  if (rr.data.length > 0) {
    const rdata = rr.data[0]

    if (rdata.h != null) {
      temp.push(`h=${rdata.h};`)
    }

    if (rdata.k != null && rdata.k.length > 0) {
      temp.push(`k=${rdata.k};`)
    }

    if (rdata.p != null && rdata.p.length > 0) {
      temp.push(`p=${rdata.p};`)
    }

    if (rdata.testMode) {
      temp.push(`t=y;`)
    }
  }

  return <Pre hostAndType={records.join(' ')} rdata={[`${temp.join(' ')}"`]} />
}

export default Preview
