import React from 'react'
import { useProgress } from '@/hooks'
import Selector from '@/components/Rrs/Selector'
import { FaMinusCircle } from 'react-icons/fa'

type Props = {
  selected: string
  options: string[]
  total: number
  change: (selected: string) => void
  del: () => void
}

function CurrentAlpn(props: Props) {
  const { inProgress } = useProgress()

  return (
    <div className="flex items-center text-sm text-gray-700 w-full mt-1">
      <FaMinusCircle
        className={`${props.total === 1 &&
          'hidden'} text-green-500 mr-1 cursor-pointer`}
        onClick={props.del}
      />

      <div className="mr-4  w-full">
        <Selector
          current={props.selected}
          vals={props.options}
          select={(index) => {
            const res = props.options[index]
            props.change(res === 'No ALPN' ? '' : res)
          }}
          disabled={inProgress}
        />
      </div>
    </div>
  )
}

export default CurrentAlpn
