import { axios, getAuthorization } from '@/api/axios'
import { withRefreshToken } from '@/auth'
import { errConverter } from './error'

export type KValue = '' | 'ed25519'
export type PValue = string
export type HValue = 'sha256' | 'sha1'

export type DkimData = {
  selector?: string
  h?: HValue
  k?: KValue
  p?: PValue
  testMode?: boolean
}

export type DmarcRdata = {
  p: string
  fo?: string
  rua: string[]
  ruf: string[]
  ri?: number
  adkim?: string
  aspf?: string
  pct?: number
  sp?: string
}

export type MxRdata = {
  preference: number | string
  exchange: string
}

type ARdata = string

type MxData = {
  type: 'MX'
  rdata: MxRdata[]
}

type AValue = {
  type: 'A'
  value: {
    id: number
    rdata: ARdata
  }
}

export type SoaValue = {
  primaryNs: string
  email: string
  serial: string
  refresh: string
  retry: string
  expire: string
  minTtl: string
}

export type TlsaRdata = {
  certificationUsage: number
  selector: number
  matchingType: number
  certificationAssociationData: string
}

export type SrvRdata = {
  priority: number | string
  weight: number | string
  port: number | string
  target: string
}

export type SvcParams = {
  mandatories: string[]
  alpn: string[]
  noDefaultAlpn?: boolean
  port?: number
  ech?: string
  ipv4hints: string[]
  ipv6hints: string[]
}

export type HttpsRdata = {
  priority: string
  targetName: string
  svcParams?: SvcParams
}

export type BimiRdata = {
  l: string
  a?: string
}

export type OtherRdata = { type: string; value: string }

export type Rtype =
  | 'A'
  | 'AAAA'
  | 'MX'
  | 'TXT'
  | 'CNAME'
  | 'NS'
  | 'TLSA'
  | 'SRV'
  | 'SPF'
  | 'DKIM'
  | 'DMARC'
  | 'SOA'
  | 'HTTPS'
  | 'BIMI'
  | 'OTHER'

export type Rr = {
  id?: number
  hostName: string
  ttl: number | string
  mutable?: boolean
} & (
  | {
      type: 'MX'
      data: MxRdata[]
    }
  | {
      type: 'A'
      data: string[]
    }
  | {
      type: 'AAAA'
      data: string[]
    }
  | {
      type: 'SPF'
      data: string[][]
    }
  | {
      type: 'DKIM'
      data: DkimData[]
    }
  | {
      type: 'DMARC'
      data: DmarcRdata[]
    }
  | {
      type: 'TXT'
      data: string[]
    }
  | {
      type: 'CNAME'
      data: string[]
    }
  | {
      type: 'NS'
      data: string[]
    }
  | {
      type: 'SOA'
      data: SoaValue[]
    }
  | {
      type: 'TLSA'
      data: TlsaRdata[]
    }
  | {
      type: 'SRV'
      data: SrvRdata[]
    }
  | {
      type: 'HTTPS'
      data: HttpsRdata[]
    }
  | {
      type: 'BIMI'
      data: BimiRdata[]
    }
  | {
      type: 'OTHER'
      data: OtherRdata[]
    }
)

export type Rrs = {
  zoneId: number
  rrs: Rr[]
}

const getRrs = (zoneId?: number): Promise<Rrs> =>
  new Promise<Rrs>((resolve, reject) =>
    axios
      .get<{ message: string }>(`/dev/rr/list`, {
        headers: getAuthorization(),
        params: {
          zoneId,
        },
      })
      .then((res) => {
        const parsed: Rrs = JSON.parse(res.data.message)
        resolve(parsed)
      })
      .catch((err) => {
        reject(errConverter(err))
      })
  )

const get = withRefreshToken(getRrs)

type RrsType = {
  zoneId: number
  rrs: Rr
  oldHostName?: string
}

const postRrs = (params?: RrsType): Promise<string> =>
  new Promise<string>((resolve, reject) =>
    axios
      .post<{ message: string }>(`dev/rrs`, params, {
        headers: getAuthorization(),
      })
      .then((result) => {
        resolve(result.data.message)
      })
      .catch((err) => {
        reject(errConverter(err))
      })
  )

const post = withRefreshToken(postRrs)

export const removeRrs = (option?: {
  zoneId: number
  hostName: string
  type: string
}): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    axios
      .delete<{ message: string }>('dev/rrs/', {
        headers: getAuthorization(),
        data: option,
      })
      .then((result) => {
        resolve(result.data.message)
      })
      .catch((err) => {
        reject(errConverter(err))
      })
  })

const remove = withRefreshToken(removeRrs)

export { get, post, remove }
