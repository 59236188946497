import React from 'react'
import { Rr, TlsaRdata } from '@/api/rrs'
import { convertOldHostName, convertRr } from '../Common'
import HostName, { parseHostName } from './Hostname'
import Value from './Value'
import { tlsaCaUsages, tlsaMatchingTypes, tlsaSelector } from './Rdata'
import {
  validateTtl,
  validateTlsaHostName,
  validateCaAssociationData,
} from '../validator'

export function parse(zoneName: string, rr: Rr) {
  if (rr.type !== 'TLSA') {
    throw Error(`Fixme!! this is not TLSA: ${JSON.stringify(rr)}`)
  }

  const fqdn =
    rr.hostName === '' || rr.hostName === '@'
      ? `${zoneName}.`
      : `${rr.hostName}.${zoneName}.`

  const Val = ({ value }: { value: TlsaRdata }) => {
    const {
      certificationUsage,
      selector,
      matchingType,
      certificationAssociationData,
    } = value

    return (
      <>
        <p className="truncate">{`cert usage: ${tlsaCaUsages[certificationUsage]}`}</p>
        <p className="truncate">{`selector: ${tlsaSelector[selector]}`}</p>
        <p className="truncate">{`matching type: ${tlsaMatchingTypes[matchingType]}`}</p>
        <p className="truncate">{`data: ${certificationAssociationData}`}</p>
      </>
    )
  }

  const hostName = rr.hostName === '' ? '@' : rr.hostName

  return { fqdn, hostName, rrType: rr.type, Val }
}

export function create() {
  function isReady(rr: Rr) {
    if (rr.type !== 'TLSA') throw Error('Fixme: this is not TLSA record')

    if (!validateTlsaHostName(rr.hostName)) return false
    if (!validateTtl(rr.ttl)) return false

    if (rr.data.length === 0) return false

    const res = rr.data.filter(
      (tlsa) =>
        !(
          tlsa.certificationAssociationData.length > 0 &&
          validateCaAssociationData(
            tlsa.matchingType,
            tlsa.certificationAssociationData
          ).result
        )
    )

    return res.length === 0
  }

  return {
    HostName,
    Val: Value,
    isReady,
    convertOldHostName,
    convertRr,
  }
}

export { parseHostName }
