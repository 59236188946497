import React from 'react'
import { ipV4 } from '../validator'
import { ValProps } from '@/types'
import FlexList from '@/components/Rrs/FlexList'

const Value = (props: ValProps) => {
  if (props.rr.type !== 'A') throw Error('Fixme: this is not A record')

  const changeIpList = (ipList: string[]) => {
    props.rr.data = ipList
    props.change({ ...props.rr })
  }

  return (
    <div className="mt-4">
      <FlexList
        title={'IP address(v4)'}
        vals={props.rr.data.length === 0 ? [''] : props.rr.data}
        validate={ipV4}
        change={changeIpList}
        errorMsg={'Invalid IP address'}
      />
    </div>
  )
}

export default Value
